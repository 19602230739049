import { Typography } from '@material-ui/core';
import { formatNumber, getTopElementsBasedOnProperty, TOP_UNITS_BOX_KEY, UnitType } from '@rentguru/commons-utils';
import { formatDuration, subYears } from 'date-fns';
import { isNil, toLower } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';
import { calculateAverageDaysOfRentalVacancy } from '../DashboardMetrics/utils/DashboardKeyfiguresUtils';
import {
  calculateExpectedUnitIncomeInTimeWindow,
  preFilterLeaseIndexationsAndDiscountsBasedOnUnits,
} from '../DashboardMetrics/utils/MetricUtils';
import { BoxSwitchFiltersProps } from '../Filters/BoxSwitchFilters';
import RankingBox, { RankingBoxLayoutProps } from './RankingBox';

const DashboardRankingTopUnits: React.FC<RankingBoxLayoutProps> = ({ forceUpdateLayout }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const {
    switchFilters: { topUnits: topUnitsFilter },
  } = useDashboardFilters();
  const { units, unitsLoading } = useUnits();
  const { leasePriceHistories, loading: leasesIndexationsLoading } = useLeasePriceHistories();
  const { discounts, loading: leaseVariousOperationsLoading } = useLeaseVariousOperations();

  const loading = unitsLoading || leasesIndexationsLoading || leaseVariousOperationsLoading;

  const switchs: BoxSwitchFiltersProps = {
    name: 'topUnits',
    filters: [
      {
        label: formatMessage({ id: 'dashboard.rankingFilters.all' }),
        value: 'all',
      },
      {
        label: formatMessage({ id: 'dashboard.rankingFilters.unitType' }),
        menuItems: Object.keys(UnitType).map((unitType) => ({
          label: formatMessage({ id: `enums.UnitType.${unitType}` }),
          value: unitType,
        })),
      },
    ],
  };

  const datas = useMemo(() => {
    // Based on Last 5 years
    const today = new Date();
    const start = subYears(today, 5);
    const timeWindow = { start, end: today };
    let totalRentalIncome = 0;
    const unitsWithIncomeAndVacancyDays = units.reduce((result, currentUnit) => {
      if (!isNil(topUnitsFilter) && topUnitsFilter.value !== 'all') {
        if (topUnitsFilter.value !== currentUnit.type) {
          return result;
        }
      }
      const { leaseIndexations: filteredLeaseIndexations, discounts: filteredDiscounts } =
        preFilterLeaseIndexationsAndDiscountsBasedOnUnits([currentUnit], leasePriceHistories ?? [], discounts ?? []);
      const totalIncome = calculateExpectedUnitIncomeInTimeWindow(
        currentUnit,
        filteredLeaseIndexations,
        filteredDiscounts,
        timeWindow
      );
      totalRentalIncome += totalIncome;
      const numberOfVacancyDays = calculateAverageDaysOfRentalVacancy([currentUnit], timeWindow);

      result.push({ unit: currentUnit.name, totalIncome, numberOfVacancyDays });
      return result;
    }, [] as { unit: string; totalIncome: number; numberOfVacancyDays: number }[]);

    const top5Unit = getTopElementsBasedOnProperty<{ unit: string; totalIncome: number; numberOfVacancyDays: number }>(
      unitsWithIncomeAndVacancyDays,
      'totalIncome',
      5
    );
    return top5Unit.map((unit) => ({
      name: unit.unit,
      primaryText: formatNumber(unit.totalIncome, language, {
        style: 'currency',
        currency: 'EUR',
      }),
      primarySubText: formatNumber(unit.totalIncome / totalRentalIncome, language, {
        style: 'percent',
        maximumSignificantDigits: 2,
      }),
      secondaryText: `${formatDuration(
        { days: unit.numberOfVacancyDays },
        { locale: (dateLocaleMap as dateLocaleMapType)[language], zero: true }
      )} ${toLower(formatMessage({ id: 'rentalUnit.detail.general.vacant' }))}`,
    }));
  }, [units, topUnitsFilter, language, leasePriceHistories, discounts, formatMessage]);

  return (
    <RankingBox
      boxKey={TOP_UNITS_BOX_KEY}
      title={formatMessage({ id: 'dashboard.topUnits.title' })}
      infoContent={
        <>
          <Typography style={{ fontSize: 12 }}>
            {formatMessage({ id: 'dashboard.topUnits.info' }, { numberOfYears: 5 })}
          </Typography>
          <Typography style={{ fontSize: 12 }}>{` ${formatMessage({ id: 'dashboard.topUnits.info2' })}`}</Typography>
        </>
      }
      filtersBundle={switchs}
      datas={datas}
      forceUpdateLayout={forceUpdateLayout}
      loading={loading}
    />
  );
};

export default DashboardRankingTopUnits;
