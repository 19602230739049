import { Typography } from '@material-ui/core';
import { NewFile, PDF_FILE_TYPE, fileExists } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import DropZoneField from 'src/components/ui/Forms/FormField/DropZoneField';
import { EndLeaseFormValues, useEndLeaseStyles } from './EndLeaseForm';

const EndLeaseFileField = ({
  forRenovation = false,
  showTitle = true,
}: {
  forRenovation?: boolean;
  showTitle?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, isSubmitting } = useFormikContext<EndLeaseFormValues>();
  const classes = useEndLeaseStyles();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (isEmpty(acceptedFiles)) {
        return;
      }
      const filesToAdd: NewFile[] = acceptedFiles.reduce((acc: NewFile[], acceptedFile: File) => {
        if (
          fileExists(
            acceptedFile,
            values.files.map((newFile) => newFile.file)
          )
        ) {
          return acc;
        }

        return [
          ...acc,
          {
            mimeType: acceptedFile.type,
            name: acceptedFile.name,
            size: acceptedFile.size,
            file: acceptedFile,
          },
        ];
      }, []);

      const allUniqueFiles = [...values.files, ...filesToAdd];

      setFieldValue(`files`, allUniqueFiles);
    },
    [values.files, setFieldValue]
  );

  const removeFileIndex = (index: number) => {
    setFieldValue(
      `files`,
      values.files.filter((_file, i) => i !== index)
    );
  };

  return (
    <>
      {showTitle && (
        <Typography
          className={classes.informationFillInTitle}
          style={{
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          {formatMessage({ id: `lease.endExtendLease.addDocument${forRenovation ? 'ForRenovation' : ''}` })}
        </Typography>
      )}
      <DropZoneField
        onDrop={onDrop}
        currentFiles={values.files}
        displayingPictures
        imagesOnTop
        removeFileByIndex={removeFileIndex}
        height={100}
        width={560}
        style={{ width: 'none' }}
        itemStyle={{ width: 'none', height: 'none', paddingRight: 0, paddingLeft: 0 }}
        dropBoxStyle={{ width: '100%' }}
        disabled={isSubmitting}
        acceptedMimeTypes={[PDF_FILE_TYPE]}
      />
    </>
  );
};

export default EndLeaseFileField;
