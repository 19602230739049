import { Grid } from '@material-ui/core';
import { ElevatedPaperTable } from '@up2rent/ui';
import { useIntl } from 'react-intl';
import FormHeaderColorDots from 'src/components/ui/FormHeaderColorDots';
import BuildingInformation from './BuildingInformation';
import CommoditiesEdit from './CommoditiesEdit';
// import LocationInformation from './LocationInformation';
import RentalInformation from './RentalInformation';
import ShowAddressEdit from './ShowAddressEdit';
import UnitTypeEdits from './UnitTypeEdits';

const GeneralEdits = () => {
  const { formatMessage } = useIntl();

  return (
    <Grid item style={{ width: 640 }}>
      <ElevatedPaperTable style={{ maxWidth: 640, paddingBottom: 10 }}>
        <FormHeaderColorDots
          title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.generalInformation' })}
        />
        <RentalInformation />
        <UnitTypeEdits />
        <BuildingInformation />
        {/* <LocationInformation /> */}
        <CommoditiesEdit />
        <ShowAddressEdit />
      </ElevatedPaperTable>
    </Grid>
  );
};

export default GeneralEdits;
