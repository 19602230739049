/* eslint-disable no-redeclare */
import {
  CreateUnitInput as CreateInput,
  CreateUnitMutationVariables as CreateMutationVariables,
  CreateUnitEventInput,
  CreateUnitEventMutationVariables,
  DeepDeletableEntityType,
  DeleteEntityByIdMutationVariables,
  DeleteUnitMutationVariables as DeleteMutationVariables,
  DeleteUnitEventMutationVariables,
  Model,
  MutationStatus,
  OnCreateUnitEventSubscription,
  OnCreateUnitSubscription,
  OnDeleteUnitEventSubscription,
  OnDeleteUnitSubscription,
  OnUpdateUnitEventSubscription,
  OnUpdateUnitSubscription,
  Unit,
  UnitEvent,
  UnitEventType,
  cleanInputCreate,
  cleanInputUpdate,
  createUnit as createMutation,
  createUnitEvent as createUnitEventMutation,
  deleteEntityById as deleteEntityByIdMutation,
  deleteUnit as deleteMutation,
  deleteUnitEvent as deleteUnitEventMutation,
  getUnit as getQuery,
  getReadId,
  getTableClientId,
  getUnitEvent as getUnitEventQuery,
  isNilOrEmpty,
  onCreateUnit,
  onCreateUnitEvent,
  onDeleteUnit,
  onDeleteUnitEvent,
  onUpdateUnit,
  onUpdateUnitEvent,
  resolveManyToMany,
  resolveOneToMany,
  syncUnits as syncQuery,
  syncUnitEvents,
  updateUnit as updateMutation,
  updateUnitEvent as updateUnitEventMutation,
} from '@rentguru/commons-utils';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { isNil } from 'lodash';
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { useUser } from './UserContext';
import { usePermissions } from './utils/PermissionsContext';

const ENTITY_MODEL_NAME: Model = 'Unit';
const ENTITY_MODEL_NAME_UNIT_EVENT: Model = 'UnitEvent';

export type ActionUnit =
  | {
      type: 'SHOULD_FETCH_UNIT' | 'IS_FETCHING_UNIT';
    }
  | {
      type: 'ADD_UNIT';
      payload: { unit: Unit };
    }
  | {
      type: 'FETCHED_UNIT';
      payload: { units: Unit[] };
    }
  | {
      type: 'UPDATE_UNIT';
      payload: { unit: Unit };
    }
  | {
      type: 'DELETE_UNIT';
      payload: { id: string };
    };

type ActionUnitEvent =
  | {
      type: 'SHOULD_FETCH_UNIT_EVENT' | 'IS_FETCHING_UNIT_EVENT';
    }
  | {
      type: 'ADD_UNIT_EVENT';
      payload: { unitEvent: UnitEvent };
    }
  | {
      type: 'FETCHED_UNIT_EVENT';
      payload: { unitEvents: UnitEvent[] };
    }
  | {
      type: 'UPDATE_UNIT_EVENT';
      payload: { unitEvent: UnitEvent };
    }
  | {
      type: 'DELETE_UNIT_EVENT';
      payload: { id: string };
    };

export interface UnitContext extends UnitEventState {
  units: Unit[];
  getUnit: (id: string) => Unit | undefined;
  createUnit: (input: Omit<Unit, 'id' | 'clientId' | 'readId'>) => Promise<Unit>;
  updateUnit: (original: Unit, updates: Partial<Unit>) => Promise<Unit>;
  deleteUnit: (id: string) => Promise<void>;
  createUnitEvent: (input: Omit<UnitEvent, 'id' | 'clientId' | 'readId'>) => Promise<UnitEvent>;
  updateUnitEvent: (original: UnitEvent, updates: Partial<UnitEvent>) => Promise<UnitEvent>;
  getUnitEvent: (id: string) => UnitEvent[];
  deleteUnitEvent: (id: string) => Promise<void>;
  deepDeleteUnit: (id: string) => Promise<MutationStatus | undefined>;
  unitsLoading: boolean;
  unitsError: string | undefined;
  setFetchUnitEvents: () => void;
  setFetchUnits: () => void;
}

export const unitReducerDelegation = (state: ContextLoaderStore, action: ContextLoaderAction): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_UNIT':
      if (state.Unit.loading || state.Unit.shouldFetch) {
        return state;
      }
      return {
        ...state,
        Unit: { ...state.Unit, shouldFetch: true },
      };
    case 'IS_FETCHING_UNIT':
      if (state.Unit.loading) {
        return state;
      }
      return {
        ...state,
        Unit: { ...state.Unit, loading: true },
      };
    case 'FETCHED_UNIT':
      return {
        ...state,
        Unit: {
          ...state.Unit,
          data: action.payload.units,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_UNIT':
      // Check if already present - If already added by this user or coming from another user
      if (state.Unit.data?.find((object) => object.id === action.payload.unit.id)) {
        return state;
      }

      return {
        ...state,
        Unit: {
          ...state.Unit,
          data: [...state.Unit.data, action.payload.unit],
        },
      };
    case 'UPDATE_UNIT':
      // No data
      if (isNilOrEmpty(state.Unit.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.Unit.data?.find((object) => object.id === action.payload.unit.id);
      if (!currentObject) {
        return {
          ...state,
          Unit: {
            ...state.Unit,
            data: [...state.Unit.data, action.payload.unit],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.unit)) {
        return state;
      }

      // Update
      return {
        ...state,
        Unit: {
          ...state.Unit,
          data: state.Unit.data.map((object) => {
            if (object.id === action.payload.unit.id) {
              return action.payload.unit;
            }
            return object;
          }),
        },
      };
    case 'DELETE_UNIT':
      if (isNilOrEmpty(state.Unit.data)) {
        return state;
      }
      return {
        ...state,
        Unit: {
          ...state.Unit,
          data: state.Unit.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

interface UnitEventState {
  unitEvents: UnitEvent[] | null;
  loading: boolean;
  shouldFetchUnitEvent: boolean;
  lastFetch: Date | null;
}

const unitEventInitialState: UnitEventState = {
  loading: false,
  unitEvents: [],
  shouldFetchUnitEvent: false,
  lastFetch: null,
};

const unitEventReducer = (state: UnitEventState, action: ActionUnitEvent): UnitEventState => {
  switch (action.type) {
    case 'SHOULD_FETCH_UNIT_EVENT':
      if (state.loading || state.shouldFetchUnitEvent) {
        return state;
      }
      return {
        ...state,
        shouldFetchUnitEvent: true,
      };
    case 'IS_FETCHING_UNIT_EVENT':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED_UNIT_EVENT':
      return {
        ...state,
        loading: false,
        shouldFetchUnitEvent: false,
        unitEvents: action.payload.unitEvents,
        lastFetch: new Date(),
      };
    case 'ADD_UNIT_EVENT':
      if (state.unitEvents?.find((object) => object.id === action.payload.unitEvent.id)) {
        return state;
      }

      return {
        ...state,

        unitEvents: [...(state.unitEvents ?? []), action.payload.unitEvent],
      };

    case 'UPDATE_UNIT_EVENT':
      // No data
      if (isNilOrEmpty(state.unitEvents)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.unitEvents.find((object) => object.id === action.payload.unitEvent.id);
      if (!currentObject) {
        return {
          ...state,
          unitEvents: [...state.unitEvents, action.payload.unitEvent],
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.unitEvent)) {
        return state;
      }

      // Update
      return {
        ...state,
        unitEvents: state.unitEvents.map((object) => {
          if (object.id === action.payload.unitEvent.id) {
            return action.payload.unitEvent;
          }
          return object;
        }),
      };

    case 'DELETE_UNIT_EVENT':
      if (isNilOrEmpty(state.unitEvents)) {
        return state;
      }

      return {
        ...state,
        unitEvents: state.unitEvents.filter((object) => object.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const fetchUnits = async (clientId: string, additionalFilter?: object): Promise<Unit[]> => {
  return await list<Unit>(
    syncQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const fetchUnitEvents = async (
  by: 'byClientId' | 'byUnit',
  byValue: string,
  additionalFilter?: object
): Promise<UnitEvent[]> => {
  return await list<UnitEvent>(syncUnitEvents, getFilterFieldNameForIndex(by), byValue, additionalFilter);
};

export const UnitContext = React.createContext<UnitContext | null>(null);

export const UnitContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [unitEventsState, unitEventsDispatch] = useReducer<Reducer<UnitEventState, ActionUnitEvent>>(
    unitEventReducer,
    unitEventInitialState
  );
  const {
    Lease: { data: leases, loading: leasesLoading },
    Unit: { data: unitsLoader, loading: unitsLoading, shouldFetch, lastFetch },
    UnitLease: { data: unitLeases, loading: unitLeasesLoading },
    UnitOwner: { data: unitOwners, loading: unitOwnersLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { buildingsUnitsDetailsDelete, leasesCreationDelete, leasesDetailsDelete, financialValuationsAndCostsDelete } =
    usePermissions();

  const { clientId, isOwner, userId, isFetchingUser } = useUser();
  const loading =
    isFetchingUser ||
    unitsLoading ||
    unitEventsState.loading ||
    unitLeasesLoading ||
    unitOwnersLoading ||
    leasesLoading;

  useEffect(() => {
    const fetchAndSet = async () => {
      unitEventsDispatch({ type: 'IS_FETCHING_UNIT_EVENT' });
      const result = await fetchUnitEvents('byClientId', getTableClientId(clientId!, ENTITY_MODEL_NAME_UNIT_EVENT));
      unitEventsDispatch({ type: 'FETCHED_UNIT_EVENT', payload: { unitEvents: result } });
    };
    if (unitEventsState.shouldFetchUnitEvent) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitEventsState.shouldFetchUnitEvent]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_UNIT' });
      const result = await fetchUnits(clientId!);
      contextDispatch({ type: 'FETCHED_UNIT', payload: { units: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<OnCreateUnitSubscription, OnUpdateUnitSubscription, OnDeleteUnitSubscription>(
    onCreateUnit,
    onUpdateUnit,
    onDeleteUnit,
    (data) => {
      contextDispatch({
        type: 'ADD_UNIT',
        payload: { unit: data.onCreateUnit as Unit },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_UNIT',
        payload: { unit: data.onUpdateUnit as Unit },
      });
    },
    (data) => {
      const { id } = data.onDeleteUnit as Unit;
      contextDispatch({
        type: 'DELETE_UNIT',
        payload: { id },
      });
    }
  );

  useSubscriptions<OnCreateUnitEventSubscription, OnUpdateUnitEventSubscription, OnDeleteUnitEventSubscription>(
    onCreateUnitEvent,
    onUpdateUnitEvent,
    onDeleteUnitEvent,
    (data) => {
      unitEventsDispatch({
        type: 'ADD_UNIT_EVENT',
        payload: { unitEvent: data.onCreateUnitEvent as UnitEvent },
      });
    },
    (data) => {
      unitEventsDispatch({
        type: 'UPDATE_UNIT_EVENT',
        payload: { unitEvent: data.onUpdateUnitEvent as UnitEvent },
      });
    },
    (data) => {
      const { id } = data.onDeleteUnitEvent as UnitEvent;
      unitEventsDispatch({
        type: 'DELETE_UNIT_EVENT',
        payload: { id },
      });
    }
  );

  const units = useMemo<Unit[]>(() => {
    if (loading) {
      return [];
    }
    const safeUnitLease = unitLeases.filter((unitLease) => unitLease.lease);
    const unitsReduced = unitsLoader.reduce((acc: Unit[], unit: Unit) => {
      if (!isNil(unit.building)) {
        acc.push({
          ...unit,
          events: resolveOneToMany(unit.id, unitEventsState.unitEvents ?? [], 'unitId'),
          owners: resolveManyToMany(unit, 'unit', unitOwners),
          leases: resolveManyToMany(unit, 'unit', safeUnitLease),
        });
      }
      return acc;
    }, []);

    return unitsReduced;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leases, unitsLoader, unitOwners, unitLeases, unitEventsState.unitEvents, loading]);

  const setFetchUnitEvents = () => {
    unitEventsDispatch({ type: 'SHOULD_FETCH_UNIT_EVENT' });
  };

  const setFetchUnits = () => {
    contextDispatch({ type: 'SHOULD_FETCH_UNIT' });
  };

  const createUnit = async (input: Omit<Unit, 'id' | 'clientId' | 'readId'>) => {
    const cleanedInput = cleanInputCreate(input) as CreateInput;
    const unit = await mutation<Unit, CreateMutationVariables>(createMutation, {
      input: {
        ...cleanedInput,
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_UNIT', payload: { unit } });
    return unit;
  };

  const updateUnit = async (original: Unit, updates: Partial<Unit>) => {
    const result = await mutation<Unit>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_UNIT', payload: { unit: result } });
    return result;
  };

  const getUnitEvent = (id: string) => {
    if (isNil(unitEventsState.unitEvents)) {
      return [];
    }

    return (unitEventsState.unitEvents as UnitEvent[]).filter((be) => be.unitId === id);
  };

  const createUnitEvent = async (input: Omit<UnitEvent, 'id' | 'clientId' | 'readId'>): Promise<UnitEvent> => {
    const unitEvent = await mutation<UnitEvent, CreateUnitEventMutationVariables>(createUnitEventMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateUnitEventInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME_UNIT_EVENT),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME_UNIT_EVENT),
        ...(isOwner && { writers: [userId!] }),
      },
    });
    unitEventsDispatch({ type: 'ADD_UNIT_EVENT', payload: { unitEvent } });
    return unitEvent;
  };

  const updateUnitEvent = async (original: UnitEvent, updates: Partial<UnitEvent>) => {
    const result = await mutation<UnitEvent>(updateUnitEventMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    unitEventsDispatch({ type: 'UPDATE_UNIT_EVENT', payload: { unitEvent: result } });
    return result;
  };

  const deleteUnitEvent = async (id: string) => {
    const unitEvent = unitEventsState.unitEvents?.find((currentEvent) => currentEvent.id === id);
    if (
      (!leasesCreationDelete && !leasesDetailsDelete && unitEvent?.type === UnitEventType.OWN_USE) ||
      (!financialValuationsAndCostsDelete && unitEvent?.type === UnitEventType.WORK)
    ) {
      return;
    }
    await deleteEntityWithFetchBefore<Pick<UnitEvent, 'id'>, DeleteUnitEventMutationVariables>(
      { id },
      getUnitEventQuery,
      deleteUnitEventMutation
    );
    unitEventsDispatch({ type: 'DELETE_UNIT_EVENT', payload: { id } });
  };

  const deleteUnit = async (id: string) => {
    if (!buildingsUnitsDetailsDelete) {
      return;
    }

    await deleteEntityWithFetchBefore<Pick<Unit, 'id'>, DeleteMutationVariables>({ id }, getQuery, deleteMutation);
    contextDispatch({ type: 'DELETE_UNIT', payload: { id } });
  };

  const deepDeleteUnit = async (id: string) => {
    if (!buildingsUnitsDetailsDelete) {
      return;
    }
    return await mutation<MutationStatus, DeleteEntityByIdMutationVariables>(deleteEntityByIdMutation, {
      input: { entityId: id, entity: DeepDeletableEntityType.UNIT },
    });
  };

  const getUnit = (id: string): Unit | undefined => {
    if (unitsLoading) {
      return undefined;
    }
    return units.find((u) => u.id === id);
  };

  const values = useMemo(
    () => ({
      ...unitEventsState,
      units,
      getUnit,
      createUnit,
      updateUnit,
      deleteUnit,
      createUnitEvent,
      updateUnitEvent,
      getUnitEvent,
      deleteUnitEvent,
      deepDeleteUnit,
      unitsError: undefined,
      unitsLoading: loading,
      shouldFetch,
      lastFetch,
      setFetchUnitEvents,
      setFetchUnits,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [units, unitEventsState, loading, shouldFetch, lastFetch]
  );

  return <UnitContext.Provider value={values}>{children}</UnitContext.Provider>;
};

export const useUnits = (): UnitContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<UnitContext | null>(UnitContext);

  if (isNil(context)) {
    throw new Error('`useUnits` hook must be used within a `UnitContextProvider` component');
  }

  useEffect(() => {
    if (!firstRender.current && isNil(context.unitEvents)) {
      context.setFetchUnitEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context as UnitContext;
};
