/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
import { Divider, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Building,
  Colors,
  Contact,
  contactContainsType,
  ContactType,
  fileExists,
  MAX_SIZE_ATTACHMENT,
  uniqueObjectWithIdAppend,
  uniquePush,
  Unit,
} from '@rentguru/commons-utils';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { FormikProps, useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { UserStatus } from 'src/API';
import FormHeader from 'src/components/ui/FormHeader';
import DropZoneField from 'src/components/ui/Forms/FormField/DropZoneField';
import BuildingSelector from 'src/components/ui/SimpleSelectors/SimpleBuildingSelector';
import ContactSelector from 'src/components/ui/SimpleSelectors/SimpleContactSelector';
import UnitSelector from 'src/components/ui/SimpleSelectors/SimpleUnitSelector';
import TemplateEditor from 'src/components/ui/TemplateEditor';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useUser } from 'src/hooks/UserContext';
import { useUsers } from 'src/hooks/UsersContext';
import { stripHTML } from 'src/utils/stripHTML';
import { TicketInitialValuesTypes } from './AddTicketForm';

export const TICKET_ACCEPTED_MIME_TYPES = [
  'image/heic',
  'image/heif',
  'image/*',
  'video/*',
  'text/plain',
  'application/vnd.ms-excel',
  'application/pdf',
  'application/msword',
  'application/zip',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-outlook',
  'message/rfc822',
  'application/octet-stream',
  '.msg',
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 280,
      },
    },
    divider: {
      marginBottom: 10,
      marginTop: 10,
    },
    subjectMessage: {
      '& > *': {
        margin: theme.spacing(1),
        width: 578,
        fontColor: Colors.CHARCOAL_GREY,
      },
      '& > * ::before': {
        content: 'none',
        color: Colors.BLUE_GREY,
      },
    },
    user: {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(2),
      minWidth: 580,
    },
    inputLabelStyle: {
      color: Colors.BLUE_GREY,
      fontSize: 16,
      textAlign: 'left',
    },
  })
);
interface AddTicketFormMessageProps {
  subjectEditor: EditorState;
  setSubjectEditor: (editor: EditorState) => void;
  bodyEditor: EditorState;
  setBodyEditor: (edit: EditorState) => void;
  contentError: boolean;
  setContentError: (contentError: boolean) => void;
  subjectError: boolean;
  setSubjectError: (subjectError: boolean) => void;
}

const AddTicketFormMessage: React.FC<AddTicketFormMessageProps> = ({
  subjectEditor,
  setSubjectEditor,
  bodyEditor,
  setBodyEditor,
  contentError,
  setContentError,
  subjectError,
  setSubjectError,
}) => {
  const { values, setFieldValue, errors, touched, isSubmitting }: FormikProps<TicketInitialValuesTypes> =
    useFormikContext();
  const { formatMessage } = useIntl();
  const [myFiles, setMyFiles] = useState([] as File[]);
  const classes = useStyles();
  const { buildingsError, buildingsLoading, buildings } = useBuildings();
  const { unitsError, unitsLoading, units } = useUnits();
  const { contactsError, contactsLoading, contacts, members } = useContacts();
  const { isOwner } = useUser();
  const { getLease } = useLeases();
  const { getUserFromContact } = useUsers();

  const errorMessage: string = formatMessage({ id: 'tickets.detail.newConversationError' });
  const errorSubjectMessage: string = formatMessage({ id: 'tickets.detail.newSubject' });

  useEffect(() => {
    // error message disappears if text is not empty
    const currentMessage = stateToHTML(bodyEditor.getCurrentContent()).replace(/(\r\n|\n|\r)/gm, '');
    if (contentError && !isEmpty(stripHTML(currentMessage))) {
      setContentError(false);
    }
  }, [bodyEditor, contentError, setContentError]); // message content

  useEffect(() => {
    // error message disappears if text is not empty
    const currentSubject = stateToHTML(subjectEditor.getCurrentContent()).replace(/(\r\n|\n|\r)/gm, '');
    if (subjectError && !isEmpty(stripHTML(currentSubject))) {
      setSubjectError(false);
    }
  }, [subjectEditor, subjectError, setSubjectError]); // message subject

  if (contactsLoading || buildingsLoading || unitsLoading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }
  if (contactsError || buildingsError || unitsError) {
    return <Typography>{`${contactsError}-${buildingsError}-${unitsError}`}</Typography>;
  }

  let filteredBuildings: Building[] = buildings;
  let filteredUnits: Unit[] = units;
  const filteredMembers = members.filter((member) => {
    const userFromContact = getUserFromContact(member.id);
    return userFromContact?.status !== UserStatus.PENDING;
  });
  // exclude contractor from ticket
  let filteredContacts: Contact[] = contacts.filter((currentContact) => {
    if (contactContainsType(currentContact, ContactType.MEMBER)) {
      return filteredMembers.find((member) => member.id === currentContact.id);
    }
    return !contactContainsType(currentContact, ContactType.CONTRACTOR);
  });

  // Members DO NOT filter buildings and units!
  const completeContact =
    values.contactId !== ''
      ? filteredContacts.find(
          (currentContact) =>
            currentContact.id === values.contactId && !contactContainsType(currentContact, ContactType.MEMBER)
        )
      : undefined;
  const buildingSelected = !isNil(values.building);
  const unitSelected = !isNil(values.unit);
  const contactSelected = !isNil(completeContact);
  const completeBuilding = values.building;
  const completeUnit = values.unit;

  if (unitSelected) {
    const newFilteredBuildings: Building[] = [];
    const newFilteredContacts: Contact[] = filteredMembers;
    if (!isNil(completeUnit)) {
      const completeBuilding = buildings.find((b) => b.id === completeUnit.building!.id);
      if (!isNil(completeBuilding)) uniquePush(newFilteredBuildings, completeBuilding);
      // Propose the owners of the units
      completeUnit.owners?.forEach((uo) => {
        const completeContact = contacts.find((currentContact) => currentContact.id === uo.owner!.id);
        if (!isNil(completeContact)) {
          uniquePush(newFilteredContacts, completeContact);
        }
      });

      // Propose the owners of the unit's building
      completeBuilding?.owners?.forEach((bo) => {
        const completeContact = contacts.find((currentContact) => currentContact.id === bo.owner!.id);
        if (!isNil(completeContact)) {
          uniquePush(newFilteredContacts, completeContact);
        }
      });

      // Propose the contacts linked to the leases of the unit
      completeUnit.leases?.forEach((ul) => {
        const completeLease = getLease(ul.lease!.id);
        completeLease?.contacts?.forEach((lc) => {
          const completeContact = contacts.find((currentContact) => currentContact.id === lc.contact!.id);
          if (!isNil(completeContact)) {
            uniquePush(newFilteredContacts, completeContact);
          }
        });
      });

      filteredBuildings = newFilteredBuildings;
      filteredContacts = newFilteredContacts;
      filteredUnits = [completeUnit];
    }
  } else if (contactSelected && !buildingSelected) {
    if (!isNil(completeContact)) {
      const newFilteredBuildings: Building[] = [];
      let newFilteredUnits: Unit[] = [];
      // Add the buildings of the building owners, as well as their units
      completeContact.buildings!.forEach((buildingOwner) => {
        const completeBuilding = buildings.find((b) => b.id === buildingOwner.building!.id);
        if (!isNil(completeBuilding)) {
          uniquePush(newFilteredBuildings, completeBuilding);
          const completeUnitsBuilding = completeBuilding.units!.reduce((acc: Unit[], unit) => {
            const completeUnit = units.find((u) => u.id === unit.id);
            if (!isNil(completeUnit)) acc.push(completeUnit);
            return acc;
          }, []);
          newFilteredUnits = uniqueObjectWithIdAppend(newFilteredUnits, completeUnitsBuilding);
        }
      });
      // And the units of the unit owners, as well as their buildings
      completeContact.units!.forEach((unitOwner) => {
        const completeUnit = units.find((u) => u.id === unitOwner.unit!.id);
        if (!isNil(completeUnit)) {
          uniquePush(newFilteredUnits, completeUnit);
          const completeBuilding = buildings.find((b) => b.id === completeUnit.building!.id);
          if (!isNil(completeBuilding)) uniquePush(newFilteredBuildings, completeBuilding);
        }
      });
      // We should also add the units linked to the lease as well as their building
      completeContact.leases!.forEach((leaseContact) => {
        const completeLease = getLease(leaseContact.lease!.id);
        if (!isNil(completeLease)) {
          completeLease.units!.forEach((unitLease) => {
            const completeUnit = units.find((u) => u.id === unitLease.unit!.id);
            if (!isNil(completeUnit)) {
              uniquePush(newFilteredUnits, completeUnit);
              const completeBuilding = buildings.find((b) => b.id === completeUnit.building!.id);
              if (!isNil(completeBuilding)) uniquePush(newFilteredBuildings, completeBuilding);
            }
          });
        }
      });
      filteredBuildings = newFilteredBuildings;
      filteredUnits = newFilteredUnits;
      filteredContacts = [completeContact];
    }
  } else if (buildingSelected && !contactSelected) {
    let newFilteredUnits: Unit[] = [];
    const newFilteredContacts: Contact[] = filteredMembers;
    if (!isNil(completeBuilding)) {
      newFilteredUnits = completeBuilding.units!.reduce((acc: Unit[], unit) => {
        const completeUnit = units.find((u) => u.id === unit.id);
        if (!isNil(completeUnit)) acc.push(completeUnit);
        return acc;
      }, []);
      // Add all its owners
      completeBuilding.owners!.forEach((bo) => {
        const completeContact = contacts.find((currentContact) => bo.owner!.id === currentContact.id);
        if (!isNil(completeContact)) uniquePush(newFilteredContacts, completeContact);
      });
      // Add the owners of its units as well as their lease contacts
      completeBuilding.units!.forEach((unit) => {
        const completeUnit = units.find((u) => u.id === unit.id);
        if (!isNil(completeUnit)) {
          // Add the unit owner
          completeUnit.owners!.forEach((uo) => {
            const completeContact = contacts.find((currentContact) => uo.owner!.id === currentContact.id);
            if (!isNil(completeContact)) uniquePush(newFilteredContacts, completeContact);
          });
          // Add their lease contacts
          completeUnit.leases!.forEach((unitLease) => {
            const completeLease = getLease(unitLease.lease!.id);
            if (!isNil(completeLease)) {
              completeLease.contacts!.forEach((lc) => {
                const completeContact = contacts.find((currentContact) => currentContact.id === lc.contact!.id);
                if (!isNil(completeContact)) uniquePush(newFilteredContacts, completeContact);
              });
            }
          });
        }
      });
      filteredBuildings = [completeBuilding];
      filteredUnits = newFilteredUnits;
      filteredContacts = newFilteredContacts;
    }
  }
  // Both filters selected => Start from the contact and keep only the unit with the correct building
  else if (!isNil(completeContact) && !isNil(completeBuilding)) {
    let newFilteredUnits: Unit[] = [];
    // If the contact owns the building, add all the building's units
    completeContact.buildings!.forEach((buildingOwner) => {
      if (buildingOwner.building!.id === completeBuilding.id) {
        const nestedCompleteBuilding = buildings.find((b) => b.id === buildingOwner.building!.id);
        if (!isNil(nestedCompleteBuilding))
          newFilteredUnits = uniqueObjectWithIdAppend(newFilteredUnits, nestedCompleteBuilding.units!);
      }
    });
    // If a unit is owned by the contact and in the building => add it
    completeContact.units!.forEach((unitOwner) => {
      const completeUnit = units.find((u) => u.id === unitOwner.unit!.id);
      if (!isNil(completeUnit) && completeUnit.building!.id === completeBuilding.id) {
        uniquePush(newFilteredUnits, completeUnit);
      }
    });
    // Add all units in the contact leases, only if they are in the building
    completeContact.leases!.forEach((leaseContact) => {
      const completeLease = getLease(leaseContact.lease!.id);
      if (!isNil(completeLease)) {
        completeLease.units!.forEach((unitLease) => {
          const completeUnit = units.find((u) => u.id === unitLease.unit!.id);
          if (!isNil(completeUnit) && completeUnit.building!.id === completeBuilding.id) {
            uniquePush(newFilteredUnits, completeUnit);
          }
        });
      }
    });
    filteredBuildings = [completeBuilding];
    filteredUnits = newFilteredUnits;
    filteredContacts = [completeContact];
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ display: 'flex' }}>
        <FormHeader
          title={formatMessage({
            id: 'tickets.addTicket.message',
          })}
          toolbarStyle={{ paddingLeft: 20 }}
        />
      </div>
      <Divider className={classes.divider} />
      <div style={{ display: 'flex', marginLeft: 30, marginRight: 30 }}>
        <ContactSelector
          fieldLabel={formatMessage({
            id: 'tickets.detail.contact',
          })}
          contacts={filteredContacts}
          fieldName="contactId"
          inputWidth={580}
          disabled={isOwner}
          groupBy="type"
        />
      </div>
      <>
        <div style={{ display: 'flex', marginLeft: 30, marginRight: 30, justifyContent: 'space-between' }}>
          <BuildingSelector buildings={filteredBuildings} fieldName="building" inputWidth={280} />
          <UnitSelector units={filteredUnits} fieldName="unit" inputWidth={280} />
        </div>
        {(Boolean(errors.unit && touched.unit) || Boolean(errors.building && touched.building)) && (
          <Typography style={{ fontSize: 14, color: Colors.BURNING_ORANGE }}>
            {formatMessage({ id: 'tickets.addTicket.error' })}
          </Typography>
        )}
      </>
      <Divider className={classes.divider} />
      <Grid
        container
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'flex-end',
        }}
        direction="column"
      >
        <Grid item xs={12} style={{ marginBottom: 50 }}>
          <TemplateEditor
            title={subjectEditor}
            setTitle={setSubjectEditor}
            body={bodyEditor}
            setBody={setBodyEditor}
            editable={true}
            editMode={true}
            error={contentError}
            errorMessage={errorMessage}
            errorSubject={subjectError}
            errorSubjectMessage={errorSubjectMessage}
          />
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12} style={{ paddingLeft: 30, paddingRight: 30 }}>
          <DropZoneField
            displayingPictures
            onDrop={(acceptedFiles: File[]) => {
              if (isEmpty(acceptedFiles)) {
                return;
              }
              const uniqueNewFiles = acceptedFiles.filter((file) => !fileExists(file, myFiles));

              const updatedFiles = [...myFiles, ...uniqueNewFiles];
              setMyFiles(updatedFiles);
              setFieldValue('file', [...values.file, ...uniqueNewFiles]);
            }}
            currentFiles={
              values.file
                ? values.file.map((acceptedFile: File) => ({
                    mimeType: acceptedFile.type,
                    name: acceptedFile.name,
                    size: acceptedFile.size,
                    file: acceptedFile,
                  }))
                : []
            }
            isDisplayList={true}
            multiple={true}
            maxSize={MAX_SIZE_ATTACHMENT}
            height={60}
            width={'100%'}
            disableGuttersForAdd
            acceptedMimeTypes={TICKET_ACCEPTED_MIME_TYPES}
            disabled={isSubmitting}
            removeFileByIndex={(index: number) => {
              const newFiles = [...myFiles];
              if (index > -1) {
                newFiles.splice(index, 1);
              }
              setMyFiles(newFiles);
              setFieldValue('file', newFiles);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddTicketFormMessage;
