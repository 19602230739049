import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { isString } from 'lodash';

export interface Interval {
  start: Date;
  end: Date;
}

export const getPrintableDate = (inputFormat: number | string | Date | undefined | null) => {
  if (isNil(inputFormat) || (isString(inputFormat) && isEmpty(inputFormat))) {
    return '';
  }
  const d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
};

const pad = (s: number) => {
  return s < 10 ? `0${s}` : s;
};
