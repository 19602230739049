/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import {
  countOccurrences,
  isNilOrEmpty,
  MessagesEN as messages,
  TagCategory,
  Template,
  templateSpecificTags,
  TemplateTag,
  TemplateTagTypes,
} from '@rentguru/commons-utils';
import { convertFromHTML } from 'draft-convert';
import { ContentBlock, ContentState, convertFromRaw, convertToRaw, EditorState, genKey } from 'draft-js';
import { Options, stateToHTML } from 'draft-js-export-html';
import { List } from 'immutable';
import { groupBy, isNil, toUpper } from 'lodash';
import { MessageDescriptor } from 'react-intl';
import { putMetaDataIntoIfBlocks, tagRegexp } from './templateTagsUtils';

export function groupTemplateBySubType(ts: Template[] | null): Template[][] {
  if (isNil(ts)) {
    return [];
  }
  const templatesGroupedBySubType = groupBy(ts, 'subType');
  return Object.values(templatesGroupedBySubType);
}

export const convertNewLinesFromHtmlBody = (bodyInHtml: string) => {
  // We need to put an invisible character &zwnj; so draft-js won't remove empty paragraph
  const regex = /<p>(<br>)?<\/p>/g;
  return bodyInHtml.replaceAll(regex, '<p>&zwnj;</p>');
};

export const linkDefaultAndCustomTemplates = (templateToLink: Template, linkingTemplates: Template[]) => {
  return linkingTemplates.find(
    (dt) =>
      toUpper(dt!.language) === toUpper(templateToLink.language) &&
      dt.type === templateToLink.type &&
      dt.subType === templateToLink.subType
  );
};

export const mergeTemplates = (customTemplates: Template[], defaultTemplates: Template[]): Template[] => {
  const defaultTemplatesToKeep = defaultTemplates.filter((dt) => {
    const defaultTemplate = linkDefaultAndCustomTemplates(dt, customTemplates);
    return isNil(defaultTemplate);
  });
  return [...customTemplates, ...defaultTemplatesToKeep];
};

export const findTemplateGroup = (subType: string, groupedTemplates: Template[][]): Template[] => {
  if (isNil(groupedTemplates)) {
    return [];
  }
  const subTypeGroup = groupedTemplates.find(
    (groupedTemplate) => groupedTemplate[0] && groupedTemplate[0].subType === subType
  );
  return subTypeGroup || [];
};

export function getLanguageTemplates(groupedTemplates: Template[][], language: string): Template[] {
  const templatesInGivenLanguage: Template[] = [];
  groupedTemplates.forEach((groupedTemplate) => {
    const templateOfGivenLanguage = groupedTemplate.find((template) => template.language === toUpper(language));
    if (templateOfGivenLanguage) templatesInGivenLanguage.push(templateOfGivenLanguage);
    else {
      const englishTemplate = groupedTemplate.find((template) => template.language === 'EN');
      if (englishTemplate) templatesInGivenLanguage.push(englishTemplate);
    }
  });
  return templatesInGivenLanguage;
}

// Replace the tag labels by their html values that will be compiled with Handlebar
const changeTagsValueWithHandlebars = (
  htmlOutput: string,
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string,
  template: Template
): string => {
  let newOutput = htmlOutput;
  const tags: TemplateTag[] = templateSpecificTags(template);
  tags.forEach((tag) => {
    if (tag.type === TemplateTagTypes.ATTRIBUTE) {
      newOutput = newOutput.replaceAll(`{{${formatMessage({ id: tag.label })}}}`, tag.html);
    }
  });
  return newOutput;
};

export const convertEditorStateWithTagsToHandleBar = (
  editorState: EditorState,
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string,
  template: Template
) => {
  const editorConversionOptions: Options = {
    blockRenderers: {
      conditionalStart: (block) => {
        const blockData = block.getData();
        return blockData.get('html');
      },
      conditionalEnd: (block) => {
        const blockData = block.getData();
        return blockData.get('html');
      },
      conditionalElse: (block) => {
        const blockData = block.getData();
        return blockData.get('html');
      },
    },
    inlineStyles: {
      TINY: {
        style: { fontSize: 9 },
      },
      SMALL: {
        style: { fontSize: 12 },
      },
      MEDIUM_SMALL: {
        style: { fontSize: 13 },
      },
      MEDIUM: {
        style: { fontSize: 15 },
      },
      MEDIUM_BIG: {
        style: { fontSize: 18 },
      },
      BIG: {
        style: { fontSize: 24 },
      },
      HUGE: {
        style: { fontSize: 30 },
      },
    },
  };
  //@ts-ignore
  const convertedEditorState = stateToHTML(editorState.getCurrentContent(), editorConversionOptions);
  const handleBarWithCorrecttags = changeTagsValueWithHandlebars(convertedEditorState, formatMessage, template);
  return handleBarWithCorrecttags;
};

export const convertTemplateToStringFormat = (es: EditorState): string => {
  return JSON.stringify(convertToRaw(es.getCurrentContent()));
};

export const defaultTitleEditorOfTemplate = (template: Template, tags: TemplateTag[]) => {
  if (isNil(template.subject) || template.subject === '') return EditorState.createEmpty();
  return convertDefaultTemplateToEditorFormat(template.subject, tags);
};

export const defaultBodyEditorOfTemplate = (template: Template, tags: TemplateTag[]) => {
  if (isNil(template.body) || template.body === '') return EditorState.createEmpty();
  if (isDefaultTemplate(template)) {
    return convertDefaultTemplateToEditorFormat(template.body, tags);
  }
  return convertTemplateToEditorFormat(template.bodyRawFormat ? template.bodyRawFormat : '');
};

export const listTagCategories = (): string[] => {
  return Object.keys(messages.templates.tags.categories);
};

export const convertDefaultTemplateToEditorFormat = (htmlContent: string, tags: TemplateTag[]): EditorState => {
  const taggedHtmlContent = changeHandlebarsTagsWithNodeValue(htmlContent, tags);
  const contentState = convertFromHTML({
    // @ts-ignore
    htmlToStyle: (nodeName: string, node: HTMLElement, currentStyle: any): DraftInlineStyleType => {
      if (nodeName === 'tag') {
        return currentStyle.add('TAG');
      }
      return currentStyle;
    },
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'tag') {
        return createEntity('TAG', 'IMMUTABLE', { label: node.textContent });
      }
    },
    // @ts-ignore
    htmlToBlock: (nodeName, node) => {
      if (node.textContent) {
        if (nodeName === 'if') {
          const [label, html] = node.textContent.split(';');
          node.textContent = ' ';
          return {
            type: 'conditionalStart',
            data: { label, html },
          };
        }
        if (nodeName === 'else') {
          const [label, html] = node.textContent.split(';');
          node.textContent = ' ';
          return {
            type: 'conditionalElse',
            data: { label, html },
          };
        }
        if (nodeName === 'endif') {
          const [label, html] = node.textContent.split(';');
          node.textContent = ' ';
          return {
            type: 'conditionalEnd',
            data: { label, html },
          };
        }
      }
    },
  })(taggedHtmlContent);
  const convertedEditorState = EditorState.createWithContent(contentState);
  const contentWithDataOnConditionalBlocks = putMetaDataIntoIfBlocks(contentState);
  return EditorState.push(convertedEditorState, contentWithDataOnConditionalBlocks, 'change-block-data');
};

export const convertTemplateToEditorFormat = (bodyRaw: string): EditorState => {
  const decodedStringToJson = JSON.parse(bodyRaw);
  const contentState = convertFromRaw(decodedStringToJson);
  return EditorState.createWithContent(contentState);
};

export const changeHandlebarsTagsWithNodeValue = (htmlOutput: string, tags: TemplateTag[]): string => {
  const handleBarConverted = htmlOutput.replace(tagRegexp, (match: string) => {
    const tag = tags.find((t) => t.html === match);
    if (isNil(tag)) return match;
    switch (tag.type) {
      case TemplateTagTypes.ATTRIBUTE:
        return `<tag>{{${tag.label}}}</tag>`;
      case TemplateTagTypes.CONDITION_IF_START:
        return `<if>${tag.label};${tag.html}</if>`;
      case TemplateTagTypes.ELSE:
        return `<else>${tag.label};${tag.html}</else>`;
      case TemplateTagTypes.CONDITION_IF_END:
        return `<endif>${tag.label};${tag.html}</endif>`;
      default:
        return match;
    }
  });
  return handleBarConverted;
};

export const isDefaultTemplate = (template: Template) => {
  const idParsed = Number(template.id);
  return !isNaN(idParsed) && idParsed < 0;
};

export const textContainsConditionalTags = (text: string, tags: TemplateTag[]): boolean => {
  const conditionalTags = tags.filter((t) => t.category === TagCategory.CONDITION);
  for (const conditionalTag of conditionalTags) {
    if (text.includes(conditionalTag.label)) {
      return true;
    }
  }
  return false;
};

export const compressEditorOnOneLine = (editorState: EditorState) => {
  const blocks = editorState.getCurrentContent().getBlocksAsArray();
  if (blocks.length < 2) return editorState;
  let text = List();
  let characterList = List();

  // Gather all the text/characterList and concat them
  blocks.forEach((block) => {
    // Atomic blocks should be ignored (stripped)
    if (block.getType() !== 'atomic') {
      text = text.push(replaceNewlines(block.getText()));
      characterList = characterList.concat(block.getCharacterList()) as List<string>;
    }
  });

  // Create a new content block
  const contentBlock = new ContentBlock({
    key: genKey(),
    text: text.join(''),
    type: 'unstyled',
    characterList,
    depth: 0,
  });

  // Update the editor state with the compressed version
  const newContentState = ContentState.createFromBlockArray([contentBlock]);
  // Create the new state as an undoable action
  editorState = EditorState.push(editorState, newContentState, 'remove-range');
  // Move the selection to the end
  return EditorState.moveFocusToEnd(editorState);
};

const replaceNewlines = (str: string, replacement = ' ') => {
  return str.replace(/\n/g, replacement);
};

export const getTemplateBodyLength = (templateBody: EditorState, templateTags: TemplateTag[]) => {
  let templateBodyContent = templateBody?.getCurrentContent()?.getPlainText();
  if (isNilOrEmpty(templateBodyContent)) {
    return 0;
  }
  let tagMaxChars: number | null = 0;

  for (const tag of templateTags) {
    const translatedTag = `{{${tag.label}}}`;
    const occurrenceInWord = countOccurrences(templateBodyContent, translatedTag);
    if (occurrenceInWord > 0) {
      if (isNil(tag.maxCharacters)) {
        tagMaxChars = null;
        break;
      }
      templateBodyContent = templateBodyContent.replaceAll(translatedTag, '');
      tagMaxChars += tag.maxCharacters * occurrenceInWord;
    }
  }

  const newLinesCounter = countOccurrences(templateBodyContent, '\n');
  return !isNil(tagMaxChars) ? templateBodyContent.length + tagMaxChars + newLinesCounter : null;
};
