import { CircularProgress, FormControlLabel, Grid, RadioGroup, Typography } from '@material-ui/core';
import { Colors, CommunicationChannel, CreditOperationType } from '@rentguru/commons-utils';
import { toLower } from 'lodash';
import { useIntl } from 'react-intl';
import { RouteDestination } from 'src/components/Routes/Routes';
import { SettableCommunicationType } from 'src/components/Settings/CommunicationSettings/CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';
import { SettingsRouteDestination } from 'src/components/Settings/Settings';
import CustomRouterLink from 'src/components/ui/CustomRouterLink';
import StyledRadio from 'src/components/ui/StyledRadio';
import { useClient } from 'src/hooks/ClientContext';
import { channelToTemplateRoute } from 'src/utils/communicationUtils';

interface CommunicationChannelRadioItemProps {
  communicationType: SettableCommunicationType;
  channel: CommunicationChannel;
  disabled: boolean;
  previewMode: boolean;
}

const getCreditOperationTypeByChannel = (channel: CommunicationChannel) => {
  switch (channel) {
    case CommunicationChannel.LETTER:
      return CreditOperationType.LETTER;
    case CommunicationChannel.REGISTERED_LETTER:
      return CreditOperationType.REGISTERED_LETTER;
    case CommunicationChannel.SMS:
      return CreditOperationType.SMS;
    case CommunicationChannel.WHATSAPP:
    default:
      return CreditOperationType.WHATSAPP;
  }
};

const CommunicationChannelRadioItem: React.FC<CommunicationChannelRadioItemProps> = ({
  communicationType,
  channel,
  disabled,
  previewMode,
}) => {
  const { formatMessage } = useIntl();
  const { creditUsageCosts, loading } = useClient();

  const getCreditCostLabel = () => {
    if ([CommunicationChannel.EMAIL, CommunicationChannel.IN_APP].includes(channel)) {
      return formatMessage({ id: 'free' });
    }
    const creditOperationType = getCreditOperationTypeByChannel(channel);
    const cost = creditUsageCosts?.find(
      (creditUsageCost) => creditUsageCost.type === creditOperationType
    )?.numberOfCreditsPerOperation;
    if (cost) {
      return `${cost} ${formatMessage({
        id: 'settings.credits.name',
      }).toLowerCase()}`;
    }
  };

  return (
    <Grid container justifyContent="space-between">
      <FormControlLabel
        style={{ marginTop: -10 }}
        value={channel}
        disabled={disabled}
        control={<StyledRadio name={channel} />}
        label={
          <Grid container>
            <Typography style={{ fontSize: 14, fontWeight: 600 }}>
              {formatMessage({ id: `enums.CommunicationChannel.${channel}` })}
            </Typography>
            <Typography
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 2,
                marginLeft: 5,
                color: Colors.SLATE_GREY,
                fontStyle: 'italic',
              }}
            >
              (
              {loading ? (
                <CircularProgress style={{ width: 10, height: 10, marginLeft: 5, marginRight: 5 }} />
              ) : (
                getCreditCostLabel()
              )}
              )
            </Typography>
          </Grid>
        }
        id={channel}
      />
      {!previewMode && (
        <CustomRouterLink
          to={`${RouteDestination.SETTINGS}/${SettingsRouteDestination.TEMPLATE_ROUTE}/${toLower(
            channelToTemplateRoute(channel)
          )}/${communicationType}`}
          text={formatMessage({ id: 'settings.communications.previewTemplate' })}
          textStyle={{ marginTop: 2 }}
        />
      )}
    </Grid>
  );
};

interface CommunicationChannelFormItemProps {
  communicationType: SettableCommunicationType;
  possibleChannels: CommunicationChannel[];
  selectedChannel: CommunicationChannel;
  disabled: boolean;
  previewMode: boolean;
  onChange: (value: CommunicationChannel) => void;
}

const CommunicationChannelFormItem: React.FC<CommunicationChannelFormItemProps> = ({
  communicationType,
  possibleChannels,
  selectedChannel,
  disabled,
  onChange,
  previewMode,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Grid style={{ display: 'flex', marginTop: 20 }}>
      <Typography style={{ fontSize: 14, fontWeight: 600, width: 120 }}>
        {formatMessage({ id: 'settings.communications.communicationChannel' })}
      </Typography>
      <RadioGroup
        style={{ marginLeft: 20 }}
        name={`${communicationType}.communicationChannel`}
        value={selectedChannel}
        onChange={(_e, value) => onChange(value as CommunicationChannel)}
      >
        {possibleChannels.map((possibleChannel) => (
          <CommunicationChannelRadioItem
            key={`${communicationType}_${selectedChannel}_${possibleChannel}`}
            communicationType={communicationType}
            channel={possibleChannel}
            disabled={disabled}
            previewMode={previewMode}
          />
        ))}
      </RadioGroup>
    </Grid>
  );
};

export default CommunicationChannelFormItem;
