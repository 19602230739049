/* eslint-disable react/no-array-index-key */
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import {
  Colors,
  DEFAULT_ACCEPTED_FILE_TYPES,
  EmptyFileWithUrl,
  MAX_SIZE_ATTACHMENT,
  NewFile,
  S3Object,
  getFileMetaData,
} from '@rentguru/commons-utils';
import { CustomIconButton } from '@up2rent/ui';
import { isNil } from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import FileItemRendering from 'src/components/ui/FileItemRendering';
import DropZoneFieldErrorMessage from 'src/components/ui/Forms/FormField/DropZoneFieldErrorMessages';
import PreviewFileDialogs from 'src/components/ui/PreviewFileDialog';
import { ReactComponent as CloseIcon } from 'src/icons/close.svg';
import { ReactComponent as Upload } from 'src/icons/upload.svg';
import { handleNewHEICFile } from 'src/utils/heicConvertor';

const useLocalStyles = makeStyles({
  container: {
    display: 'flex',
    maxWidth: '92%',
    overflowY: 'hidden',
    overflowX: 'scroll',
    paddingLeft: 0,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    paddingLeft: 0,
  },
  containerItem: {
    position: 'relative',
    left: '0px',
    display: 'flex',
    minWidth: '100px',
    maxWidth: '100%',
    width: '100px',
    height: '100px',
    border: `dashed 1px ${Colors.BLUEY}`,
    borderRadius: '6px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: '16px',
    color: Colors.BLUEY,
  },
  containerItemLong: {
    position: 'relative',
    left: '0px',
    display: 'flex',
    width: '97.5%',
    height: '40px',
    borderRadius: '12px',
    backgroundColor: `${Colors.PORCELAIN_GREY_3}`,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: '20px',
    marginTop: '5px',
  },
  h6: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '20px 0 20px 30px',
  },
  boxContainer: {
    margin: 0,
  },
});

const calculateTotalFileSize = (files: (S3Object | EmptyFileWithUrl | NewFile)[]) => {
  return files.reduce((total, file) => total + ('file' in file ? file.file.size ?? 0 : file.size ?? 0), 0);
};

const shouldPreviewFirstCheck = (fileType?: string | null) => {
  return !isNil(fileType) && (fileType.includes('image') || fileType.includes('pdf'));
};

const isFileExtensionAccepted = (fileRejections: FileRejection[], acceptedMimeTypes: string[]) => {
  const filteredMimeTypes = acceptedMimeTypes.filter((type) => /^\.\w+$/.test(type)); // Only filter out .ext

  return fileRejections.every((fileRejected) => {
    const fileName = fileRejected.file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
    return filteredMimeTypes.includes(fileExtension);
  });
};

interface FileButtonProps {
  fileUrl: string;
  shouldPreviewFirst: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const FileButton: React.FC<FileButtonProps> = ({
  fileUrl,
  shouldPreviewFirst,
  onClick,
  children,
  style = { textDecoration: 'none' },
}) => {
  return (
    <Button
      href={!shouldPreviewFirst ? fileUrl : ''}
      target={!shouldPreviewFirst ? '_blank' : undefined}
      download={!shouldPreviewFirst}
      onClick={onClick}
      style={style}
    >
      {children}
    </Button>
  );
};

interface DropZoneFieldProps extends DropzoneOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrop: (acceptedFiles: any) => void;
  onTotalSizeChange?: (totalSize: number) => void;
  currentFiles: (S3Object | EmptyFileWithUrl | NewFile)[];
  maxFiles?: number;
  centerSelectedFile?: boolean;
  removeFileByIndex?: (index: number) => void;
  disabled?: boolean;
  height?: number;
  width?: number | string;
  style?: CSSProperties;
  itemStyle?: CSSProperties;
  fileItemStyle?: CSSProperties;
  fileItemListStyle?: CSSProperties;
  AddIconMultiple?: React.ReactNode;
  displayingPictures?: boolean;
  disableGuttersForAdd?: boolean;
  imagesOnTop?: boolean;
  acceptedMimeTypes?: string[];
  dataTest?: string;
  isDisplayList?: boolean;
  dropBoxStyle?: CSSProperties;
  error?: boolean;
}

const DropZoneField: React.FC<DropZoneFieldProps> = ({
  onDrop,
  onTotalSizeChange,
  currentFiles,
  maxFiles,
  multiple,
  centerSelectedFile = false,
  removeFileByIndex,
  height = 100,
  width = 580,
  disabled = false,
  style = {},
  itemStyle = {},
  fileItemStyle = {},
  fileItemListStyle = {},
  AddIconMultiple,
  displayingPictures,
  disableGuttersForAdd = false,
  imagesOnTop = false,
  dataTest = '',
  isDisplayList = false,
  acceptedMimeTypes = DEFAULT_ACCEPTED_FILE_TYPES,
  dropBoxStyle,
  error = false,
  ...otherDropZoneOptions
}) => {
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [fileToPreview, setFileToPreview] = useState<S3Object | null>(null);
  const { formatMessage } = useIntl();
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    onDrop,
    accept: acceptedMimeTypes,
    maxSize: MAX_SIZE_ATTACHMENT,
    multiple: multiple || isNil(maxFiles) || maxFiles > 1,
    disabled,
    ...otherDropZoneOptions,
  });
  const localClasses = useLocalStyles();

  const typographyStyle: CSSProperties = {
    fontSize: 12,
    color: Colors.SLATE_GREY,
  };

  const totalFileSize = calculateTotalFileSize(currentFiles);

  useEffect(() => {
    const newTotalSize = calculateTotalFileSize(currentFiles);
    if (onTotalSizeChange) {
      onTotalSizeChange(newTotalSize);
    }
  }, [currentFiles, onTotalSizeChange]);

  const previewOnClick = (
    fileType: string | null | undefined,
    file: S3Object | EmptyFileWithUrl | NewFile,
    fileUrl: string
  ) => {
    return async () => {
      if (['image/heic', 'image/heif'].includes(fileType ?? '')) {
        setPreviewLoading(true);
        const jpgBlob = await handleNewHEICFile((file as EmptyFileWithUrl | NewFile).file as unknown as File);

        if (jpgBlob) {
          const preview = URL.createObjectURL(jpgBlob);
          setFileToPreview({ ...file, url: preview } as unknown as S3Object);
          setPreviewLoading(false);
        }
      } else {
        setFileToPreview({ ...file, url: fileUrl } as unknown as S3Object);
      }
    };
  };

  return (
    <>
      <Grid
        style={{ display: 'flex', flexDirection: imagesOnTop ? 'column-reverse' : 'column', margin: 0, padding: 0 }}
      >
        <Box
          display="flex"
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: centerSelectedFile || displayingPictures ? 'center' : 'flex-start',
            alignItems: 'center',
            ...(imagesOnTop ? { margin: 0 } : {}),
            ...style,
          }}
          className={localClasses.boxContainer}
          data-test={`DropZone${dataTest}`}
        >
          {!displayingPictures && (
            <List style={fileItemListStyle}>
              {currentFiles.map((file, index) => {
                const { fileType, fileSize, fileUrl, fileName } = getFileMetaData(file);
                const shouldPreviewFirst = shouldPreviewFirstCheck(fileType);
                return (
                  <ListItem key={`${fileSize}-${index}`} style={{ width, height, ...itemStyle }}>
                    <FileButton
                      fileUrl={fileUrl}
                      shouldPreviewFirst={shouldPreviewFirst}
                      onClick={
                        shouldPreviewFirst
                          ? () => {
                              setFileToPreview({ ...file, url: fileUrl } as unknown as S3Object);
                            }
                          : undefined
                      }
                      style={{ height, borderRadius: 6, width, minWidth: 'unset' }}
                    >
                      <ListItemAvatar>
                        <FileItemRendering
                          fileType={fileType!}
                          fileUrl={fileUrl}
                          fileName={fileName}
                          fileBlob={'file' in file ? file.file : undefined}
                          removeFunction={
                            !disabled && !isNil(removeFileByIndex) ? () => removeFileByIndex(index) : undefined
                          }
                          height={height}
                          width={!isNaN(Number(width)) ? Number(width) : height}
                          style={fileItemStyle}
                        />
                      </ListItemAvatar>
                    </FileButton>
                  </ListItem>
                );
              })}
            </List>
          )}

          {currentFiles.length !== maxFiles && (
            <ListItem
              key="add-new"
              style={{
                width: currentFiles.length > 0 && !displayingPictures ? height + 20 : undefined,
                height,
                ...itemStyle,
              }}
              disableGutters={disableGuttersForAdd}
            >
              <Grid
                {...getRootProps()}
                style={{
                  width: currentFiles.length > 0 && !displayingPictures ? height : width,
                  height,
                  marginLeft: currentFiles.length > 0 && !displayingPictures ? 10 : 0,
                  borderColor: error ? Colors.RED_ORANGE : Colors.BLUEY,
                  borderWidth: 1.5,
                  borderStyle: 'dashed',
                  borderRadius: 6,
                  boxSizing: 'border-box',
                  backgroundColor: Colors.CLASSICAL_WHITE,
                  ...dropBoxStyle,
                }}
              >
                <input {...getInputProps()} />

                {isDragActive && !isDragReject && (
                  <Typography style={{ position: 'absolute' }}>
                    {formatMessage({
                      id: 'lease.addLease.dragFileAuthorized',
                    })}
                  </Typography>
                )}
                {isDragReject && !isFileExtensionAccepted(fileRejections, acceptedMimeTypes) && (
                  <Typography style={{ position: 'absolute' }}>
                    {formatMessage({
                      id: 'lease.addLease.dragFileUnAuthorized',
                    })}
                  </Typography>
                )}

                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height,
                  }}
                >
                  {!isNil(AddIconMultiple) ? (
                    AddIconMultiple
                  ) : (
                    <>
                      <Upload style={{ fill: Colors.SLATE_GREY }} />
                      <Typography
                        style={{
                          color: Colors.SLATE_GREY,
                          fontFamily: 'Mulish',
                          fontSize: 12,
                          textAlign: 'center',
                        }}
                      >
                        {formatMessage({
                          id: 'lease.addLease.dragFile',
                        })}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </ListItem>
          )}
        </Box>

        {!isDisplayList && displayingPictures && (
          <>
            <Grid
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: 40,
                color: Colors.NOBEL_GREY,
              }}
              className="blink"
            >
              {currentFiles.length > 5 && (
                <ArrowForward style={{ position: 'absolute', ...(imagesOnTop ? { bottom: 150 } : {}) }} />
              )}
            </Grid>
            <Grid
              style={{
                marginLeft: 0,
                width: '97.5%',
                paddingTop: 10,
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                left: 5,
                bottom: 10,
                scrollbarWidth: 'none',
                height: currentFiles.length > 0 ? 150 : 0,
              }}
              className="noScroll"
            >
              <ul
                className={localClasses.container}
                style={{
                  overflowX: currentFiles.length <= 4 ? 'hidden' : 'scroll',
                  minHeight: currentFiles.length > 0 ? 130 : 0,
                }}
              >
                {currentFiles.map((file, index) => {
                  const { fileType, fileSize, fileUrl, fileName } = getFileMetaData(file);
                  const shouldPreviewFirst = shouldPreviewFirstCheck(fileType);

                  return (
                    <li key={`${fileSize}-${fileName}-${index}`} className={localClasses.containerItem}>
                      <FileButton
                        fileUrl={fileUrl}
                        shouldPreviewFirst={shouldPreviewFirst}
                        onClick={shouldPreviewFirst ? previewOnClick(fileType, file, fileUrl) : undefined}
                        style={{ textDecoration: 'none', width: 100, height: 100 }}
                      >
                        <ListItemAvatar>
                          <FileItemRendering
                            fileType={fileType!}
                            fileUrl={fileUrl}
                            fileName={fileName}
                            fileBlob={'file' in file ? file.file : undefined}
                            removeFunction={
                              !disabled && !isNil(removeFileByIndex) ? () => removeFileByIndex(index) : undefined
                            }
                            height={100}
                            width={100}
                            style={{ position: 'relative', top: 3, ...fileItemStyle }}
                          />
                        </ListItemAvatar>
                      </FileButton>
                    </li>
                  );
                })}
              </ul>
            </Grid>
          </>
        )}
        {isDisplayList && displayingPictures && (
          <>
            <ul className={localClasses.listContainer}>
              {currentFiles.map((file, index) => {
                const { fileType, fileSize, fileUrl, fileName } = getFileMetaData(file);

                const shouldPreviewFirst = shouldPreviewFirstCheck(fileType);
                return (
                  <li
                    key={`${fileSize}-${fileName}-${index}`}
                    style={{ width: '97.5%' }}
                    className={localClasses.containerItemLong}
                  >
                    <FileButton
                      fileUrl={fileUrl}
                      shouldPreviewFirst={shouldPreviewFirst}
                      onClick={shouldPreviewFirst ? previewOnClick(fileType, file, fileUrl) : undefined}
                      style={{
                        textDecoration: 'none',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ListItemAvatar>
                        <FileItemRendering
                          fileType={fileType!}
                          fileUrl={fileUrl}
                          fileName={fileName}
                          fileBlob={'file' in file ? file.file : undefined}
                          height={30}
                          width={40}
                          style={{ position: 'relative', ...fileItemStyle }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '75%',
                              }}
                            >
                              <Typography style={typographyStyle}>{fileName}</Typography>
                            </span>
                            {fileSize && (
                              <Typography style={typographyStyle}>{`${
                                Math.round(fileSize / 10000) / 100
                              } mb`}</Typography>
                            )}
                          </div>
                        }
                        style={{ flexGrow: 1, textAlign: 'left' }}
                      />
                      {!disabled && !isNil(removeFileByIndex) && (
                        <CustomIconButton
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeFileByIndex(index);
                          }}
                          size="small"
                          style={{ marginLeft: '8px' }}
                          Icon={CloseIcon}
                        />
                      )}
                    </FileButton>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </Grid>

      <DropZoneFieldErrorMessage
        fileRejections={fileRejections}
        totalFileSize={totalFileSize}
        maxFileSize={MAX_SIZE_ATTACHMENT}
      />

      <PreviewFileDialogs
        open={!isNil(fileToPreview) || previewLoading}
        file={fileToPreview}
        onClose={() => setFileToPreview(null)}
        withFileName={false}
        loading={previewLoading}
      />
    </>
  );
};

export default DropZoneField;
