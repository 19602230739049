import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, IconButton, Grid } from '@material-ui/core';
import NotificationBox from './NotificationBox';
import { useCommunications } from '../../../hooks/CommunicationsContext';
import SlicedContent from './SlicedContent';
import { ReactComponent as ExternalIcon } from '../../../icons/external.svg';
import { Link } from 'react-router-dom';
import CommunicationRow from './BoxCustomRows/CommunicationRow';
import {
  Communication,
  Template,
  Colors,
  COMMUNICATION_BOX_KEY,
  defaultMailTemplates,
  TemplateSubType,
} from '@rentguru/commons-utils';
import { useTemplates } from 'src/hooks/TemplatesContext';
import isNil from 'lodash/isNil';
import { ConfirmDialog } from '@up2rent/ui';
import SnoozeNotificationDialog from './DashboardDialogs/SnoozeNotificationDialog';
import { compareAsc } from 'date-fns';
import CommunicationsHeader from './BoxCustomHeaders/CommunicationsHeader';
import isEmpty from 'lodash/isEmpty';
import { CommunicationWithTemplate } from 'src/utils/communicationUtils';
import TodosLoading from './TodosLoading';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

export const ROW_HEIGHT = 27;

const communicationSortFunction = (
  communication1: CommunicationWithTemplate,
  communication2: CommunicationWithTemplate
): number => {
  if (isNil(communication1.recipient) || isEmpty(communication1.recipient)) return 1;
  if (isNil(communication2.recipient) || isEmpty(communication2.recipient)) return -1;
  if (isNil(communication1.createdAt)) return 1;
  if (isNil(communication2.createdAt)) return -1;
  const draftCmp = compareAsc(new Date(communication1.createdAt), new Date(communication2.createdAt));
  if (draftCmp !== 0) return draftCmp;

  const type1 = communication1.template!.subType;
  const type2 = communication2.template!.subType;
  if (isNil(type1)) return 1;
  if (isNil(type2)) return -1;
  return type1.localeCompare(type2);
};

const REMINDER_TEMPLATES_SUB_TYPES = [
  TemplateSubType.LEASE_UNPAID_AMOUNT_REMINDER,
  TemplateSubType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER,
  TemplateSubType.LEASE_UNPAID_AMOUNT_FORMAL_NOTICE,
  TemplateSubType.LEASE_PAYMENT_REQUEST,
];
export const SNOOZABLE_COMMUNICATION_SUB_TYPES = [
  TemplateSubType.LEASE_UNPAID_AMOUNT_REMINDER,
  TemplateSubType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER,
  TemplateSubType.LEASE_UNPAID_AMOUNT_FORMAL_NOTICE,
];
export const SENDABLE_COMMUNICATION_SUB_TYPES = [
  TemplateSubType.LEASE_PAYMENT_REQUEST,
  TemplateSubType.LEASE_UNPAID_AMOUNT_REMINDER,
  TemplateSubType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER,
];

const DashboardCommunications: React.FC = () => {
  const { formatMessage } = useIntl();
  const { getLightCommunicationsToSend, communicationsLoading, deleteCommunication } = useCommunications();
  const { templates, templatesLoading } = useTemplates();

  const [ignoreCommunication, setIgnoreCommunication] = useState<Communication | null>(null);
  const [communicationToSnooze, setCommunicationToSnooze] = useState<Communication | null>(null);

  if (communicationsLoading || templatesLoading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }
  const paymentReminderTemplatesObjects = [...templates, ...defaultMailTemplates].reduce(
    (acc: { [key: string]: Template }, template: Template) => {
      if (!isNil(template.subType) && REMINDER_TEMPLATES_SUB_TYPES.includes(template.subType)) {
        acc[template.id] = template;
      }
      return acc;
    },
    {}
  );
  const paymentReminderTemplatesIds = Object.keys(paymentReminderTemplatesObjects);
  const paymentReminderCommunications = getLightCommunicationsToSend().reduce(
    (acc: CommunicationWithTemplate[], communication: Communication) => {
      const communicationTemplateId = communication.communicationTemplateId;
      if (!isNil(communicationTemplateId) && paymentReminderTemplatesIds.includes(communicationTemplateId)) {
        const communicationWithTemplate = {
          ...communication,
          template: paymentReminderTemplatesObjects[communicationTemplateId],
        };
        acc.push(communicationWithTemplate);
      }
      return acc;
    },
    []
  );
  const communicationsToDisplay = paymentReminderCommunications.sort(communicationSortFunction).map((communication) => {
    return (
      <CommunicationRow
        key={communication.id}
        communication={communication}
        setIgnoreCommunication={setIgnoreCommunication}
        setCommunicationToSnooze={setCommunicationToSnooze}
      />
    );
  });

  const titleComponent = (
    <Grid style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Typography
        style={{
          flex: 1,
          textAlign: 'left',
          color: Colors.CLASSICAL_BLACK,
          fontFamily: 'Mulish',
          fontSize: '16px',
          fontWeight: 'bold',
          marginLeft: 20,
        }}
      >
        {`${formatMessage({ id: 'lease.detail.menu.communications' })} (${communicationsToDisplay.length})`}
      </Typography>
      <IconButton
        size="small"
        onMouseDown={stopPropagation}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
        component={Link}
        to="/communications"
        style={{ marginLeft: 10 }}
      >
        <ExternalIcon style={{ fill: Colors.DODGER_BLUE }} />
      </IconButton>
    </Grid>
  );
  return (
    <>
      <NotificationBox boxKey={COMMUNICATION_BOX_KEY} titleComponent={titleComponent}>
        <SlicedContent
          data={communicationsToDisplay}
          header={<CommunicationsHeader />}
          limit={3}
          boxKey={COMMUNICATION_BOX_KEY}
          rowHeight={ROW_HEIGHT}
        />
      </NotificationBox>
      {!isNil(ignoreCommunication) && (
        <ConfirmDialog
          open={!isNil(ignoreCommunication)}
          confirmText={formatMessage({ id: 'lease.detail.action.confirm' })}
          confirmAction={async () => {
            await deleteCommunication(ignoreCommunication.id);
            setIgnoreCommunication(null);
          }}
          cancelAction={() => setIgnoreCommunication(null)}
          mainText={formatMessage({ id: 'dashboard.communication.deleteReminder' })}
          formatMessage={formatMessage}
        />
      )}
      {!isNil(communicationToSnooze) && (
        <SnoozeNotificationDialog notification={communicationToSnooze} onClose={() => setCommunicationToSnooze(null)} />
      )}
    </>
  );
};

export default DashboardCommunications;
