import { Grid, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Skeleton } from '@material-ui/lab';
import { AccountType, Colors, ModelWithVersion, StatementSetting, StatementSettingType } from '@rentguru/commons-utils';
import { ContentHeader, ElevatedPaper, LoaderButton } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import AccountTypeItem from 'src/components/ui/AccountTypeItem';
import CustomizedSelect from 'src/components/ui/CustomizedSelect';
import CustomizedSwitch from 'src/components/ui/CustomizedSwitch';
import { useStatementSettings } from 'src/hooks/StatementSettingsContext';
import { useUser } from 'src/hooks/UserContext';
import { ReactComponent as AgencyIcon } from 'src/icons/agency.svg';
import { ReactComponent as FundIcon } from 'src/icons/fund.svg';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { ReactComponent as OwnerIcon } from 'src/icons/owner.svg';
import * as Yup from 'yup';

const AccountTypeSchema = Yup.object()
  .shape({
    leasesStatementFrequency: Yup.number().required().min(1),
    ownersStatementFrequency: Yup.number().when('accountType', {
      is: AccountType.AGENCY,
      then: Yup.number().required().min(1),
      otherwise: Yup.number().nullable(),
    }),
  })
  .required();

interface AccountTypeFormValues {
  accountType: AccountType;
  ownersStatementFrequency: number;
  ownersStatementFrequencyPeriod: 'YEAR' | 'MONTH';
  leasesStatementFrequency: number;
  leasesStatementFrequencyPeriod: 'YEAR' | 'MONTH';
  FSMABreakdown: boolean;
}

const AccountTypeForm = () => {
  const { formatMessage } = useIntl();
  const { accountType, isAgency } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { statementSettings, updateStatementSetting, statementSettingsLoading } = useStatementSettings(true);

  if (statementSettingsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton />
      </div>
    );
  }

  const [defaultLeaseSettings, defaultOwnerSettings] = statementSettings.reduce(
    (acc: [StatementSetting | undefined, StatementSetting | undefined], statementSetting) => {
      if (statementSetting.custom === false) {
        if (statementSetting.type === StatementSettingType.LEASE) acc[0] = statementSetting;
        else if (statementSetting.type === StatementSettingType.OWNER) acc[1] = statementSetting;
      }
      return acc;
    },
    [undefined, undefined]
  );

  if (isNil(defaultLeaseSettings) || (isNil(defaultOwnerSettings) && isAgency)) return null;

  const handleSubmit = async (
    values: AccountTypeFormValues,
    { setStatus, setSubmitting }: FormikHelpers<AccountTypeFormValues>
  ) => {
    // TODO handle FSMA
    const {
      ownersStatementFrequency,
      ownersStatementFrequencyPeriod,
      leasesStatementFrequency,
      leasesStatementFrequencyPeriod,
    } = values;

    const updateDefaultStatementSettingsPromises: Promise<StatementSetting>[] = [];
    const leaseMonthFrequency =
      leasesStatementFrequencyPeriod === 'MONTH' ? leasesStatementFrequency : leasesStatementFrequency * 12;
    updateDefaultStatementSettingsPromises.push(
      updateStatementSetting({
        id: defaultLeaseSettings.id,
        _version: (defaultLeaseSettings as ModelWithVersion<StatementSetting>)._version,
        monthFrequency: leaseMonthFrequency,
      })
    );

    if (defaultOwnerSettings) {
      const ownerMonthFrequency =
        ownersStatementFrequencyPeriod === 'MONTH' ? ownersStatementFrequency : ownersStatementFrequency * 12;
      updateDefaultStatementSettingsPromises.push(
        updateStatementSetting({
          id: defaultOwnerSettings.id,
          _version: (defaultOwnerSettings as ModelWithVersion<StatementSetting>)._version,
          monthFrequency: ownerMonthFrequency,
        })
      );
    }

    await Promise.all(updateDefaultStatementSettingsPromises);
    setSubmitting(false);
    setStatus(true);
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
  };

  const initialValues: AccountTypeFormValues = {
    accountType: accountType as AccountType,
    ownersStatementFrequency: defaultOwnerSettings ? defaultOwnerSettings.monthFrequency : 1,
    ownersStatementFrequencyPeriod: 'MONTH',
    leasesStatementFrequency: defaultLeaseSettings.monthFrequency,
    leasesStatementFrequencyPeriod: 'MONTH',
    FSMABreakdown: false,
  };

  return (
    <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
      <ContentHeader title={formatMessage({ id: 'settings.generalSection.accountType' })} />
      <Divider style={{ marginBottom: 20 }} />
      <Formik initialValues={initialValues} validationSchema={AccountTypeSchema} onSubmit={handleSubmit}>
        {({ values, errors, setFieldValue, isSubmitting, status }) => {
          const ExpenseStatementFrequencyComponent = (
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography style={{ fontSize: 14, fontWeight: 700, textAlign: 'left', marginRight: 10 }}>
                  {formatMessage({ id: 'settings.generalSection.frequencyExpenseStatement' })}
                </Typography>
                <Tooltip
                  title={formatMessage({ id: 'settings.generalSection.frequencyBuildingHelper' })}
                  placement="bottom"
                >
                  <Grid>
                    <InfoSvg style={{ fill: Colors.SILVER }} />
                  </Grid>
                </Tooltip>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography style={{ fontSize: 14, marginRight: 15, fontWeight: 600 }}>{`${formatMessage({
                  id: 'settings.generalSection.every',
                })} `}</Typography>
                <TextField
                  title={' '} // Avoid having small fields
                  name="leasesStatementFrequency"
                  onChange={(e) => {
                    setFieldValue('leasesStatementFrequency', e.target.value);
                  }}
                  value={values.leasesStatementFrequency}
                  type="number"
                  margin="dense"
                  variant="filled"
                  inputProps={{ style: { width: 80, padding: '15px 12px' }, min: 0, step: 1 }}
                  error={Boolean(errors.ownersStatementFrequency)}
                  style={{ marginRight: 15, width: 80 }}
                />
                <CustomizedSelect
                  fieldName="leasesStatementFrequencyPeriod"
                  value={values.leasesStatementFrequencyPeriod}
                  margin="dense"
                  FormControlProps={{ style: { minWidth: 120 } }}
                  SelectProps={{
                    SelectDisplayProps: {
                      style: { width: 80, padding: '15px 12px' },
                    },
                  }}
                >
                  <MenuItem id="year" key="year" value="YEAR">
                    {formatMessage({ id: 'rentalUnit.filter.years' })}
                  </MenuItem>
                  <MenuItem id="month" key="month" value="MONTH">
                    {formatMessage({ id: 'rentalUnit.filter.month' })}
                  </MenuItem>
                </CustomizedSelect>
              </div>
            </>
          );
          const OwnerAndTenantStatementFrequencyComponent = (
            <>
              <div>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography style={{ fontSize: 14, fontWeight: 700, textAlign: 'left', marginRight: 10 }}>
                    {formatMessage({ id: 'settings.generalSection.frequencyOwnerStatement' })}
                  </Typography>
                  <Tooltip
                    title={formatMessage({ id: 'settings.generalSection.frequencyOwnerHelper' })}
                    placement="bottom"
                  >
                    <Grid>
                      <InfoSvg style={{ fill: Colors.SILVER }} />
                    </Grid>
                  </Tooltip>
                </div>
                <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, textAlign: 'left' }}>
                  {formatMessage({ id: 'settings.generalSection.frequencyOwnerStatementHelper' })}
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Typography style={{ fontSize: 14, marginRight: 15, fontWeight: 600 }}>{`${formatMessage({
                    id: 'settings.generalSection.every',
                  })} `}</Typography>
                  <TextField
                    title={' '} // Avoid having small fields
                    name="ownersStatementFrequency"
                    onChange={(e) => {
                      setFieldValue('ownersStatementFrequency', e.target.value);
                    }}
                    value={values.ownersStatementFrequency}
                    type="number"
                    margin="dense"
                    variant="filled"
                    inputProps={{ style: { width: 80, padding: '15px 12px' }, min: 0, step: 1 }}
                    error={Boolean(errors.ownersStatementFrequency)}
                    style={{ marginRight: 15, width: 80 }}
                  />
                  <CustomizedSelect
                    fieldName="ownersStatementFrequencyPeriod"
                    value={values.ownersStatementFrequencyPeriod}
                    margin="dense"
                    FormControlProps={{ style: { minWidth: 120 } }}
                    SelectProps={{
                      SelectDisplayProps: {
                        style: { width: 80, padding: '15px 12px' },
                      },
                    }}
                  >
                    <MenuItem id="year" key="year" value="YEAR">
                      {formatMessage({ id: 'rentalUnit.filter.years' })}
                    </MenuItem>
                    <MenuItem id="month" key="month" value="MONTH">
                      {formatMessage({ id: 'rentalUnit.filter.month' })}
                    </MenuItem>
                  </CustomizedSelect>
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography style={{ fontSize: 14, fontWeight: 700, textAlign: 'left', marginRight: 10 }}>
                    {formatMessage({ id: 'settings.generalSection.frequencyTenantStatement' })}
                  </Typography>
                  <Tooltip
                    title={formatMessage({ id: 'settings.generalSection.frequencyBuildingHelper' })}
                    placement="bottom"
                  >
                    <Grid>
                      <InfoSvg style={{ fill: Colors.SILVER }} />
                    </Grid>
                  </Tooltip>
                </div>
                <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, textAlign: 'left' }}>
                  {formatMessage({ id: 'accounting.charges' }, { value: 2 })}
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Typography style={{ fontSize: 14, marginRight: 15, fontWeight: 600 }}>{`${formatMessage({
                    id: 'settings.generalSection.every',
                  })} `}</Typography>
                  <TextField
                    title={' '} // Avoid having small fields
                    name="leasesStatementFrequency"
                    onChange={(e) => {
                      setFieldValue('leasesStatementFrequency', e.target.value);
                    }}
                    value={values.leasesStatementFrequency}
                    type="number"
                    margin="dense"
                    variant="filled"
                    inputProps={{ style: { width: 80, padding: '15px 12px' }, min: 0, step: 1 }}
                    error={Boolean(errors.leasesStatementFrequency)}
                    style={{ marginRight: 15, width: 80 }}
                  />
                  <CustomizedSelect
                    fieldName="leasesStatementFrequencyPeriod"
                    value={values.leasesStatementFrequencyPeriod}
                    margin="dense"
                    FormControlProps={{ style: { minWidth: 120 } }}
                    SelectProps={{
                      SelectDisplayProps: {
                        style: { width: 80, padding: '15px 12px' },
                      },
                    }}
                  >
                    <MenuItem id="year" key="year" value="YEAR">
                      {formatMessage({ id: 'rentalUnit.filter.years' })}
                    </MenuItem>
                    <MenuItem id="month" key="month" value="MONTH">
                      {formatMessage({ id: 'rentalUnit.filter.month' })}
                    </MenuItem>
                  </CustomizedSelect>
                </div>
              </div>
            </>
          );

          const ExpenseStatementFrequencyComponentAndFSMA = (
            <>
              {ExpenseStatementFrequencyComponent}
              <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 20 }}>
                <Typography style={{ fontSize: 14, fontWeight: 700, marginRight: 30 }}>
                  {formatMessage({
                    id: 'settings.generalSection.fsmaBreakdown',
                  })}
                </Typography>
                <CustomizedSwitch
                  checked={values.FSMABreakdown}
                  onChange={() => {
                    setFieldValue('FSMABreakdown', !values.FSMABreakdown);
                  }}
                />
              </div>
            </>
          );
          return (
            <Form>
              <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                {values.accountType === AccountType.OWNER && (
                  <AccountTypeItem
                    Icon={OwnerIcon}
                    onClick={() => {
                      // setFieldValue('accountType', AccountType.OWNER);
                    }}
                    mainText={formatMessage({ id: 'building.detail.general.owner' })}
                    subText={formatMessage({ id: 'signup.ownerSubText' })}
                    selected={values.accountType === AccountType.OWNER}
                    style={{ marginTop: 40 }}
                    selectedColor={Colors.SILVER}
                    CollapseComponent={ExpenseStatementFrequencyComponent}
                  />
                )}
                {values.accountType === AccountType.AGENCY && (
                  <AccountTypeItem
                    Icon={AgencyIcon}
                    onClick={() => {
                      // setFieldValue('accountType', AccountType.AGENCY);
                    }}
                    mainText={formatMessage({ id: 'lease.addLease.realEstateAgency' })}
                    subText={formatMessage({ id: 'signup.agencySubText' })}
                    selected={values.accountType === AccountType.AGENCY}
                    style={{ marginTop: 20 }}
                    selectedColor={Colors.SILVER}
                    CollapseComponent={OwnerAndTenantStatementFrequencyComponent}
                  />
                )}
                {values.accountType === AccountType.FUND && (
                  <AccountTypeItem
                    Icon={FundIcon}
                    onClick={() => {
                      // setFieldValue('accountType', AccountType.FUND);
                    }}
                    mainText={formatMessage({ id: 'signup.realEstateFund' })}
                    subText={formatMessage({ id: 'signup.fundSubText' })}
                    selected={values.accountType === AccountType.FUND}
                    style={{ marginTop: 20, marginBottom: 30 }}
                    selectedColor={Colors.SILVER}
                    CollapseComponent={ExpenseStatementFrequencyComponentAndFSMA}
                  />
                )}
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div
                style={{
                  marginBottom: 20,
                  marginRight: 30,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({ id: 'save' })}
                </LoaderButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ElevatedPaper>
  );
};

export default AccountTypeForm;
