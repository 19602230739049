import { FormControl, FormControlLabel, Grid, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import { AgencyRateFeesType, AgencyRateIncludedAmount, Colors } from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import StyledRadio from 'src/components/ui/StyledRadio';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { AddEditAgencyRateValues } from '../AddEditAgencyRateForm';
import { getExclusiveValueInCheckedList, handleCheckedItemsInList, isItemCheckedInList } from './utils';

const includedInAmountExclusiveChoice = [
  AgencyRateIncludedAmount.RENT_WITHOUT_CHARGES,
  AgencyRateIncludedAmount.INDEXED_PRICE,
];

const AgencyRateIncludedInAmountField = () => {
  const { values, setFieldValue, errors, touched }: FormikProps<AddEditAgencyRateValues> = useFormikContext();
  const { formatMessage } = useIntl();

  const error = Boolean(errors.includedInAmount && touched.includedInAmount);
  return (
    <>
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'start' }}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: 0,
            color: error ? Colors.RED_ORANGE : Colors.CLASSICAL_BLACK,
          }}
        >
          {formatMessage({ id: 'agencyRates.detail.includedInAmount' })}
        </Typography>
        <Tooltip title={formatMessage({ id: 'agencyRates.detail.includedInAmountInfo' })} placement="bottom">
          <Grid>
            <InfoSvg style={{ fill: Colors.SILVER, paddingLeft: 10 }} />
          </Grid>
        </Tooltip>
      </div>

      <div style={{ display: 'flex' }}>
        <FormControl component="fieldset">
          <RadioGroup
            value={getExclusiveValueInCheckedList('includedInAmount', values, includedInAmountExclusiveChoice)}
            onChange={(_e, value) => {
              handleCheckedItemsInList(
                setFieldValue,
                'includedInAmount',
                values,
                value,
                includedInAmountExclusiveChoice
              );
            }}
          >
            {includedInAmountExclusiveChoice.map((element) => (
              <FormControlLabel
                key={element}
                value={element}
                control={<StyledRadio />}
                label={
                  <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                    {formatMessage({ id: `agencyRates.detail.${element}` })}
                  </Typography>
                }
                disabled={
                  values.feesAppliedTo === AgencyRateFeesType.AMOUNT_COLLECTED &&
                  element === AgencyRateIncludedAmount.RENT_WITHOUT_CHARGES
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>

      <Typography
        style={{
          marginTop: 10,
          fontSize: 14,
          fontWeight: 'bold',
          fontStyle: 'normal',
          letterSpacing: 0,
          color: error ? Colors.RED_ORANGE : Colors.CLASSICAL_BLACK,
        }}
      >
        {formatMessage({ id: 'agencyRates.detail.includedInAmountAdd' })}
      </Typography>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {Object.keys(AgencyRateIncludedAmount).map((element) => {
          if (includedInAmountExclusiveChoice.includes(element as AgencyRateIncludedAmount)) {
            return null;
          }
          return (
            <div style={{ display: 'flex', alignItems: 'center' }} key={element}>
              <FormControlLabel
                control={
                  <CustomCheckBox
                    isChecked={isItemCheckedInList('includedInAmount', values, element)}
                    onCheck={(_e) => {
                      handleCheckedItemsInList(setFieldValue, 'includedInAmount', values, element);
                    }}
                    secondary
                    name="includedInAmount"
                  />
                }
                label={formatMessage({ id: `agencyRates.detail.${element}` })}
                disabled={
                  values.feesAppliedTo === AgencyRateFeesType.AMOUNT_COLLECTED &&
                  element === AgencyRateIncludedAmount.RENTAL_DISCOUNT
                }
              />
              {element === AgencyRateIncludedAmount.RENTAL_DISCOUNT && (
                <Tooltip
                  title={formatMessage({ id: 'agencyRates.detail.creditNotesIncludedHelper' })}
                  placement="bottom"
                >
                  <Grid>
                    <InfoSvg style={{ fill: Colors.SILVER }} />
                  </Grid>
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AgencyRateIncludedInAmountField;
