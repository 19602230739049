import { Divider, Grid } from '@material-ui/core';
import CreditCosts from './CreditCosts';
import MandateStatus from './MandateStatus';

const SubscriptionPlan = () => (
  <Grid style={{ marginLeft: 25, marginRight: 25 }}>
    <MandateStatus />
    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
    <CreditCosts />
  </Grid>
);

export default SubscriptionPlan;
