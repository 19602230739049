import {
  CreateTemplateInput,
  CreateTemplateMutationVariables,
  Language,
  Template,
  TemplateSubType,
  TemplateType,
  UpdateTemplateInput,
  cleanInputCreate,
  cleanInputUpdate,
  createTemplate as createTemplateQuery,
  deleteTemplate as deleteTemplateQuery,
  getDefaultTemplates,
  getReadId,
  getTableClientId,
  getTemplateForTypeSubTypeAndLanguage,
  syncTemplates,
  updateTemplate as updateTemplateQuery,
} from '@rentguru/commons-utils';
import { deleteAndHideEntity, list, mutation } from '@up2rent/fetch-utils';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useUser } from './UserContext';

interface TemplateWithVersioning extends Template {
  _version: number;
}

export interface TemplateContext {
  templates: Template[];
  createTemplate: (input: CreateTemplateInput | Omit<CreateTemplateInput, 'clientId' | 'readId'>) => Promise<Template>;
  updateTemplate: (
    original: UpdateTemplateInput,
    updates: Omit<UpdateTemplateInput, 'id' | 'clientId' | 'readId'>
  ) => Promise<Template>;
  deleteTemplate: (template: Template) => Promise<Template>;
  getTemplateOrDefault: (type: TemplateType, subType: TemplateSubType, language: Language) => Template | undefined;
  templatesLoading: boolean | undefined;
  templatesError: string | undefined;
}

// eslint-disable-next-line no-redeclare
export const TemplateContext = React.createContext<TemplateContext | null>(null);

export const TemplateContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [templatesLoading, setTemplatesLoading] = useState<boolean | undefined>(undefined);
  const [templatesError, setTemplatesError] = useState<string | Error | undefined>(undefined);

  const fetchTemplates = async (): Promise<Template[]> => {
    return await list<Template>(syncTemplates, 'clientId', getTableClientId(clientId!, 'Template'));
  };

  const { clientId } = useUser();
  useEffect(() => {
    let unmounted = false;

    const queryEntity = async () => {
      setTemplatesLoading(true);
      try {
        const templatesResult = await fetchTemplates();
        if (!unmounted) {
          setTemplates(templatesResult);
        }
      } catch (err) {
        if (!unmounted) {
          setTemplatesError(err as Error);
        }
      } finally {
        setTemplatesLoading(false);
      }
    };

    queryEntity();
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTemplate = async (
    input: CreateTemplateInput | Omit<CreateTemplateInput, 'id' | 'clientId' | 'readId'>
  ): Promise<Template> => {
    const result = await mutation<Template, CreateTemplateMutationVariables>(createTemplateQuery, {
      input: {
        ...(cleanInputCreate(input) as CreateTemplateInput),
        readId: getReadId(clientId!, 'Template'),
        clientId: getTableClientId(clientId!, 'Template'),
      },
    });
    setTemplates((t) => [...t, result]);
    return result;
  };

  const updateTemplate = async (
    original: UpdateTemplateInput,
    updates: Omit<UpdateTemplateInput, 'id' | 'clientId' | 'readId'>
  ): Promise<Template> => {
    const result = await mutation<Template>(updateTemplateQuery, {
      input: { ...cleanInputUpdate(updates), id: original.id, _version: (original as TemplateWithVersioning)._version },
    }).then(() => {
      return mutation<Template>(updateTemplateQuery, {
        input: {
          id: original.id,
          clientId: `${original.clientId}`,
          _version: (original as TemplateWithVersioning)._version + 1,
        },
      });
    });
    setTemplates((ts) =>
      ts.map((t) => {
        if (t.id === result.id) return result;
        return t;
      })
    );
    return result;
  };

  const deleteTemplate = async (template: Template): Promise<Template> => {
    const result = await deleteAndHideEntity<Template>(template, deleteTemplateQuery, updateTemplateQuery);
    setTemplates(templates.filter((t) => t.id !== template.id));
    return result;
  };

  const getTemplateOrDefault = (type: TemplateType, subType: TemplateSubType, language: Language) => {
    const savedTemplate = getTemplateForTypeSubTypeAndLanguage(templates, type, subType, language);

    if (savedTemplate) {
      return savedTemplate;
    }
    const defaultsTemplates = getDefaultTemplates(type);

    return getTemplateForTypeSubTypeAndLanguage(defaultsTemplates, type, subType, language);
  };

  const values = useMemo(
    () => ({
      templates,
      createTemplate,
      updateTemplate,
      deleteTemplate,
      getTemplateOrDefault,
      templatesError: templatesError as string | undefined,
      templatesLoading,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templates, templatesError, templatesLoading]
  );

  return <TemplateContext.Provider value={values}>{children}</TemplateContext.Provider>;
};

export const useTemplates = (): TemplateContext => {
  const context = useContext<TemplateContext | null>(TemplateContext);

  if (context === undefined) {
    throw new Error('`useTemplates` hook must be used within a `TemplateContextProvider` component');
  }
  return context as TemplateContext;
};
