import { Divider, Grid, Typography } from '@material-ui/core';
import { CommunicationChannel, CommunicationSettingsProfileReferent } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { OwnerSettableCommunicationType } from '../CommunicationSettingsProfileUtils/OwnerCommunicationSettingsUtils';
import CommunicationSettingsFormItem from './CommunicationSettingsForm';

const OwnerCommunicationSettingsForm: React.FC<{ defaultCollapse?: boolean }> = ({ defaultCollapse = false }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid>
      <Typography style={{ textAlign: 'start', fontWeight: 'bold', fontSize: 16, marginTop: 20, marginBottom: 10 }}>
        {formatMessage({ id: 'settings.communications.communicationTypesGroups.statementsSending' })}
      </Typography>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      <CommunicationSettingsFormItem
        communicationType={OwnerSettableCommunicationType.OWNER_STATEMENT}
        possibleCommunicationChannels={[CommunicationChannel.EMAIL, CommunicationChannel.LETTER]}
        referent={CommunicationSettingsProfileReferent.OWNER}
        defaultCollapse={defaultCollapse}
        // TO UPDATE IN COMMUNICATION V2
        previewProfile={null}
      />
    </Grid>
  );
};

export default OwnerCommunicationSettingsForm;
