import { Chip, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Colors, CommunicationChannel, CommunicationRecipientType } from '@rentguru/commons-utils';
import { isEmpty, isEqual, isNil } from 'lodash';
import { useIntl } from 'react-intl';
import {
  CustomMenuItemType,
  getOptionLabelFromCustomMenuItemType,
  useCustomizedComboStyles,
} from 'src/components/ui/ComboBox/TextComboBox';
import TextDetail from 'src/components/ui/TextDetail';

const useTextFieldClass = makeStyles({
  root: {
    // for textfield placeHolder => !important
    color: `${Colors.ROLLING_STONE_GREY} !important`,
    paddingLeft: '3px !important',
    fontSize: '12px !important',
  },
});

interface CommunicationDetailContentRecipientProps {
  recipients: (CustomMenuItemType | string)[];
  setRecipients: (recipient: (CustomMenuItemType | string)[]) => void;
  editable: boolean;
  recipientType: CommunicationRecipientType;
  autoCompleteOptions?: CustomMenuItemType[];
  communicationChannel: CommunicationChannel;
}

const CommunicationDetailsContentRecipient: React.FC<CommunicationDetailContentRecipientProps> = ({
  recipients,
  setRecipients,
  editable,
  recipientType,
  autoCompleteOptions = [],
  communicationChannel,
}) => {
  const { formatMessage } = useIntl();
  const classes = useCustomizedComboStyles();
  const textFieldClass = useTextFieldClass();

  const filteredRecipients = recipients.filter((recipient) => recipient !== '');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (event: any) => {
    const isSeparatorKeyTyped =
      [';', ','].includes(event.key) ||
      (event.key === ' ' && [CommunicationChannel.SMS, CommunicationChannel.EMAIL].includes(communicationChannel));
    if (isSeparatorKeyTyped) {
      event.preventDefault();
      event.stopPropagation();
      if (event.target.value.length > 0) {
        setRecipients([...recipients, event.target.value]);
      }
    }
  };

  return (
    <Grid>
      <TextDetail
        title={formatMessage({ id: `communications.detail.recipient.${recipientType}` })}
        style={{ paddingBottom: 12 }}
      />

      <Autocomplete
        multiple
        id="tags-filled"
        value={filteredRecipients}
        onChange={(_event, newValue) => setRecipients(newValue)}
        filterSelectedOptions
        autoSelect
        options={autoCompleteOptions}
        disabled={!editable}
        groupBy={(option) => {
          if (typeof option === 'string') return 'custom';
          return option.group as string;
        }}
        freeSolo
        getOptionSelected={(option, value) => {
          const str1 = typeof option === 'string' ? option : option.value;
          const str2 = typeof value === 'string' ? value : value.value;
          return isEqual(str1, str2);
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return option;
          return getOptionLabelFromCustomMenuItemType(option);
        }}
        renderTags={(value: (CustomMenuItemType | string)[], getTagProps) => {
          if (!isEqual(recipients, value)) setRecipients(value);
          return value.map((option: CustomMenuItemType | string, index: number) => (
            <Chip
              key={typeof option === 'string' ? option : option.value}
              variant="outlined"
              disabled={!editable}
              label={typeof option === 'string' ? option : option.value}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderOption={(option) => (
          <Grid container alignContent="space-between" style={{ marginBottom: '5px', marginTop: '5px' }}>
            <Grid item xs={6}>
              <Typography className={classes.primaryText}>
                {typeof option === 'string' ? option : option.primary}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              {typeof option !== 'string' && !isNil(option.secondary) && (
                <Typography className={classes.secondaryText}>{option.secondary}</Typography>
              )}
            </Grid>
          </Grid>
        )}
        renderInput={(params) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (params.inputProps as any).onKeyDown = handleKeyDown;
          return (
            <TextField
              {...params}
              InputProps={{ disableUnderline: true, ...params.InputProps, classes: textFieldClass }}
              label=""
              placeholder={isEmpty(filteredRecipients) ? formatMessage({ id: 'noRecipient' }) : ''}
              fullWidth
            />
          );
        }}
      />
    </Grid>
  );
};

export default CommunicationDetailsContentRecipient;
