import { Button, Grid, ListItemAvatar, Typography, makeStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import {
  COMMUNICATION_TABLE_NAME,
  Colors,
  CommunicationChannel,
  DEFAULT_ACCEPTED_FILE_TYPES,
  MAX_EMAIL_ATTACHMENT_SIZE,
  MAX_NUMBER_OF_FILES,
  NewFile,
  PDF_FILE_TYPE,
  S3Object,
  fileExists,
  getFileMetaData,
} from '@rentguru/commons-utils';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import FileItemRendering from 'src/components/ui/FileItemRendering';
import PreviewFileDialog from 'src/components/ui/PreviewFileDialog';
import DropZoneFieldErrorMessage from '../ui/Forms/FormField/DropZoneFieldErrorMessages';

const useStyle = makeStyles({
  addFileBox: {
    color: Colors.BLUEY,
    fill: Colors.BLUEY,
    width: 50,
    height: 80,
    borderColor: Colors.BLUEY,
    borderWidth: 1.5,
    borderStyle: 'dashed',
    borderRadius: 6,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      borderColor: Colors.ROYAL_BLUE,
      color: Colors.ROYAL_BLUE,
      fill: Colors.ROYAL_BLUE,
      cursor: 'pointer',
    },
  },
});

type CommunicationDetailsFilesProps = {
  files: (S3Object | NewFile)[];
  setFiles: (value: (S3Object | NewFile)[]) => void;
  communicationChannel: CommunicationChannel;
  canAddFile?: boolean;
};

const fileCanBeDeleted = (file: S3Object | NewFile) =>
  !('foreignTableName' in file) || file.foreignTableName === COMMUNICATION_TABLE_NAME;

const CommunicationDetailsFiles: React.FC<CommunicationDetailsFilesProps> = ({
  files,
  setFiles,
  communicationChannel,
  canAddFile = true,
}) => {
  const { formatMessage } = useIntl();
  const [fileToPreview, setFileToPreview] = useState<S3Object | null>(null);
  const classes = useStyle();
  const isLetter = [CommunicationChannel.LETTER, CommunicationChannel.REGISTERED_LETTER].includes(communicationChannel);
  const { getRootProps, isDragReject, fileRejections, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      if (isEmpty(acceptedFiles)) {
        return;
      }
      const uniqueNewFiles = acceptedFiles.reduce((acc, file) => {
        if (fileExists(file, files)) {
          return acc;
        }
        acc.push({ name: file.name, size: file.size, file, mimeType: file.type });
        return acc;
      }, [] as NewFile[]);
      setFiles([...files, ...uniqueNewFiles]);
    },
    accept: isLetter ? [PDF_FILE_TYPE] : DEFAULT_ACCEPTED_FILE_TYPES,
    maxSize: MAX_EMAIL_ATTACHMENT_SIZE,
    maxFiles: MAX_NUMBER_OF_FILES,
    multiple: true,
  });

  const getTotalFileSize = () =>
    files.reduce((acc, file) => {
      const size = 'file' in file ? file.file.size : file.size;
      acc = acc + (size ?? 0);
      return acc;
    }, 0);

  const handleDelete = (fileToDeletedName: string, fileToDeleteSize?: number | null) => {
    setFiles(
      files.filter((file) => {
        const { fileName, fileSize } = getFileMetaData(file);
        return !(fileName === fileToDeletedName && fileSize === fileToDeleteSize);
      })
    );
  };

  return (
    <Grid>
      <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12, marginLeft: 20, marginBottom: 10 }}>
        {formatMessage({ id: `communications.detail.attachedFiles` })}
      </Typography>
      <Grid container>
        {files?.map((file, index) => {
          const { fileName, fileType, fileUrl, fileSize } = getFileMetaData(file);
          return (
            <Button
              key={fileName}
              onClick={() => setFileToPreview({ ...file, url: fileUrl } as unknown as S3Object)}
              style={{
                padding: 0,
                height: 80,
                width: 50,
                marginLeft: index === 0 ? 20 : 0,
              }}
            >
              <ListItemAvatar>
                <FileItemRendering
                  fileType={fileType}
                  fileUrl={fileUrl}
                  fileName={fileName}
                  removeFunction={
                    canAddFile && fileCanBeDeleted(file) ? () => handleDelete(fileName, fileSize) : undefined
                  }
                  height={80}
                  width={50}
                />
              </ListItemAvatar>
            </Button>
          );
        })}
        {canAddFile && files.length < MAX_NUMBER_OF_FILES && (
          <Grid {...getRootProps()} className={classes.addFileBox} style={{ marginLeft: isEmpty(files) ? 20 : 0 }}>
            <input {...getInputProps()} />
            <AddCircle />
          </Grid>
        )}
      </Grid>

      <DropZoneFieldErrorMessage
        fileRejections={fileRejections}
        totalFileSize={getTotalFileSize()}
        maxFileSize={MAX_EMAIL_ATTACHMENT_SIZE}
      />

      {isDragReject && (
        <Typography style={{ color: Colors.RED_ORANGE }}>
          {formatMessage({ id: 'lease.addLease.dragFileUnAuthorized' })}
        </Typography>
      )}

      {!isNil(fileToPreview) && (
        <PreviewFileDialog
          open={!isNil(fileToPreview)}
          file={fileToPreview}
          onClose={() => setFileToPreview(null)}
          withFileName={false}
        />
      )}
    </Grid>
  );
};

export default CommunicationDetailsFiles;
