import { Grid, Typography } from '@material-ui/core';
import { Technic, TechnicType } from '@rentguru/commons-utils';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import CustomInputComboBox from 'src/components/ui/ComboBox/FormikFullCustomComboBox';
import { CustomInputItemType } from 'src/components/ui/ComboBox/FullCustomComboBox';
import { useCustomizedComboStyles } from 'src/components/ui/ComboBox/TextComboBox';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as LogoBuilding } from 'src/icons/buildingPlaceholder.svg';
import { ReactComponent as LogoLease } from 'src/icons/key.svg';
import { ReactComponent as LogoUnit } from 'src/icons/unitPlaceholder.svg';
import { getSafeValueInObject } from 'src/utils/object';

interface SimpleTechnicSelectorProps {
  technics: Technic[];
  defaultValue?: Technic;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  fieldName: string;
  inputWidth?: number;
  paperClass?: string;
  disabled?: boolean;
}

const SimpleTechnicSelector: React.FC<SimpleTechnicSelectorProps> = ({
  technics,
  defaultValue,
  style,
  inputStyle,
  fieldName,
  inputWidth = 560,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const comboBoxClasses = useCustomizedComboStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, errors, touched } = useFormikContext<any>();

  const getStringIdentifierOfTechnic = (technic: Technic) => {
    switch (technic.type) {
      case TechnicType.PEB:
        return format(new Date(technic.PebValidityDate!), 'd/MM/yyyy', {
          locale: (dateLocaleMap as dateLocaleMapType)[language],
        });
      case TechnicType.CHIMNEY:
        return format(new Date(technic.chimneyLastControlDate!), 'd/MM/yyyy', {
          locale: (dateLocaleMap as dateLocaleMapType)[language],
        });
      case TechnicType.DETECTOR:
        return technic.detectorSerialNumber!;
      case TechnicType.FUELTANK:
        return `${formatMessage({ id: `technic.fuelTankTab.${technic.tankType!.toLowerCase()}` })} - ${format(
          new Date(technic.tankLastControlDate!),
          'd/MM/yyyy',
          {
            locale: (dateLocaleMap as dateLocaleMapType)[language],
          }
        )}`;
      case TechnicType.HEATING:
        return format(new Date(technic.heatingLastControlDate!), 'd/MM/yyyy', {
          locale: (dateLocaleMap as dateLocaleMapType)[language],
        });
      case TechnicType.UTILITY_PROVIDER:
        return `${formatMessage({
          id: `enums.UtilityType.${technic.utilityType!}`,
        })} - ${technic.utilityEanCode!}`;
      default:
        return '';
    }
  };
  const technicToCustomComboItem = (technic: Technic) => {
    return {
      primary: formatMessage({ id: `enums.TechnicType.${technic.type}` }),
      secondary: getStringIdentifierOfTechnic(technic),
      value: technic,
    };
  };
  const emptyObject = !isNil(defaultValue)
    ? technicToCustomComboItem(defaultValue)
    : { primary: '', secondary: '', value: null };
  const technicSelected = getSafeValueInObject(values, fieldName);

  return (
    <div
      style={{
        alignItems: 'baseline',
        display: 'flex',
        ...style,
      }}
    >
      <CustomInputComboBox
        name={fieldName}
        value={
          !isNil(technicSelected) && !isEmpty(technicSelected) ? technicToCustomComboItem(technicSelected) : emptyObject
        }
        label={formatMessage({ id: 'dashboard.notifications.technic' })}
        options={technics.map((t) => technicToCustomComboItem(t))}
        fieldToReturn="value"
        emptyValue={emptyObject}
        inputStyle={{ width: inputWidth, ...inputStyle }}
        renderOption={(value: CustomInputItemType) => {
          const technic: Technic = value.value;
          const Logo = !isNil(technic.unit) ? LogoUnit : !isNil(technic.building) ? LogoBuilding : LogoLease;
          return (
            <Grid container alignContent="space-between" style={{ marginBottom: '5px', marginTop: '5px' }}>
              <Grid item xs={2}>
                <Logo
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    fill: 'blue',
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className={comboBoxClasses.primaryText}>{value.primary}</Typography>
              </Grid>
              <Grid item xs={5} style={{ textAlign: 'right' }}>
                {!isNil(value.secondary) && (
                  <Typography className={comboBoxClasses.secondaryText}>{value.secondary}</Typography>
                )}
              </Grid>
            </Grid>
          );
        }}
        renderInput={(value: CustomInputItemType) => {
          const technic: Technic = value.value;
          const Logo = !isNil(technic.unit) ? LogoUnit : !isNil(technic.building) ? LogoBuilding : LogoLease;
          return (
            <>
              <Logo
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 10,
                  fill: 'blue',
                }}
              />
              <Typography
                style={{
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: `calc(${inputWidth}px - 85px)`,
                }}
              >{`${value.primary} - ${value.secondary}`}</Typography>
            </>
          );
        }}
        error={Boolean(getSafeValueInObject(errors, fieldName) && getSafeValueInObject(touched, fieldName))}
        noOptionsText={formatMessage({ id: 'utilityProviders.noUtility' })}
        stringToCompare={(value: CustomInputItemType) => `${value.primary} - ${value.secondary}`}
        {...rest}
      />
    </div>
  );
};

export default SimpleTechnicSelector;
