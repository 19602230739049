/* eslint-disable max-len */
import { DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@material-ui/core';
import { CommunicationSettingsProfile, Lease } from '@rentguru/commons-utils';
import { ActionButton, LoaderButton, useCustomSimpleDialogStyles } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty, isNil, sortBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCommunicationSettingsProfileUtils } from 'src/components/Settings/CommunicationSettings/CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';
import {
  CommunicationSettingsProfileFormValues,
  CommunicationSettingsProfileType,
  defaultLeaseSettings,
  getFormValuesFromProfile,
} from 'src/components/Settings/CommunicationSettings/CommunicationSettingsProfileUtils/LeaseCommunicationSettingsUtils';
import LeaseCommunicationSettingsProfileForm, {
  LeaseCommunicationSettingsProfileSchema,
} from 'src/components/ui/Forms/FormField/LeaseCommunicationSettingsProfileForm';
import {
  getCommunicationSettingsProfile,
  useCommunicationSettingsProfiles,
} from 'src/hooks/CommunicationSettingsProfilesContext';
import { useLeases } from 'src/hooks/LeasesContext';

interface UpdateCommunicationSettingsProfileDialogProps {
  lease: Lease;
  closeDialog: () => void;
}

const UpdateCommunicationSettingsProfileDialog: React.FC<UpdateCommunicationSettingsProfileDialogProps> = ({
  lease,
  closeDialog,
}) => {
  const { formatMessage } = useIntl();
  const classes = useCustomSimpleDialogStyles();
  const { getDefaultLeaseCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();
  const [initialValues, setInitialValues] = useState<CommunicationSettingsProfileFormValues | null>(null);
  const { updateEntityCommunicationSettingsProfile } = useCommunicationSettingsProfileUtils();
  const { enqueueSnackbar } = useSnackbar();
  const { updateLease } = useLeases();

  const [leaseCommunicationSettingsProfiles, setLeaseCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const leaseProfiles = getDefaultLeaseCommunicationSettingsProfiles();

    setLeaseCommunicationSettingsProfiles(sortBy(leaseProfiles, 'defaultName'));
  }, [getDefaultLeaseCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  useEffect(() => {
    if (isNil(initialValues) && !isEmpty(leaseCommunicationSettingsProfiles) && !isNil(lease)) {
      const getValues = async () => {
        const communicationSettingProfile = await getCommunicationSettingsProfile(lease.communicationSettingsProfileId);

        const initialCommunicationSettingsProfileSettings = communicationSettingProfile.isDefaultSetting
          ? defaultLeaseSettings
          : getFormValuesFromProfile(communicationSettingProfile);

        const values = {
          communicationSettingsProfileId: communicationSettingProfile.id,
          communicationSettingsProfileType: communicationSettingProfile.isDefaultSetting
            ? CommunicationSettingsProfileType.DEFAULT
            : CommunicationSettingsProfileType.CUSTOM,
          ...initialCommunicationSettingsProfileSettings,
        };
        setInitialValues(values);
      };

      getValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseCommunicationSettingsProfiles]);

  if (isNil(initialValues)) {
    return (
      <Grid style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </Grid>
    );
  }

  const handleUpdate = async (
    values: CommunicationSettingsProfileFormValues,
    { setSubmitting, setStatus }: FormikHelpers<CommunicationSettingsProfileFormValues>
  ) => {
    setSubmitting(true);
    const newCommunicationSettingsProfileId = await updateEntityCommunicationSettingsProfile(
      values,
      lease.communicationSettingsProfileId
    );
    if (newCommunicationSettingsProfileId !== lease.communicationSettingsProfileId) {
      await updateLease(lease, { communicationSettingsProfileId: newCommunicationSettingsProfileId });
    }
    setStatus(true);
    setSubmitting(false);
    closeDialog();
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
  };

  return (
    <Grid>
      <DialogTitle id="alert-dialog-title" style={{ fontSize: 50 }}>
        <Typography className={classes.title}>{formatMessage({ id: 'settings.communications.settings' })}</Typography>
      </DialogTitle>

      <Divider style={{ marginBottom: 20 }} />

      <Formik
        initialValues={initialValues}
        validationSchema={LeaseCommunicationSettingsProfileSchema}
        onSubmit={handleUpdate}
      >
        {({ isSubmitting, status }) => (
          <Form>
            <DialogContent style={{ padding: 0, height: 600 }}>
              <LeaseCommunicationSettingsProfileForm showFormHeader={false} />
            </DialogContent>
            <Divider />
            <DialogActions className={classes.buttonsGrid}>
              <ActionButton onClick={closeDialog} className={classes.cancelButton}>
                {formatMessage({
                  id: 'cancel',
                })}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default UpdateCommunicationSettingsProfileDialog;
