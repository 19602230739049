import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Colors, CommunicationSettingsProfile, isNilOrEmpty } from '@rentguru/commons-utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import LeaseCommunicationSettingsForm from 'src/components/Settings/CommunicationSettings/CommunicationSettingsProfileForm/LeaseCommunicationSettingsProfileForm';
import { CommunicationSettingsProfileFormValues } from 'src/components/Settings/CommunicationSettings/CommunicationSettingsProfileUtils/LeaseCommunicationSettingsUtils';
import CustomInputComboBox from 'src/components/ui/ComboBox/FormikFullCustomComboBox';
import { CustomInputItemType } from 'src/components/ui/ComboBox/FullCustomComboBox';
import { CustomIconButton, CustomSimpleDialog } from '@up2rent/ui';
import { ReactComponent as EyeSvg } from 'src/icons/eye.svg';

interface CommunicationSettingsProfileSelectorProps {
  values: CommunicationSettingsProfileFormValues;
  profiles: CommunicationSettingsProfile[];
}
const CommunicationSettingsProfileSelector: React.FC<CommunicationSettingsProfileSelectorProps> = ({
  values,
  profiles,
}) => {
  const { formatMessage } = useIntl();
  const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);

  const communicationSettingsProfileToCustomComboItem = (
    communicationSettingsProfile: CommunicationSettingsProfile
  ) => {
    return {
      defaultName: communicationSettingsProfile.defaultName!,
      value: communicationSettingsProfile.id,
    };
  };

  const selected = profiles.find((profile) => profile.id === values.communicationSettingsProfileId);

  return (
    <>
      <Grid style={{ marginLeft: 30, marginRight: 30 }}>
        <CustomInputComboBox
          name="communicationSettingsProfileId"
          value={
            !isNilOrEmpty(selected)
              ? communicationSettingsProfileToCustomComboItem(selected)
              : { defaultName: '', value: null }
          }
          fieldToReturn="value"
          label={formatMessage({ id: 'lease.addLease.communicationProfileLabel' })}
          options={profiles.map((profile) => communicationSettingsProfileToCustomComboItem(profile))}
          inputStyle={{ width: '100%' }}
          renderOption={(value: CustomInputItemType) => (
            <Grid>
              <Typography style={{ marginBottom: 5, marginTop: 5 }}>{value.defaultName}</Typography>
            </Grid>
          )}
          renderInput={(value: CustomInputItemType) => (
            <Grid>
              <Typography style={{ marginBottom: 5, marginTop: 5, fontSize: 16 }}>{value.defaultName}</Typography>
            </Grid>
          )}
          inputAdornment={
            selected && (
              <Grid style={{ marginTop: -10 }}>
                <Tooltip title={formatMessage({ id: 'lease.addLease.communicationProfilePreview' })}>
                  <CustomIconButton
                    onClick={() => setPreviewDialogOpen(true)}
                    iconStyle={{ fill: Colors.BLUEY }}
                    Icon={EyeSvg}
                  />
                </Tooltip>
              </Grid>
            )
          }
          stringToCompare={(value: CustomInputItemType) => value.defaultName}
        />
      </Grid>

      {previewDialogOpen && selected && (
        <CustomSimpleDialog
          onActionButtonClick={() => setPreviewDialogOpen(false)}
          actionButtonLabel={formatMessage({ id: 'close' })}
          open={previewDialogOpen}
          onClose={() => setPreviewDialogOpen(false)}
          title={selected.defaultName!}
          dividerBelowTitle
          formatMessage={formatMessage}
        >
          <Grid style={{ height: 600, paddingBottom: 0, paddingTop: 0 }}>
            <LeaseCommunicationSettingsForm defaultCollapse previewProfile={selected} />
          </Grid>
        </CustomSimpleDialog>
      )}
    </>
  );
};

export default CommunicationSettingsProfileSelector;
