import { Grid } from '@material-ui/core';
import { Colors, Lease } from '@rentguru/commons-utils';
import { ActionButtonSecond } from '@up2rent/ui';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import UpdateCommunicationSettingsProfileDialog from 'src/components/Leases/UpdateCommunicationSettingsProfileDialog';
import Dialog from 'src/components/ui/Dialog';
import { ReactComponent as SettingsIcon } from 'src/icons/cog.svg';

interface CommunicationSettingsButtonProps {
  lease: Lease;
}

export const CommunicationSettingsButton: React.FC<CommunicationSettingsButtonProps> = ({ lease }) => {
  const { formatMessage } = useIntl();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <Grid>
      <ActionButtonSecond
        style={{
          textTransform: 'none',
          marginBottom: 10,
          minWidth: 120,
        }}
        startIcon={<SettingsIcon style={{ fill: Colors.DARK_SLATE_GREY }} />}
        onClick={() => setDialogOpen(true)}
      >
        {formatMessage({ id: 'settings.communications.settings' })}
      </ActionButtonSecond>
      {dialogOpen && (
        <Dialog open={dialogOpen}>
          <UpdateCommunicationSettingsProfileDialog lease={lease} closeDialog={() => setDialogOpen(false)} />
        </Dialog>
      )}
    </Grid>
  );
};
