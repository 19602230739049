import { Divider, Grid, Tab, Tooltip, Typography } from '@material-ui/core';
import { FormikProps, useFormikContext } from 'formik';
import { isEmpty, orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import CommunicationSettingsProfileSelector from './CommunicationSettingsProfileSelector';
import { Colors, CommunicationSettingsProfile, CommunicationSettingsProfileReferent } from '@rentguru/commons-utils';
import FormHeader from 'src/components/ui/FormHeader';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { CustomizedTabs } from '@up2rent/ui';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import LeaseCommunicationSettingsForm from 'src/components/Settings/CommunicationSettings/CommunicationSettingsProfileForm/LeaseCommunicationSettingsProfileForm';
import { getCommunicationSettingsProfileSchema } from 'src/components/Settings/CommunicationSettings/CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';
import {
  CommunicationSettingsProfileFormValues,
  CommunicationSettingsProfileType,
  // eslint-disable-next-line max-len
} from 'src/components/Settings/CommunicationSettings/CommunicationSettingsProfileUtils/LeaseCommunicationSettingsUtils';

export const LeaseCommunicationSettingsProfileSchema = Yup.object()
  .shape({
    communicationSettingsProfileId: Yup.string().required(),
  })
  .concat(getCommunicationSettingsProfileSchema(CommunicationSettingsProfileReferent.LEASE, false))
  .required();

const LeaseCommunicationSettingsProfileForm = ({ showFormHeader = true }) => {
  const { values, setFieldValue }: FormikProps<CommunicationSettingsProfileFormValues> = useFormikContext();
  const { getDefaultLeaseCommunicationSettingsProfiles, loading } = useCommunicationSettingsProfiles();
  const { formatMessage } = useIntl();

  const [leaseCommunicationSettingsProfiles, setLeaseCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const leaseProfiles = getDefaultLeaseCommunicationSettingsProfiles();
    setLeaseCommunicationSettingsProfiles(orderBy(leaseProfiles, 'defaultName'));
  }, [getDefaultLeaseCommunicationSettingsProfiles, loading]);

  if (loading || isEmpty(leaseCommunicationSettingsProfiles)) {
    return (
      <Grid container style={{ justifyContent: 'center' }}>
        <Typography style={{ margin: 20 }}>{formatMessage({ id: 'loading' })}</Typography>
      </Grid>
    );
  }

  const selectedProfileIsDefault = leaseCommunicationSettingsProfiles.some(
    (profile) => profile.id === values.communicationSettingsProfileId && profile.isDefaultSetting
  );

  return (
    <Grid>
      {showFormHeader && (
        <Grid>
          <FormHeader
            title={formatMessage({
              id: `lease.detail.general.communications`,
            })}
          >
            <Tooltip
              title={formatMessage({
                id: `lease.addLease.communicationsInfo`,
              })}
              placement="top"
            >
              <Grid>
                <InfoSvg style={{ fill: Colors.SILVER }} />
              </Grid>
            </Tooltip>
          </FormHeader>
          <Divider style={{ marginBottom: 10 }} />
        </Grid>
      )}

      <CustomizedTabs
        value={values.communicationSettingsProfileType}
        handleChange={(value: CommunicationSettingsProfileType) => {
          setFieldValue('communicationSettingsProfileType', value);
          if (!selectedProfileIsDefault) {
            setFieldValue('communicationSettingsProfileId', leaseCommunicationSettingsProfiles[0].id);
          }
        }}
      >
        <Tab
          value={CommunicationSettingsProfileType.DEFAULT}
          label={formatMessage({ id: `lease.addLease.communicationsDefault` })}
        />
        <Tab
          value={CommunicationSettingsProfileType.CUSTOM}
          label={formatMessage({ id: `lease.addLease.communicationsCustom` })}
        />
      </CustomizedTabs>
      <Divider style={{ marginBottom: 20 }} />
      {values.communicationSettingsProfileType === CommunicationSettingsProfileType.DEFAULT && (
        <>
          <IconnedRemark
            Icon={InfoSvg}
            noFixWidth
            style={{ marginLeft: 30, marginRight: 30 }}
            message={
              <Typography style={{ textAlign: 'left' }}>
                {formatMessage({ id: `lease.addLease.communicationsDefaultInfo` })}
              </Typography>
            }
          />
          <CommunicationSettingsProfileSelector values={values} profiles={leaseCommunicationSettingsProfiles} />
        </>
      )}
      {values.communicationSettingsProfileType === CommunicationSettingsProfileType.CUSTOM && (
        <>
          <IconnedRemark
            Icon={InfoSvg}
            noFixWidth
            style={{ marginLeft: 30, marginRight: 30 }}
            message={
              <Typography style={{ textAlign: 'left' }}>
                {formatMessage({ id: `lease.addLease.communicationsCustomInfo` })}
              </Typography>
            }
          />
          <Grid style={{ marginLeft: 30, marginRight: 30 }}>
            <LeaseCommunicationSettingsForm defaultCollapse />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LeaseCommunicationSettingsProfileForm;
