import { FormControl, FormControlLabel, Grid, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import { AgencyRateFeesType, AgencyRateIncludedAmount, Colors } from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import StyledRadio from 'src/components/ui/StyledRadio';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { AddEditAgencyRateValues } from '../AddEditAgencyRateForm';

const AgencyRateFeesAppliedToField = () => {
  const { values, setValues }: FormikProps<AddEditAgencyRateValues> = useFormikContext();
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 'bold',
          fontStyle: 'normal',
          letterSpacing: 0,
          color: Colors.CLASSICAL_BLACK,
        }}
      >
        {formatMessage({ id: 'agencyRates.detail.feesAppliedTo' })}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          value={values.feesAppliedTo}
          onChange={(_e, value) => {
            const newValues = { ...values };
            if (
              value === AgencyRateFeesType.AMOUNT_COLLECTED &&
              values.includedInAmount &&
              !values.includedInAmount.includes(AgencyRateIncludedAmount.RENTAL_DISCOUNT)
            ) {
              (newValues.includedInAmount as AgencyRateIncludedAmount[]).push(AgencyRateIncludedAmount.RENTAL_DISCOUNT);
            }
            if (
              value === AgencyRateFeesType.AMOUNT_COLLECTED &&
              values.includedInAmount &&
              values.includedInAmount.includes(AgencyRateIncludedAmount.RENT_WITHOUT_CHARGES)
            ) {
              (newValues.includedInAmount as AgencyRateIncludedAmount[]).push(AgencyRateIncludedAmount.INDEXED_PRICE);
              newValues.includedInAmount = (newValues.includedInAmount as AgencyRateIncludedAmount[]).filter(
                (includedAmount) => includedAmount !== AgencyRateIncludedAmount.RENT_WITHOUT_CHARGES
              );
            }
            newValues.feesAppliedTo = value as AgencyRateFeesType;
            setValues(newValues);
          }}
        >
          {Object.keys(AgencyRateFeesType).map((element) => (
            <FormControlLabel
              value={element}
              key={element}
              control={<StyledRadio />}
              label={
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'start' }}>
                  <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                    {formatMessage({ id: `agencyRates.detail.${element}` })}
                  </Typography>
                  <Tooltip
                    title={formatMessage({
                      id: `agencyRates.detail.${
                        element === AgencyRateFeesType.AMOUNT_COLLECTED
                          ? 'feesAppliedToAmountsCollectedInfo'
                          : 'feesAppliedToExpectedRentInfo'
                      }`,
                    })}
                    placement="bottom"
                  >
                    <Grid>
                      <InfoSvg style={{ fill: Colors.SILVER, paddingLeft: 10 }} />
                    </Grid>
                  </Tooltip>
                </div>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default AgencyRateFeesAppliedToField;
