/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable func-names */
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { IntlFormatters, MessageDescriptor, useIntl } from 'react-intl';
import { CountryCode } from 'src/components/ui/CountrySelector';
import * as Yup from 'yup';
// eslint-disable-next-line import/no-named-as-default
import { checkVAT, countries } from '@accountable/jsvat';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  Address,
  CivilityType,
  Colors,
  CommunicationSettingsProfile,
  Contact,
  CONTACT_TYPES_EMAIL_NOT_MANDATORY,
  ContactType,
  getCountryCodeFromDialCode,
  getDialCodeCodeFromCountryCode,
  handleDisposableEmail,
  isNilOrEmpty,
  isOneTypeOverlapping,
  PhoneNumber as PhoneNumberModel,
  sanitizeEmailAddress,
} from '@rentguru/commons-utils';
import { ActionButton, LoaderButton } from '@up2rent/ui';
// eslint-disable-next-line import/no-named-as-default
import parsePhoneNumberFromString, { parsePhoneNumber, CountryCode as PhoneCountryCode } from 'libphonenumber-js';
import toUpper from 'lodash/toUpper';
import { useSnackbar } from 'notistack';
import { AddressFormValues } from 'src/components/ui/Forms/FormField/AddressFields';
import { PhoneNumbersFieldsFormValues } from 'src/components/ui/Forms/FormField/PhoneNumbersFields';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useUser } from 'src/hooks/UserContext';
import { useUsers } from 'src/hooks/UsersContext';
import { EMAIL_REGEX } from 'src/utils/communicationUtils';
import AddContactForm from './AddContactForm';

const NOT_VALID_VAT = 'VAT is not valid';
const INVALID_PHONE_NUMBER_FORMAT = 'The format of this phone number is not valid';

const useStyles = makeStyles({
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
});

export async function verifyDisposableEmail(
  email: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string,
  createError: (params?: Yup.CreateErrorOptions | undefined) => Yup.ValidationError
) {
  if (!email.endsWith('gmail.com') && !email.endsWith('hotmail.com') && !email.endsWith('yahoo.com')) {
    const result = await handleDisposableEmail(email);

    if (result?.disposable === true) {
      return createError({ path: 'email', message: formatMessage({ id: 'signup.disposableEmail' }) });
    }
    if (result && !result.dns) {
      return createError({ path: 'email', message: formatMessage({ id: 'signup.noDnsEmail' }) });
    }
  }
  return true;
}

export const OptionnalAddressSchema = Yup.object()
  .shape({
    address: Yup.object()
      .shape({
        country: Yup.string().nullable(),
        postalCode: Yup.string().nullable(),
        street: Yup.string().nullable(),
        number: Yup.string().nullable(),
        box: Yup.string().nullable(),
        city: Yup.string().nullable(),
      })
      .test('Empty address', 'Address should be 100% empty or correctly filled', function (value) {
        if (isNil(value)) return true;
        const { country, postalCode, street, number, box, city } = value;
        const atLeastOneValueFilled =
          (!isNil(postalCode) && !isEmpty(postalCode)) ||
          (!isNil(street) && !isEmpty(street)) ||
          (!isNil(number) && !isEmpty(number)) ||
          (!isNil(box) && !isEmpty(box)) ||
          (!isNil(city) && !isEmpty(city));
        // One value has been filled we must do a standard check on address
        if (atLeastOneValueFilled) {
          if (isNil(country) || country.length !== 2) return this.createError({ path: 'address.country' });
          if (isNil(postalCode) || !(postalCode.length >= 2 && postalCode.length <= 6))
            return this.createError({ path: 'address.postalCode' });
          if (isNil(street) || !(street.length >= 2 && street.length <= 150))
            return this.createError({ path: 'address.street' });
          if (isNil(number) || !(number.length >= 1 && number.length <= 10))
            return this.createError({ path: 'address.number' });
          if (isNil(city) || !(city.length >= 2 && city.length <= 150))
            return this.createError({ path: 'address.city' });
        }
        return true;
      }),
  })
  .required();

export const PhoneNumberSchema = Yup.object()
  .shape({
    phoneNumbers: Yup.array().of(
      Yup.object()
        .shape({
          countryCode: Yup.string().min(1).required(),
          number: Yup.string().test('validPhoneNumber', INVALID_PHONE_NUMBER_FORMAT, function (value) {
            if (isEmpty(value)) return true;
            const { countryCode } = this.parent;
            const parsedPhoneNumber = parsePhoneNumberFromString(
              `${value}`,
              getCountryCodeFromDialCode(countryCode) as PhoneCountryCode
            );
            return !isNil(parsedPhoneNumber) && parsedPhoneNumber.isValid();
          }),
        })
        .required()
    ),
  })
  .required();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAddContactSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  Yup.object()
    .shape({
      firstName: Yup.string().max(50).required(),
      lastName: Yup.string().max(50).required(),
      email: Yup.string()
        .email()
        .nullable()
        .matches(EMAIL_REGEX, formatMessage({ id: 'settings.sendingSources.invalidEmail' }))
        .test('Checking email validity', 'disposable email error', async function (value) {
          if (isOneTypeOverlapping(this.parent.types, CONTACT_TYPES_EMAIL_NOT_MANDATORY) && isNil(value)) return true;
          if (isNil(value)) return false;
          return await verifyDisposableEmail(value, formatMessage, this.createError);
        })
        .test(
          'Checking email present if the sendInvitation is checked',
          formatMessage({ id: 'errors.emailRequiredForInvitation' }),
          function (value) {
            return !this.parent.sendInvitation || !isNil(value);
          }
        ),
      types: Yup.array().of(Yup.string().required()).required(),
    })
    .required();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAddCompanySchema = (formatMessage: IntlFormatters['formatMessage']) =>
  Yup.object()
    .shape({
      companyName: Yup.string().max(50).required(),
      commercialName: Yup.string().max(50).nullable(),
      email: Yup.string()
        .email()
        .nullable()
        .matches(EMAIL_REGEX, formatMessage({ id: 'settings.sendingSources.invalidEmail' }))
        .test('Checking email validity', 'disposable email error', async function (value) {
          if (isOneTypeOverlapping(this.parent.types, CONTACT_TYPES_EMAIL_NOT_MANDATORY) && isNil(value)) return true;
          if (isNil(value) || isEmpty(value)) return false;
          return await verifyDisposableEmail(value!, formatMessage, this.createError);
        })
        .test(
          'Checking email present if the sendInvitation is checked',
          formatMessage({ id: 'errors.emailRequiredForInvitation' }),
          function (value) {
            return !this.parent.sendInvitation || !isNil(value);
          }
        ),
      businessNumber: Yup.string()
        .min(4)
        .test(
          'Checking business number is present if vatLiable is checked',
          formatMessage({ id: 'errors.businessNumberRequired' }),
          function (value) {
            return !this.parent.vatLiable || !isNilOrEmpty(value);
          }
        ),
      vatLiable: Yup.boolean().required(),
      vat: Yup.string()
        .nullable()
        .test('validVat', NOT_VALID_VAT, function (value) {
          // nil is only ok when business number
          if (isNil(value) || isEmpty(value)) return !this.parent.vatLiable;

          const vatResult = checkVAT(value, countries);
          return vatResult.isValid;
        }),
      types: Yup.array().of(Yup.string().required()).required(),
    })
    .required();

export type AddContactValues = PhoneNumbersFieldsFormValues &
  AddressFormValues & {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    businessNumber?: string;
    vat?: string;
    email: string;
    birthDate: string | null;
    language?: string;
    birthPlace?: string;
    nationalRegister?: string;
    civility?: CivilityType;
    types: ContactType[];
    vatLiable: boolean;
    sendInvitation: boolean;
  };

interface AddContactProps {
  afterSubmit: (contact: Contact) => void;
  type?: ContactType | ContactType[] | null;
  proposedAddress?: Address;
  isContactTypeEditable?: boolean;
}

const AddContact: React.FC<AddContactProps> = ({ afterSubmit, type, proposedAddress, isContactTypeEditable }) => {
  const [view, setView] = useState<string>('person');
  const [ownerCommunicationSettingsProfiles, setOwnerCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { createContact, inviteContactToClientAccount } = useContacts();
  const { enqueueSnackbar } = useSnackbar();

  const { countryCode, countryDialCode, isEmailAlreadyTaken, language } = useUser();
  const { getDefaultOwnerCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();
  const { getClientContact } = useUsers();

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const ownerProfiles = getDefaultOwnerCommunicationSettingsProfiles();
    setOwnerCommunicationSettingsProfiles(ownerProfiles);
  }, [getDefaultOwnerCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  if (communicationSettingsProfilesLoading || isEmpty(ownerCommunicationSettingsProfiles)) {
    return (
      <Grid container style={{ justifyContent: 'center' }}>
        <Typography style={{ margin: 20 }}>{formatMessage({ id: 'loading' })}</Typography>
      </Grid>
    );
  }

  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
  const defaultOwnerProfile = ownerCommunicationSettingsProfiles?.[0];

  const handleTabChange = (index: number) => {
    setView(index === 0 ? 'person' : 'company');
  };

  const handleCreate = async (
    values: AddContactValues,
    { setSubmitting, setStatus, setErrors }: FormikHelpers<AddContactValues>
  ) => {
    const {
      phoneNumbers,
      birthDate,
      companyName,
      vat,
      businessNumber,
      firstName,
      lastName,
      birthPlace,
      nationalRegister,
      civility,
      vatLiable: _vatLiable,
      email,
      ...remainingValues
    } = values;

    const selectedTypes = values.types;

    let emailLowerCase = email;
    if (isOneTypeOverlapping(selectedTypes, CONTACT_TYPES_EMAIL_NOT_MANDATORY) || (!isNil(email) && !isEmpty(email))) {
      emailLowerCase = sanitizeEmailAddress(email);
      // Check first that nobody in up2rent has this email
      if (emailLowerCase && emailLowerCase !== '') {
        const res = await isEmailAlreadyTaken(emailLowerCase);
        if (res.success === false) {
          setErrors({ email: formatMessage({ id: 'error.UsernameExistsException' }) });
          setSubmitting(false);
          return;
        }
      }
    }

    const parsedPhoneNumbers: PhoneNumberModel[] = [];
    phoneNumbers.forEach((phoneNumber) => {
      if (!isEmpty(phoneNumber.number)) {
        try {
          const parsedPhoneNumber = parsePhoneNumber(
            phoneNumber.number,
            getCountryCodeFromDialCode(phoneNumber.countryCode) as PhoneCountryCode
          );
          parsedPhoneNumbers.push({
            countryCode: phoneNumber.countryCode,
            number: parsedPhoneNumber.nationalNumber as string,
          });
        } catch (err) {
          // Don't display the parsed error.
        }
      }
    });
    const contact = await createContact({
      ...remainingValues,
      // Specific values if company is selected
      ...(!viewPerson && { companyName }),
      ...(!viewPerson && { vat }),
      ...(!viewPerson && { businessNumber }),
      // Specific values if contact is a person
      ...(viewPerson && { firstName }),
      ...(viewPerson && { lastName }),
      ...(viewPerson && !isNil(birthDate) && { birthDate }),
      ...(viewPerson && { birthPlace }),
      ...(viewPerson && { nationalRegister }),
      ...(viewPerson && { civility }),
      // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
      ...((selectedTypes.includes(ContactType.OWNER) || selectedTypes.includes(ContactType.JOINT_OWNERSHIP)) && {
        communicationSettingsProfileId: defaultOwnerProfile?.id ?? 'mockId',
      }),
      ...(selectedTypes.includes(ContactType.OWNER) && { language: values.language }),
      email: emailLowerCase && emailLowerCase !== '' ? emailLowerCase : undefined,
      phoneNumbers: parsedPhoneNumbers,
    });
    if (remainingValues.sendInvitation) {
      const clientContact = getClientContact();
      if (clientContact) {
        // No await as it can be slow with lambda cold start
        inviteContactToClientAccount(contact, clientContact);
        enqueueSnackbar(formatMessage({ id: 'settings.addMember.sent' }), {
          variant: 'success',
        });
      }
    }

    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit(contact);
  };

  const handleAfterSubmit = (contact: Contact) => {
    if (!isNil(afterSubmit)) {
      afterSubmit(contact);
    }
  };

  // Cannot create them here
  const excludedCreationTypes = [ContactType.MEMBER, ContactType.JOINT_OWNERSHIP, ContactType.CLIENT];

  const defaultType = isNil(type) ? ContactType.TENANT : type;
  const viewPerson = view === 'person';
  const proposedTypes: ContactType[] = (
    !isNil(type) && !isContactTypeEditable ? (Array.isArray(type) ? type : [type]) : Object.values(ContactType)
  ).filter((type) => !excludedCreationTypes.includes(type));

  const initialTypes = Array.isArray(defaultType)
    ? defaultType.filter((type) => !excludedCreationTypes.includes(type))
    : [defaultType];

  const initialValues: AddContactValues = {
    firstName: '',
    lastName: '',
    companyName: '',
    businessNumber: '',
    vat: '',
    vatLiable: false,
    email: '',
    birthDate: null,
    birthPlace: '',
    nationalRegister: '',
    address: {
      country: (proposedAddress?.country ?? countryCode) as CountryCode,
      postalCode: proposedAddress?.postalCode ?? '',
      street: proposedAddress?.street ?? '',
      number: proposedAddress?.number ?? '',
      box: proposedAddress?.box ?? '',
      city: proposedAddress?.city ?? '',
    },
    language: language?.toLowerCase() ?? 'en',
    types: initialTypes,
    phoneNumbers: [
      {
        countryCode: countryDialCode || '',
        number: '',
      },
    ],
    sendInvitation: !initialTypes.includes(ContactType.CONTRACTOR),
  };
  const validationSchema = viewPerson
    ? getAddContactSchema(formatMessage).concat(OptionnalAddressSchema).concat(PhoneNumberSchema)
    : getAddCompanySchema(formatMessage).concat(OptionnalAddressSchema).concat(PhoneNumberSchema);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleCreate} validateOnChange>
      {({ values, status, isSubmitting, setFieldValue }) => {
        const increaseNumbers = () => {
          const dialNumber = getDialCodeCodeFromCountryCode(values.address.country);
          const emptyPhones = {
            countryCode: dialNumber || '',
            number: '',
          };
          setFieldValue('phoneNumbers', [...values.phoneNumbers, emptyPhones]);
        };
        return (
          <Form>
            <AddContactForm
              viewPerson={viewPerson}
              handleTabChange={handleTabChange}
              proposedTypes={proposedTypes}
              showSendInvite={!values.types.includes(ContactType.CONTRACTOR)}
            />
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginBottom: 20,
                marginRight: 30,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={increaseNumbers}
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                }}
                className={classes.addButton}
                id="addPhoneNumber"
              >
                <Add />
                {toUpper(
                  formatMessage({
                    id: 'contact.addContact.addNumberLabel',
                  })
                )}
              </ActionButton>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ActionButton
                  onClick={handleAfterSubmit}
                  style={{
                    background: 'none',
                    color: Colors.DARK_SLATE_GREY,
                    marginRight: 20,
                  }}
                >
                  {toUpper(
                    formatMessage({
                      id: 'contact.addContact.cancelLabel',
                    })
                  )}
                </ActionButton>

                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({
                    id: 'contact.addContact.submitLabel',
                  })}
                </LoaderButton>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddContact;
