import { Divider, Grid, Typography } from '@material-ui/core';
import {
  Colors,
  LeasePaymentFrequency,
  UnitType,
  formatNumber,
  formatNumberToEuroCurrency,
  formatStandardizedUTCDate,
  getContactNameFromObject,
  getLocaleFromLanguage,
  getPriceForPaymentFrequency,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import { format, isBefore, isToday } from 'date-fns';
import { useFormikContext } from 'formik';
import { isNil, toLower, toUpper } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { getLeasePricesValues } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import TextDetailEditable from 'src/components/ui/TextDetailEditable';
import { useContacts } from 'src/hooks/ContactsContext';
import { FileCategoryTypeForm } from 'src/hooks/TechnicsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as ArrowRight } from 'src/icons/arrow-right.svg';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import * as Yup from 'yup';
import DropZoneField from './DropZoneField';
import { getDateValue } from './KeyDatesFields';
import SummaryContactLine from './SummaryContactLine';
import { getUnitIcon } from './UnitSelectorWithLogoField';

export interface SummaryFormValues {
  leaseName: string;
  openBalance: number;
  contract: FileCategoryTypeForm;
  originalContract: FileCategoryTypeForm;
  contractAmendment: FileCategoryTypeForm;
  originalContractAmendment: FileCategoryTypeForm;
}

export const LeaseNameField = Yup.string().min(1).required();

export const SummaryFormFieldsValidationSchema = Yup.object().shape({
  leaseName: LeaseNameField,
  openBalance: Yup.number().required(),
});

const SummaryFormFields = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext<AddLeaseFormValues>();
  const { formatMessage } = useIntl();
  const { getUnit } = useUnits();
  const { language } = useLocale();
  const { getContact } = useContacts();

  useEffect(() => {
    const leaseName = values.tenants
      .map((tenant) => {
        const contact = getContact(tenant.id);
        return getContactNameFromObject(contact);
      })
      .join(`, `);
    if (!values.id) {
      setFieldValue('leaseName', leaseName);
    }
    // eslint-disable-next-line
  }, [values.tenants]);

  const leaseLanguage = values.language as string;
  const { rentalPrice, monthlyCharges } = getLeasePricesValues(values);
  const paymentFrequency = values.paymentDueDateFields.paymentFrequency as LeasePaymentFrequency;
  const rentalPriceForFrequency = getPriceForPaymentFrequency(paymentFrequency, rentalPrice);
  const chargesPriceForFrequency = getPriceForPaymentFrequency(paymentFrequency, monthlyCharges);

  const allUnits = [getUnit(values.unit.id!), ...values.subUnits.map((subUnit) => getUnit(subUnit.id!))];
  // eslint-disable-next-line no-undef
  const locale: Locale = getLocaleFromLanguage(language);

  const totalOpenBalance = values.history.reduce(
    (acc, currentHistoryOperation) => acc + currentHistoryOperation.amount,
    0
  );

  const onMainDrop = useCallback(
    (acceptedFiles: File[]) => {
      // do nothing if no files
      if (isNilOrEmpty(acceptedFiles)) {
        return;
      }

      const filesToAdd = acceptedFiles.map((acceptedFile: File) => {
        return {
          mimeType: acceptedFile.type,
          name: acceptedFile.name,
          size: acceptedFile.size,
          file: acceptedFile,
        };
      });
      // on drop we add to the existing files
      setFieldValue(`contract.files`, [...filesToAdd, ...values.contract.files]);
    },
    [values.contract, setFieldValue]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // do nothing if no files
      if (isNilOrEmpty(acceptedFiles)) {
        return;
      }

      const filesToAdd = acceptedFiles.map((acceptedFile: File) => {
        return {
          mimeType: acceptedFile.type,
          name: acceptedFile.name,
          size: acceptedFile.size,
          file: acceptedFile,
        };
      });
      // on drop we add to the existing files
      setFieldValue(`contractAmendment.files`, [...values.contractAmendment.files, ...filesToAdd]);
    },
    // eslint-disable-next-line
    [values.contractAmendment, setFieldValue]
  );

  const currentValues = values.contract;
  const currentSubValues = values.contractAmendment;

  const startDateValue = getDateValue(values, 'startDate', values.unit.id!);
  const endDateValue = getDateValue(values, 'endDate', values.unit.id!);

  return (
    <>
      <TextDetailEditable
        title={formatMessage({ id: 'lease.addLease.leaseName' })}
        editMode={true}
        name="leaseName"
        type="text"
        style={{ width: 580, maxWidth: 'none' }}
        error={Boolean(errors.leaseName && touched.leaseName)}
      />
      <Divider style={{ marginTop: 30, marginBottom: 30 }} />
      <Typography style={{ textAlign: 'left', marginLeft: 30, fontSize: 14, fontWeight: 'bold' }}>
        {formatMessage({ id: 'lease.addLease.RentCharges' })}
      </Typography>
      <Grid container style={{ display: 'flex', paddingLeft: 30, marginTop: 15, marginBottom: 15 }}>
        <Grid item xs={6}>
          <TextDetailEditable
            style={{ textAlign: 'left' }}
            titleStyle={{ textAlign: 'left' }}
            title={`${formatMessage({ id: 'lease.addLease.totalRent' })} / 
              ${formatMessage({ id: `financial.perPaymentFrequency.${paymentFrequency}` })}`}
            name="totalRent"
            text={formatNumberToEuroCurrency(rentalPriceForFrequency, leaseLanguage)}
            editMode={false}
            dataTest="TotalRent"
          />
        </Grid>
        <Grid item xs={6}>
          <TextDetailEditable
            style={{ textAlign: 'left' }}
            titleStyle={{ textAlign: 'left' }}
            title={`${formatMessage({ id: 'lease.addLease.totalCharges' })} / 
              ${formatMessage({ id: `financial.perPaymentFrequency.${paymentFrequency}` })}`}
            name="totalCharges"
            text={formatNumberToEuroCurrency(chargesPriceForFrequency, leaseLanguage)}
            editMode={false}
            dataTest="TotalCharges"
          />
        </Grid>
      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
        <IconnedRemark
          Icon={InfoSvg}
          style={{ margin: 0 }}
          message={
            <Typography
              data-test="VATInfo"
              style={{
                fontSize: 12,
                color: Colors.SLATE_GREY,
              }}
            >
              {formatMessage({ id: `lease.addLease.${values.applyVat ? 'vatActiveAlert' : 'vatNotActiveAlert'}` })}
            </Typography>
          }
        />
      </Grid>
      <Divider />
      <Typography style={{ textAlign: 'left', marginLeft: 30, fontSize: 14, fontWeight: 'bold', marginTop: 30 }}>
        {formatMessage({ id: 'rentalUnit.header.title' })}
      </Typography>
      <Grid style={{ marginTop: 15, marginBottom: 30 }}>
        {allUnits.reduce((acc, unit, index) => {
          if (!isNil(unit)) {
            const startDateSubUnit = getDateValue(values, 'startDate', unit.id);
            const endDateSubUnit = getDateValue(values, 'endDate', unit.id);

            acc.push(
              <Grid
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{
                  width: 580,
                  marginLeft: 30,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Grid>
                  <Typography style={{ fontSize: 14, textAlign: 'left' }}>{unit.name}</Typography>
                  {!values.allDatesAreSame && (
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY, marginRight: 5 }}>
                        {format(new Date(startDateSubUnit ?? ''), 'dd/MM/yyyy', { locale })}
                      </Typography>
                      <ArrowRight fill={Colors.LIGHT_BLUE_GREY} width={10} height={10} />
                      <Typography style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY, marginLeft: 5 }}>
                        {format(new Date(endDateSubUnit ?? ''), 'dd/MM/yyyy', { locale })}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography style={{ fontSize: 14, color: Colors.SLATE_GREY, marginRight: 10 }}>
                    {formatMessage({
                      id: index === 0 ? `enums.LeaseType.${values.type}` : `enums.UnitType.${toUpper(unit.type)}`,
                    })}
                  </Typography>
                  <Grid>{getUnitIcon(unit.type as UnitType, { width: 24, height: 24 })}</Grid>
                </Grid>
              </Grid>
            );
          }
          return acc;
          // eslint-disable-next-line no-undef
        }, [] as JSX.Element[])}
      </Grid>
      {values.allDatesAreSame && (
        <>
          <Divider />
          <Typography style={{ textAlign: 'left', marginLeft: 30, fontSize: 14, fontWeight: 'bold', marginTop: 30 }}>
            {formatMessage({ id: 'communications.list.date' })}
          </Typography>
          <Grid container style={{ paddingLeft: 30, marginTop: 15, marginBottom: 15 }}>
            <Grid item xs={6} style={{ width: '50%' }}>
              {values.startDate && (
                <TextDetailEditable
                  style={{ textAlign: 'left' }}
                  title={formatMessage({
                    id: 'rentalUnit.editUnit.advertisement.general.startDate',
                  })}
                  titleStyle={{ textAlign: 'left' }}
                  name="totalRent"
                  text={format(new Date(startDateValue ?? ''), 'dd/MM/yyyy', { locale })}
                  editMode={false}
                  dataTest="StartDate"
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {values.endDate && (
                <TextDetailEditable
                  style={{ textAlign: 'left' }}
                  title={formatMessage({
                    id: 'rentalUnit.editUnit.advertisement.general.endDate',
                  })}
                  titleStyle={{ textAlign: 'left' }}
                  name="totalCharges"
                  text={formatStandardizedUTCDate(new Date(endDateValue ?? ''), 'dd/MM/yyyy', { locale })}
                  editMode={false}
                  dataTest="EndDate"
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
      <Divider />
      <Typography style={{ textAlign: 'left', marginLeft: 30, fontSize: 14, fontWeight: 'bold', marginTop: 30 }}>
        {formatMessage({ id: 'lease.addLease.stepLessorsTenantsGuarantors' })}
      </Typography>
      <Grid container style={{ marginTop: 15, marginBottom: 30, paddingLeft: 30 }}>
        <Grid item xs={isNilOrEmpty(values.guarantors) ? 6 : 12}>
          <Typography style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY, textAlign: 'left', marginBottom: 15 }}>
            {formatMessage({ id: 'lease.addLease.lessorTitle' }, { value: values.lessors.length })}
          </Typography>
          <SummaryContactLine arrayOfContacts={values.lessors} />
        </Grid>
        <Grid item xs={6}>
          <Typography style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY, textAlign: 'left', marginBottom: 15 }}>
            {formatMessage({ id: 'lease.addLease.tenantTitle' }, { value: values.tenants.length })}
          </Typography>
          <SummaryContactLine arrayOfContacts={values.tenants} />
        </Grid>
        {!isNilOrEmpty(values.guarantors) && (
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12, color: Colors.LIGHT_BLUE_GREY, textAlign: 'left', marginBottom: 15 }}>
              {formatMessage({ id: 'lease.addLease.GuarantorTitle' }, { value: values.guarantors.length })}
            </Typography>
            <SummaryContactLine arrayOfContacts={values.guarantors} />
          </Grid>
        )}
      </Grid>
      {isBefore(new Date(values.startDate), new Date()) && !isToday(new Date(values.startDate)) && (
        <>
          <Divider />
          <Grid
            style={{
              paddingLeft: 30,
              marginTop: 30,
              marginBottom: 30,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: 30,
            }}
          >
            <Typography style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold' }}>
              {formatMessage({ id: 'financial.openBalance' })}
            </Typography>
            <Grid
              style={{
                minWidth: 60,
                minHeight: 30,
                borderRadius: 5,
                border: `1px solid ${totalOpenBalance === 0 ? Colors.TURQUOISE_BLUE : Colors.CARNATION_RED}`,
              }}
            >
              <Typography
                data-test="openBalanceAmount"
                style={{
                  margin: '10px 20px',
                  color: totalOpenBalance === 0 ? Colors.TURQUOISE_BLUE : Colors.CARNATION_RED,
                }}
              >
                {formatNumber(totalOpenBalance, toLower(values.language), { style: 'currency', currency: 'EUR' })}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Divider />
      <Grid
        style={{
          paddingLeft: 30,
          marginTop: 30,
          marginBottom: 15,
          paddingRight: 30,
        }}
      >
        <Typography style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold' }}>
          {formatMessage({ id: 'enums.FileCategory.CONTRACT' })}
        </Typography>
        <DropZoneField
          style={{ marginTop: 30 }}
          onDrop={onMainDrop}
          currentFiles={!isNil(currentValues.files[0]) ? [currentValues.files[0]] : []}
          maxFiles={1}
          centerSelectedFile
          removeFileByIndex={(index) =>
            setFieldValue(
              `contract.files`,
              values.contract.files.filter((_file, i) => i !== index)
            )
          }
          displayingPictures
          dataTest="Contract"
          acceptedMimeTypes={['application/pdf']}
        />
      </Grid>
      <Divider />
      <Grid
        style={{
          paddingLeft: 30,
          marginTop: 30,
          marginBottom: 15,
          paddingRight: 30,
        }}
      >
        <Typography style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold', marginBottom: 15 }}>
          {formatMessage({ id: 'lease.addLease.documentsToSign' })}
        </Typography>
        <DropZoneField
          onDrop={onDrop}
          currentFiles={!isNil(currentSubValues.files[0]) ? currentSubValues.files : []}
          maxFiles={Infinity}
          centerSelectedFile
          removeFileByIndex={(index) => {
            setFieldValue(
              `contractAmendment.files`,
              values.contractAmendment.files.filter((_file, i) => i !== index)
            );
          }}
          displayingPictures
          imagesOnTop
          acceptedMimeTypes={['application/pdf']}
        />
      </Grid>
    </>
  );
};

export default SummaryFormFields;
