import { CommunicationDTO, getTempFileKeyForCommunicationPdf, ONE_DAY_IN_SECONDS } from '@rentguru/commons-utils';
import { getProperties, getUrl } from 'aws-amplify/storage';
import { format, isAfter } from 'date-fns';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useFiles } from 'src/hooks/FilesContext';

export function useGenerateAndDownloadCommunication(communicationId: string) {
  const { getCommunication } = useCommunications();
  const { generateCommunicationPdf } = useFiles();
  const [networkLoading, setNetworkLoading] = useState<boolean>(false);
  const [communicationPdf, setCommunicationPdf] = useState<string | null>(null);

  useEffect(() => {
    if (!isNil(communicationPdf)) {
      const a = document.createElement('a');
      a.href = communicationPdf;
      a.download = `${format(new Date(), 'yyyy-MM-dd')}.pdf`;
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(communicationPdf);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();
      setNetworkLoading(false);
    }
  }, [communicationPdf]);

  const generateAndGetS3Object = async () => {
    const { key: fileKey } = await generateCommunicationPdf({ id: communicationId });
    if (!fileKey) {
      setNetworkLoading(false);
      return null;
    }
    const s3Object = await getUrl({
      key: fileKey,
      options: {
        expiresIn: ONE_DAY_IN_SECONDS,
      },
    });

    return s3Object.url.toString();
  };

  const generateDownloadCommunication = async () => {
    setNetworkLoading(true);
    // Get from storage to check if it exists
    const communication = getCommunication(communicationId)!;
    const possibleFileKey = getTempFileKeyForCommunicationPdf(communicationId);
    let s3Object;
    try {
      const fileUrl = await getUrl({
        key: possibleFileKey,
        options: {
          expiresIn: ONE_DAY_IN_SECONDS,
          validateObjectExistence: true,
        },
      });
      // Get file properties
      const fileProperties = await getProperties({ key: possibleFileKey });

      s3Object =
        fileProperties.lastModified &&
        isAfter(new Date((communication as CommunicationDTO)._lastChangedAt), new Date(fileProperties.lastModified))
          ? await generateAndGetS3Object()
          : fileUrl.url.href; // Get url
    } catch (error) {
      // File is not there yet
      s3Object = await generateAndGetS3Object();
    }

    setCommunicationPdf(s3Object as unknown as string);
  };

  return {
    generateDownloadCommunication,
    generateDownloadCommunicationLoading: networkLoading,
  };
}
