import {
  BatchChangeCommunicationsAction,
  BatchChangeCommunicationsMutationVariables,
  BatchChangeIdVersion,
  COMMUNICATION_TABLE_NAME,
  Communication,
  CommunicationChannel,
  CommunicationStatus,
  CommunicationType,
  CreateCommunicationInput,
  CreateCommunicationMutationVariables,
  DeleteCommunicationMutationVariables,
  Lease,
  LooseObject,
  MutationStatus,
  OnCreateCommunicationSubscription,
  OnCreateSignatureDocumentSubscription,
  OnDeleteCommunicationSubscription,
  OnDeleteSignatureDocumentSubscription,
  OnUpdateCommunicationSubscription,
  OnUpdateSignatureDocumentSubscription,
  SignatureDocument,
  SignatureDocumentStatus,
  Unit,
  batchChangeCommunications as batchChangeCommunicationsMutation,
  cleanInputCreate,
  cleanInputUpdate,
  createCommunication as createMutation,
  deleteCommunication as deleteMutation,
  getCommunication as getQuery,
  getReadId,
  getTableClientId,
  isNilOrEmpty,
  onCreateCommunication,
  onDeleteCommunication,
  onUpdateCommunication,
  onUpdateSignatureDocument,
  updateCommunication as updateMutation,
} from '@rentguru/commons-utils';
import {
  NUMBER_OF_MINUTES_FOR_REFETCH,
  NUMBER_OF_MINUTES_FOR_RESYNC,
  deleteEntityWithFetchBefore,
  get,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { differenceInMinutes, isBefore } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { syncLightCommunications } from '../tenantHooks/graphqlHelper';
import { useContextLoader } from './ContextLoader';
import { useSignatureDocuments } from './SignatureDocumentContext';
import { useUser } from './UserContext';
import { getFilteredTeamsBuildingsUnitsAndLeasesForUserId } from './utils';
import { usePermissions } from './utils/PermissionsContext';

export enum CommunicationCategory {
  TO_SEND,
  HISTORY,
  OUTBOX,
  OUTDATED,
}

export enum FetchByType {
  BY_LEASE = 'leaseId',
  BY_CLIENT = 'clientId',
  BY_BUILDING = 'buildingId',
  BY_CONTACT = 'contactId',
}

export interface ClassifiedCommunications {
  communicationsToSend: Communication[];
  communicationsOutbox: Communication[];
  communicationsHistory: Communication[];
  communicationsOutdated: Communication[];
}

const LIMIT_LIGHT_COMMUNICATIONS = 3000;

export interface CommunicationContext extends CommunicationState {
  createCommunication: (input: Omit<Communication, 'id' | 'clientId' | 'readId'>) => Promise<Communication | undefined>;
  updateCommunication: (
    original: Communication | { id: string },
    updates: Partial<Communication>
  ) => Promise<Communication | undefined>;
  deleteCommunication: (id: string) => Promise<void>;
  batchDeleteCommunications: (communicationsIdsVersions: BatchChangeIdVersion[]) => Promise<boolean>;
  batchSendCommunications: (communicationsIdsVersions: BatchChangeIdVersion[], resend?: boolean) => Promise<boolean>;
  getCommunicationsForBuilding: (buildingId: string) => Communication[];
  getCommunicationsForUnit: (unitId: string) => Communication[];
  getCommunicationsForLease: (leaseId: string) => Communication[];
  getCommunicationsForSignatureDocument: (signatureDocumentId: string) => Communication[] | undefined;
  getLightCommunicationsToSend: () => Communication[];
  getLightCommunicationsOutbox: () => Communication[];
  getLightCommunicationsHistory: () => Communication[];
  getLightCommunicationsOutdated: () => Communication[];
  getLightCommunicationsForOwner: (ownerUnits: Unit[], ownerId: string) => Communication[];
  getCommunication: (id: string) => Communication | undefined;
  setSyncCommunications: () => void;
}

interface CommunicationState {
  communicationsRaw: Communication[];
  communicationsToSendOutboxRaw: Communication[];
  communicationsHistoryRaw: Communication[];
  communicationsOutdatedRaw: Communication[];
  communicationsLoading?: boolean;
  communicationsError?: string;
  lastSync?: Date;
  lastHistorySync?: Date;
  lastOutdatedSync?: Date;
  shouldSyncLight: boolean;
  shouldFetchCommunications: string[];
  shouldFetchToSendOutboxCommunications: boolean;
  shouldFetchHistoryCommunications: boolean;
  shouldFetchOutdatedCommunications: boolean;
  shouldFetchByBuilding: string[];
  shouldFetchByLease: string[];
  shouldFetchByUnit: string[];
  shouldFetchByOwner: { leaseIds: string[]; unitIds: string[]; ownerId: string }[];
  lastCommunicationIdFetch?: string;
  lastCommunicationsFetch: { [communicationId: string]: Date | null };
}

type Action =
  | {
      type: 'SHOULD_SYNC_LIGHT' | 'IS_FETCHING';
    }
  | {
      type: 'ADD_COMMUNICATION';
      payload: { communication: Communication };
    }
  | {
      type: 'SHOULD_FETCH';
      payload: {
        communicationCategory: CommunicationCategory;
      };
    }
  | {
      type: 'FETCHED';
      payload: {
        communications: Communication[];
        communicationCategory?: CommunicationCategory;
        error?: string;
        shouldUpdateLastSync?: boolean;
      };
    }
  | {
      type: 'ERROR';
      payload: { error: string };
    }
  | {
      type: 'UPDATE_COMMUNICATION';
      payload: { communication: Communication };
    }
  | {
      type: 'UPDATE_SIGNATURE_DOCUMENT_IN_COMMUNICATION';
      payload: { signatureDocument: SignatureDocument };
    }
  | {
      type: 'DELETE_COMMUNICATION' | 'FETCH_CLASSIC';
      payload: { id: string };
    }
  | {
      type: 'FETCH_BUILDING';
      payload: { buildingId: string };
    }
  | {
      type: 'FETCH_LEASE';
      payload: { leaseId: string };
    }
  | {
      type: 'FETCH_UNIT';
      payload: { unitId: string };
    }
  | {
      type: 'FETCH_OWNER';
      payload: { ownerLeaseIds: string[]; ownerUnitIds: string[]; ownerId: string };
    };

const initialState: CommunicationState = {
  communicationsRaw: [],
  communicationsToSendOutboxRaw: [],
  communicationsHistoryRaw: [],
  communicationsOutdatedRaw: [],
  communicationsLoading: false,
  communicationsError: undefined,
  shouldSyncLight: false,
  shouldFetchCommunications: [],
  shouldFetchToSendOutboxCommunications: false,
  shouldFetchHistoryCommunications: false,
  shouldFetchOutdatedCommunications: false,
  shouldFetchByBuilding: [],
  shouldFetchByLease: [],
  shouldFetchByUnit: [],
  shouldFetchByOwner: [],
  lastCommunicationsFetch: {},
};

const getFilteredAndClassifiedCommunicationsWithEntity = (
  communications: Communication[],
  leases: Lease[],
  units: Unit[],
  signatureDocuments: SignatureDocument[] | null
): ClassifiedCommunications => {
  const filteredCommunications = communications.filter((communication) => {
    if (communication.leaseId) {
      return !isNil(leases.some((lease) => lease.id === communication.leaseId));
    }
    if (communication.unitId) {
      return !isNil(units.some((unit) => unit.id === communication.unitId));
    }

    return (
      !isNil(communication.contactId) ||
      !isNil(communication.buildingId) ||
      communication.type === CommunicationType.CUSTOM
    );
  });
  // Attach entities to communications
  const communicationsWithEntities = filteredCommunications.map((communication) => {
    const shouldGetSignatureDocument = communication.signatureDocumentId && !communication.signatureDocument;
    if (shouldGetSignatureDocument) {
      const signatureDocument = signatureDocuments?.find(
        (currentSignatureDocument) => currentSignatureDocument.id === communication.signatureDocumentId
      );
      return { ...communication, signatureDocument };
    }
    return communication;
  });

  return classifyCommunications(communicationsWithEntities, signatureDocuments ?? []);
};

const addSignatureDocumentToMatchingCommunication = (
  communications: Communication[],
  signatureDocument: SignatureDocument
) =>
  communications.map((communication) => {
    if (communication.signatureDocumentId === signatureDocument.id) {
      communication.signatureDocument = signatureDocument;
    }
    return communication;
  });

export const communicationReducer = (state: CommunicationState, action: Action): CommunicationState => {
  switch (action.type) {
    case 'SHOULD_SYNC_LIGHT':
      if (
        state.communicationsLoading ||
        (state.lastSync && differenceInMinutes(new Date(), state.lastSync) < NUMBER_OF_MINUTES_FOR_RESYNC)
      ) {
        return state;
      }
      return {
        ...state,
        shouldSyncLight: true,
      };
    case 'SHOULD_FETCH':
      const communicationCategoryFromAction = action.payload.communicationCategory;
      if (!communicationCategoryFromAction) {
        return state;
      }
      if ([CommunicationCategory.OUTBOX, CommunicationCategory.TO_SEND].includes(communicationCategoryFromAction)) {
        if (state.lastSync && differenceInMinutes(new Date(), state.lastSync) < NUMBER_OF_MINUTES_FOR_RESYNC) {
          return state;
        }
        return { ...state, shouldFetchToSendOutboxCommunications: true, lastSync: new Date() };
      }
      if (communicationCategoryFromAction === CommunicationCategory.HISTORY) {
        if (
          state.lastHistorySync &&
          differenceInMinutes(new Date(), state.lastHistorySync) < NUMBER_OF_MINUTES_FOR_RESYNC
        ) {
          return state;
        }
        return { ...state, shouldFetchHistoryCommunications: true, lastHistorySync: new Date() };
      }
      if (communicationCategoryFromAction === CommunicationCategory.OUTDATED) {
        if (
          state.lastOutdatedSync &&
          differenceInMinutes(new Date(), state.lastOutdatedSync) < NUMBER_OF_MINUTES_FOR_RESYNC
        ) {
          return state;
        }
        return { ...state, shouldFetchOutdatedCommunications: true, lastOutdatedSync: new Date() };
      }
      return state;
    case 'IS_FETCHING':
      return {
        ...state,
        communicationsLoading: true,
      };
    case 'FETCHED':
      if (action.payload.error) {
        return {
          ...state,
          communicationsRaw: [],
          communicationsLoading: false,
          shouldSyncLight: false,
          communicationsError: action.payload.error,
        };
      }

      const updatedState: {
        communicationsToSendOutboxRaw?: Communication[];
        communicationsHistoryRaw?: Communication[];
        communicationsOutdatedRaw?: Communication[];
        communicationsRaw?: Communication[];
        shouldFetchToSendOutboxCommunications?: boolean;
        shouldFetchHistoryCommunications?: boolean;
        shouldFetchOutdatedCommunications?: boolean;
        lastHistorySync?: Date;
        lastOutdatedSync?: Date;
        lastCommunicationsFetch: { [communicationId: string]: Date | null };
      } = state;

      // Update the state depending the communicationCategory
      const actionCommunicationCategory = action.payload.communicationCategory;
      if (isNil(actionCommunicationCategory)) {
        const filteredIds = action.payload.communications.map((communication) => communication.id);
        const newCommunicationsRaw = action.payload.communications.reduce((acc, newCommunication) => {
          const oldCommunication = updatedState.communicationsRaw?.find(
            (communication) => communication.id === newCommunication.id
          );
          if (oldCommunication?.body && !newCommunication.body) {
            acc.push(oldCommunication);
            return acc;
          }
          acc.push(newCommunication);

          return acc;
        }, [] as Communication[]);

        updatedState.communicationsRaw = [
          ...(updatedState.communicationsRaw ?? []).filter((communication) => !filteredIds.includes(communication.id)),
          ...newCommunicationsRaw,
        ];
        const now = new Date();
        if (action.payload.shouldUpdateLastSync) {
          action.payload.communications.forEach((communication) => {
            const isFullCommunication = !isNilOrEmpty(communication.body);
            if (isFullCommunication) {
              updatedState.lastCommunicationsFetch[communication.id] = now;
            }
          });
        }
      } else if ([CommunicationCategory.TO_SEND, CommunicationCategory.OUTBOX].includes(actionCommunicationCategory)) {
        updatedState.communicationsToSendOutboxRaw = action.payload.communications;
        updatedState.shouldFetchToSendOutboxCommunications = false;
      } else if (actionCommunicationCategory === CommunicationCategory.HISTORY) {
        updatedState.communicationsHistoryRaw = action.payload.communications;
        updatedState.shouldFetchHistoryCommunications = false;
        updatedState.lastHistorySync = new Date();
      } else if (actionCommunicationCategory === CommunicationCategory.OUTDATED) {
        updatedState.communicationsOutdatedRaw = action.payload.communications;
        updatedState.shouldFetchOutdatedCommunications = false;
        updatedState.lastOutdatedSync = new Date();
      }

      return {
        ...state,
        ...updatedState,
        communicationsLoading: false,
        shouldSyncLight: false,
        lastSync: new Date(),
        communicationsError: undefined,
      };

    case 'FETCH_BUILDING':
      const actionBuildingId = action.payload.buildingId;
      const currentShouldFetchByBuilding = state.shouldFetchByBuilding;
      if (currentShouldFetchByBuilding.includes(actionBuildingId)) {
        return state;
      }

      return { ...state, shouldFetchByBuilding: [actionBuildingId, ...currentShouldFetchByBuilding] };
    case 'FETCH_LEASE':
      const actionLeaseId = action.payload.leaseId;
      const currentShouldFetchByLease = state.shouldFetchByLease;
      if (currentShouldFetchByLease.includes(actionLeaseId)) {
        return state;
      }

      return { ...state, shouldFetchByLease: [actionLeaseId, ...currentShouldFetchByLease] };

    case 'FETCH_UNIT':
      const actionUnitId = action.payload.unitId;
      const currentShouldFetchByUnit = state.shouldFetchByUnit;
      if (currentShouldFetchByUnit.includes(actionUnitId)) {
        return state;
      }

      return { ...state, shouldFetchByUnit: [actionUnitId, ...currentShouldFetchByUnit] };
    case 'FETCH_OWNER':
      const actionLeaseIds = action.payload.ownerLeaseIds;
      const actionUnitIds = action.payload.ownerUnitIds;
      const actionOwnerId = action.payload.ownerId;
      if (isEmpty(actionLeaseIds) && isEmpty(actionUnitIds)) {
        return state;
      }
      const shouldFetchByOwnerObject = { leaseIds: actionLeaseIds, unitIds: actionUnitIds, ownerId: actionOwnerId };
      const currentShouldFetchByOwner = state.shouldFetchByOwner;
      if (currentShouldFetchByOwner.some((object) => object.ownerId === actionOwnerId)) {
        return state;
      }
      return { ...state, shouldFetchByOwner: [shouldFetchByOwnerObject, ...currentShouldFetchByOwner] };
    case 'FETCH_CLASSIC':
      const actionCommunicationId = action.payload.id;
      const lastCommunicationIdFetch = state.lastCommunicationsFetch[actionCommunicationId];
      if (
        !actionCommunicationId ||
        state.shouldFetchCommunications.includes(actionCommunicationId) ||
        (lastCommunicationIdFetch &&
          differenceInMinutes(new Date(), lastCommunicationIdFetch) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }

      state.shouldFetchCommunications.unshift(actionCommunicationId);
      return { ...state };
    case 'ADD_COMMUNICATION':
      const communicationCreated = action.payload.communication;
      return {
        ...state,
        communicationsRaw: [...state.communicationsRaw, communicationCreated],
        communicationsToSendOutboxRaw: [...state.communicationsToSendOutboxRaw, communicationCreated],
      };
    case 'UPDATE_COMMUNICATION':
      const updatedCommunication = action.payload.communication;
      const oldRecord =
        state.communicationsRaw.find((communication) => communication.id === updatedCommunication.id) ??
        state.communicationsToSendOutboxRaw.find((communication) => communication.id === updatedCommunication.id);
      if (isNil(oldRecord) || !recordWasUpdated(oldRecord, updatedCommunication)) {
        return state;
      }
      const updatedCommunicationHasBounceError = updatedCommunication.status === CommunicationStatus.BOUNCE_ERROR;
      const updatedCommunicationHasBeenSent = updatedCommunication.status === CommunicationStatus.SENT;
      const toSendCommunicationIsOutdated =
        updatedCommunication.status === CommunicationStatus.OUTDATED &&
        oldRecord.status === CommunicationStatus.TO_SEND;
      const outdatedCommunicationIsToSend =
        oldRecord.status === CommunicationStatus.OUTDATED &&
        updatedCommunication.status === CommunicationStatus.TO_SEND;
      const outdatedCommunicationIsSent =
        oldRecord.status === CommunicationStatus.OUTDATED && updatedCommunication.status === CommunicationStatus.SENT;

      const newCommunicationsRaw = state.communicationsRaw.reduce(
        (acc: Communication[], communication: Communication) => {
          if (communication.id === updatedCommunication.id) {
            if (toSendCommunicationIsOutdated) {
              return acc;
            }
            acc.push(updatedCommunication);
            return acc;
          }
          acc.push(communication);
          return acc;
        },
        []
      );

      const newCommunicationsHistoryRaw = updatedCommunicationHasBounceError
        ? state.communicationsHistoryRaw.filter((communication) => communication.id !== updatedCommunication.id)
        : [...state.communicationsHistoryRaw];

      if (updatedCommunicationHasBeenSent) {
        newCommunicationsHistoryRaw.unshift(updatedCommunication);
      }

      const newCommunicationsToSendOutboxRaw = state.communicationsToSendOutboxRaw.reduce(
        (acc: Communication[], communication: Communication) => {
          if (communication.id === updatedCommunication.id) {
            if (updatedCommunicationHasBeenSent || updatedCommunicationHasBounceError) {
              return acc;
            }
            acc.push(updatedCommunication);
            return acc;
          }
          acc.push(communication);
          return acc;
        },
        []
      );

      if (updatedCommunicationHasBounceError) {
        newCommunicationsToSendOutboxRaw.unshift(updatedCommunication);
      }

      const newCommunicationsOutdatedRaw = state.communicationsOutdatedRaw.reduce(
        (acc: Communication[], communication: Communication) => {
          if (communication.id === updatedCommunication.id) {
            if (outdatedCommunicationIsSent || outdatedCommunicationIsToSend) {
              return acc;
            }
            acc.push(updatedCommunication);
            return acc;
          }
          acc.push(communication);
          return acc;
        },
        []
      );

      if (toSendCommunicationIsOutdated) {
        newCommunicationsOutdatedRaw.unshift(updatedCommunication);
      }

      if (outdatedCommunicationIsSent) {
        newCommunicationsHistoryRaw.unshift(updatedCommunication);
      }

      if (outdatedCommunicationIsToSend) {
        newCommunicationsRaw.unshift(updatedCommunication);
      }

      return {
        ...state,
        communicationsRaw: newCommunicationsRaw,
        communicationsHistoryRaw: newCommunicationsHistoryRaw,
        communicationsToSendOutboxRaw: newCommunicationsToSendOutboxRaw,
        communicationsOutdatedRaw: newCommunicationsOutdatedRaw,
      };
    case 'UPDATE_SIGNATURE_DOCUMENT_IN_COMMUNICATION':
      const updatedSignatureDocument = action.payload.signatureDocument;
      return {
        ...state,
        communicationsRaw: addSignatureDocumentToMatchingCommunication(
          state.communicationsRaw,
          updatedSignatureDocument
        ),
        communicationsHistoryRaw: addSignatureDocumentToMatchingCommunication(
          state.communicationsHistoryRaw,
          updatedSignatureDocument
        ),
        communicationsToSendOutboxRaw: addSignatureDocumentToMatchingCommunication(
          state.communicationsToSendOutboxRaw,
          updatedSignatureDocument
        ),
        communicationsOutdatedRaw: addSignatureDocumentToMatchingCommunication(
          state.communicationsOutdatedRaw,
          updatedSignatureDocument
        ),
      };
    case 'DELETE_COMMUNICATION':
      return {
        ...state,
        communicationsRaw: state.communicationsRaw.filter((communication) => communication.id !== action.payload.id),
        communicationsToSendOutboxRaw: state.communicationsToSendOutboxRaw.filter(
          (communication) => communication.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

export const classifyCommunications = (
  communications: Communication[],
  signatureDocuments: SignatureDocument[] | null
): ClassifiedCommunications => {
  const classifiedCommunications = communications.reduce(
    (acc: ClassifiedCommunications, communication: Communication) => {
      const isLetter = [CommunicationChannel.LETTER, CommunicationChannel.REGISTERED_LETTER].includes(
        communication.channel
      );

      // Letters
      if (isLetter) {
        const relatedSignatureDocument = signatureDocuments?.find(
          (signatureDocument) => signatureDocument.id === communication.signatureDocumentId
        );
        if ([CommunicationStatus.TO_SEND, CommunicationStatus.CREDIT_ERROR].includes(communication.status)) {
          const isForOutbox =
            relatedSignatureDocument && SignatureDocumentStatus.PENDING === relatedSignatureDocument.status;
          if (isForOutbox) {
            acc.communicationsOutbox.push(communication);
          } else {
            acc.communicationsToSend.push(communication);
          }
          return acc;
        }
        if (relatedSignatureDocument?.status === SignatureDocumentStatus.SIGNED) {
          acc.communicationsHistory.push(communication);
          return acc;
        }
        if (communication.status === CommunicationStatus.OUTDATED) {
          acc.communicationsOutdated.push(communication);
          return acc;
        }
      }

      // Emails and SMS
      if (
        [CommunicationStatus.TO_SEND, CommunicationStatus.BOUNCE_ERROR].includes(communication.status) ||
        (communication.status === CommunicationStatus.SNOOZED &&
          !isNil(communication.snoozedDate) &&
          isBefore(new Date(communication.snoozedDate), new Date()))
      ) {
        acc.communicationsToSend.push(communication);
        return acc;
      }
      if (communication.status === CommunicationStatus.SENT) {
        acc.communicationsHistory.push(communication);
        return acc;
      }
      if (communication.status === CommunicationStatus.OUTDATED) {
        acc.communicationsOutdated.push(communication);
      }
      return acc;
    },
    {
      communicationsToSend: [],
      communicationsOutbox: [],
      communicationsHistory: [],
      communicationsOutdated: [],
    } as ClassifiedCommunications
  );
  return classifiedCommunications;
};

export const fetchCommunication = async (communicationId: string): Promise<Communication> => {
  const communicationResult = await get<Communication>(getQuery, communicationId);
  return communicationResult;
};

export const fetchLightCommunications = async (
  by: FetchByType,
  byValue: string,
  statusList?: CommunicationStatus[]
): Promise<Communication[]> => {
  const statusFilter: LooseObject | undefined = statusList
    ? { or: statusList.map((currentStatus) => ({ status: { eq: currentStatus } })) }
    : undefined;

  return await list<Communication>(
    syncLightCommunications,
    by,
    byValue,
    statusFilter,
    false,
    undefined,
    LIMIT_LIGHT_COMMUNICATIONS
  );
};

const fetchLightCommunicationsByUnit = async (unitId: string, clientId: string): Promise<Communication[]> => {
  const additionalFilter = {
    and: [{ unitId: { eq: unitId } }],
  };

  return await list<Communication>(
    syncLightCommunications,
    'clientId',
    clientId,
    additionalFilter,
    false,
    undefined,
    LIMIT_LIGHT_COMMUNICATIONS
  );
};

const fetchLightCommunicationsForOwner = async (
  leaseIds: string[],
  unitIds: string[],
  clientId: string
): Promise<Communication[]> => {
  const leaseFilter = { or: leaseIds.map((currentLeaseId) => ({ leaseId: { eq: currentLeaseId } })) };
  const unitFilter = { or: unitIds.map((currentUnitId) => ({ unitId: { eq: currentUnitId } })) };
  const additionalFilter = {
    or: [{ ...leaseFilter, ...unitFilter }],
  };

  return await list<Communication>(
    syncLightCommunications,
    'clientId',
    clientId,
    additionalFilter,
    false,
    undefined,
    LIMIT_LIGHT_COMMUNICATIONS
  );
};

// eslint-disable-next-line no-redeclare
export const CommunicationContext = React.createContext<CommunicationContext | null>(null);

export const CommunicationContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<CommunicationState, Action>>(communicationReducer, initialState);
  const { clientId, userId } = useUser();
  const { communicationsDetailsDelete } = usePermissions();
  const { signatureDocuments, loading: signatureDocumentsLoading } = useSignatureDocuments();
  const {
    Team: { data: teamsLoader, loading: teamsLoading },
    UserTeam: { data: userTeamsLoader, loading: userTeamsLoading },
    Building: { data: buildingsLoader, loading: buildingsLoading },
    Unit: { data: unitsLoader, loading: unitsLoading },
    UnitLease: { data: unitLeasesLoader, loading: unitLeasesLoading },
    Lease: { data: leasesLoader, loading: leasesLoading },
  } = useContextLoader();

  const finishedLoading =
    !teamsLoading &&
    !userTeamsLoading &&
    !buildingsLoading &&
    !unitsLoading &&
    !unitLeasesLoading &&
    !leasesLoading &&
    !signatureDocumentsLoading;

  useEffect(() => {
    if (state.shouldSyncLight && finishedLoading) {
      syncAndSetLightCommunications(CommunicationCategory.TO_SEND);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldSyncLight, finishedLoading]);

  useEffect(() => {
    if (state.shouldFetchToSendOutboxCommunications && finishedLoading) {
      syncAndSetLightCommunications(CommunicationCategory.TO_SEND);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchToSendOutboxCommunications, finishedLoading]);

  useEffect(() => {
    if (state.shouldFetchHistoryCommunications && finishedLoading) {
      syncAndSetLightCommunications(CommunicationCategory.HISTORY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchHistoryCommunications, finishedLoading]);

  useEffect(() => {
    if (state.shouldFetchOutdatedCommunications && finishedLoading) {
      syncAndSetLightCommunications(CommunicationCategory.OUTDATED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchOutdatedCommunications, finishedLoading]);

  const dispatchFetched = (communicationsToFetch: Communication[], shouldUpdateLastSync: boolean = true) => {
    dispatch({
      type: 'FETCHED',
      payload: {
        communications: communicationsToFetch,
        shouldUpdateLastSync,
      },
    });
  };

  useEffect(() => {
    let unmounted = false;
    const fetchAndSet = async () => {
      const communicationId = state.shouldFetchCommunications[0];
      if (!isEmpty(communicationId)) {
        dispatch({ type: 'IS_FETCHING' });
        const communication = await fetchCommunication(communicationId);
        if (!unmounted) {
          dispatchFetched([
            ...communications.filter((currentCommunication) => currentCommunication.id !== communication.id),
            communication,
          ]);
        }
      }
    };
    if (!isEmpty(state.shouldFetchCommunications) && finishedLoading) {
      fetchAndSet();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchCommunications, state.shouldFetchCommunications.length, finishedLoading]);

  useEffect(() => {
    let unmounted = false;
    const fetchAndSet = async () => {
      const buildingId = state.shouldFetchByBuilding[0];
      if (!isEmpty(buildingId)) {
        dispatch({ type: 'IS_FETCHING' });
        const communicationsByBuilding = await fetchLightCommunications(FetchByType.BY_BUILDING, buildingId);
        if (!unmounted) {
          dispatchFetched(
            [
              ...communications.filter((currentCommunication) => currentCommunication.buildingId !== buildingId),
              ...communicationsByBuilding,
            ],
            false
          );
        }
      }
    };
    if (!isEmpty(state.shouldFetchByBuilding) && !state.communicationsLoading && finishedLoading) {
      fetchAndSet();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchByBuilding, finishedLoading]);

  useEffect(() => {
    let unmounted = false;
    const fetchAndSet = async () => {
      const leaseId = state.shouldFetchByLease[0];

      if (!isEmpty(leaseId)) {
        dispatch({ type: 'IS_FETCHING' });
        const communicationsByLease = await fetchLightCommunications(FetchByType.BY_LEASE, leaseId);
        if (!unmounted) {
          dispatchFetched(
            [
              ...communications.filter((currentCommunication) => currentCommunication.leaseId !== leaseId),
              ...communicationsByLease,
            ],
            false
          );
        }
      }
    };
    if (!isEmpty(state.shouldFetchByLease) && !state.communicationsLoading && finishedLoading) {
      fetchAndSet();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchByLease, finishedLoading]);

  useEffect(() => {
    let unmounted = false;
    const fetchAndSet = async () => {
      const unitId = state.shouldFetchByUnit[0];
      if (!isEmpty(unitId)) {
        const communicationsByUnit = await fetchLightCommunicationsByUnit(
          unitId,
          getTableClientId(clientId!, COMMUNICATION_TABLE_NAME)
        );
        if (!unmounted) {
          dispatchFetched(
            [
              ...communications.filter((currentCommunication) => currentCommunication.unitId !== unitId),
              ...communicationsByUnit,
            ],
            false
          );
        }
      }
    };
    if (!isEmpty(state.shouldFetchByUnit) && !state.communicationsLoading && finishedLoading) {
      fetchAndSet();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchByUnit, finishedLoading]);

  useEffect(() => {
    let unmounted = false;
    const fetchAndSet = async () => {
      const ownerLeaseAndUnitIds = state.shouldFetchByOwner[0];
      if (!isNil(ownerLeaseAndUnitIds.leaseIds) || !isNil(ownerLeaseAndUnitIds.unitIds)) {
        dispatch({ type: 'IS_FETCHING' });
        const communicationsByOwner = await fetchLightCommunicationsForOwner(
          ownerLeaseAndUnitIds.leaseIds,
          ownerLeaseAndUnitIds.unitIds,
          getTableClientId(clientId!, COMMUNICATION_TABLE_NAME)
        );
        const allCommunicationsId = communicationsByOwner.map((currentCommunication) => currentCommunication.id);
        if (!unmounted) {
          dispatchFetched(
            [
              ...communications.filter(
                (currentCommunication) => !allCommunicationsId.includes(currentCommunication.id)
              ),
              ...communicationsByOwner,
            ],
            false
          );
        }
      }
    };
    if (!isEmpty(state.shouldFetchByOwner) && finishedLoading) {
      fetchAndSet();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchByOwner, finishedLoading]);

  const syncAndSetLightCommunications = async (communicationCategory: CommunicationCategory) => {
    const communicationStatusList = [];
    if (communicationCategory === CommunicationCategory.TO_SEND) {
      communicationStatusList.push(CommunicationStatus.TO_SEND);
      communicationStatusList.push(CommunicationStatus.SNOOZED);
      communicationStatusList.push(CommunicationStatus.BOUNCE_ERROR);
      communicationStatusList.push(CommunicationStatus.CREDIT_ERROR);
    } else if (communicationCategory === CommunicationCategory.OUTBOX) {
      communicationStatusList.push(CommunicationStatus.TO_SEND);
      communicationStatusList.push(CommunicationStatus.SNOOZED);
    } else if (communicationCategory === CommunicationCategory.HISTORY) {
      communicationStatusList.push(CommunicationStatus.SENT);
    } else if (communicationCategory === CommunicationCategory.OUTDATED) {
      communicationStatusList.push(CommunicationStatus.OUTDATED);
    }
    try {
      dispatch({ type: 'IS_FETCHING' });
      const communications = await fetchLightCommunications(
        FetchByType.BY_CLIENT,
        getTableClientId(clientId!, COMMUNICATION_TABLE_NAME),
        communicationStatusList
      );
      dispatch({ type: 'FETCHED', payload: { communications, communicationCategory } });
    } catch (error) {
      dispatch({ type: 'FETCHED', payload: { communications: [], error: error as string } });
    }
  };

  useSubscriptions<
    OnCreateCommunicationSubscription,
    OnUpdateCommunicationSubscription,
    OnDeleteCommunicationSubscription
  >(
    onCreateCommunication,
    onUpdateCommunication,
    onDeleteCommunication,
    (data) => {
      dispatch({
        type: 'ADD_COMMUNICATION',
        payload: { communication: data.onCreateCommunication as Communication },
      });
    },
    (data) => {
      dispatch({
        type: 'UPDATE_COMMUNICATION',
        payload: { communication: data.onUpdateCommunication as Communication },
      });
    },
    (data) => {
      const { id } = data.onDeleteCommunication as Communication;
      dispatch({
        type: 'DELETE_COMMUNICATION',
        payload: { id },
      });
    }
  );

  useSubscriptions<
    OnCreateSignatureDocumentSubscription,
    OnUpdateSignatureDocumentSubscription,
    OnDeleteSignatureDocumentSubscription
  >(
    undefined,
    onUpdateSignatureDocument,
    undefined,
    undefined,
    (data) => {
      const updatedSignatureDocument = data.onUpdateSignatureDocument as SignatureDocument;
      dispatch({
        type: 'UPDATE_SIGNATURE_DOCUMENT_IN_COMMUNICATION',
        payload: { signatureDocument: updatedSignatureDocument },
      });
    },
    undefined
  );

  const { communicationsToSend, communicationsOutbox } = useMemo<{
    communicationsToSend: Communication[];
    communicationsOutbox: Communication[];
  }>(() => {
    if (!finishedLoading || !userId) {
      return { communicationsToSend: [], communicationsOutbox: [] };
    }
    const { units, leases } = getFilteredTeamsBuildingsUnitsAndLeasesForUserId(
      userId,
      userTeamsLoader,
      teamsLoader,
      buildingsLoader,
      unitsLoader,
      unitLeasesLoader,
      leasesLoader
    );
    const { communicationsToSend: filteredCommunicationsToSend, communicationsOutbox: filteredCommunicationsOutbox } =
      getFilteredAndClassifiedCommunicationsWithEntity(
        state.communicationsToSendOutboxRaw,
        leases,
        units,
        signatureDocuments
      );

    return {
      communicationsToSend: filteredCommunicationsToSend,
      communicationsOutbox: filteredCommunicationsOutbox,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedLoading, state.communicationsToSendOutboxRaw, userId]);

  const { communicationsHistory } = useMemo<{
    communicationsHistory: Communication[];
  }>(() => {
    if (!finishedLoading || !userId) {
      return { communicationsHistory: [] };
    }

    const { units, leases } = getFilteredTeamsBuildingsUnitsAndLeasesForUserId(
      userId,
      userTeamsLoader,
      teamsLoader,
      buildingsLoader,
      unitsLoader,
      unitLeasesLoader,
      leasesLoader
    );

    const { communicationsHistory: filteredCommunicationsHistory } = getFilteredAndClassifiedCommunicationsWithEntity(
      state.communicationsHistoryRaw,
      leases,
      units,
      signatureDocuments
    );

    return {
      communicationsHistory: filteredCommunicationsHistory,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedLoading, state.communicationsHistoryRaw, userId]);

  const { communicationsOutdated } = useMemo<{
    communicationsOutdated: Communication[];
  }>(() => {
    if (!finishedLoading || !userId) {
      return { communicationsOutdated: [] };
    }

    const { units, leases } = getFilteredTeamsBuildingsUnitsAndLeasesForUserId(
      userId,
      userTeamsLoader,
      teamsLoader,
      buildingsLoader,
      unitsLoader,
      unitLeasesLoader,
      leasesLoader
    );

    const { communicationsOutdated: filteredCommunicationsOutdated } = getFilteredAndClassifiedCommunicationsWithEntity(
      state.communicationsOutdatedRaw,
      leases,
      units,
      signatureDocuments
    );

    return {
      communicationsOutdated: filteredCommunicationsOutdated,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedLoading, state.communicationsOutdatedRaw, userId]);

  const { communications } = useMemo<{
    communications: Communication[];
  }>(() => {
    if (!finishedLoading || !userId) {
      return { communications: [] };
    }

    return { communications: state.communicationsRaw };
  }, [finishedLoading, state.communicationsRaw, userId]);

  const setSyncCommunications = () => {
    dispatch({ type: 'SHOULD_SYNC_LIGHT' });
  };

  const createCommunication = async (input: Omit<Communication, 'id' | 'clientId' | 'readId'>) => {
    try {
      const communication = await mutation<Communication, CreateCommunicationMutationVariables>(createMutation, {
        input: {
          ...(cleanInputCreate(input) as CreateCommunicationInput),
          clientId: getTableClientId(clientId!, COMMUNICATION_TABLE_NAME),
          readId: getReadId(clientId!, COMMUNICATION_TABLE_NAME),
        },
      });
      return communication;
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as string } });
    }
  };

  const updateCommunication = async (original: Communication | { id: string }, updates: Partial<Communication>) => {
    try {
      const communication = await fetchCommunication(original.id);
      const updatedCommunication = await mutation<Communication>(updateMutation, {
        input: {
          id: original.id,
          _version: communication._version,
          ...cleanInputUpdate(updates, false),
        },
      });
      return updatedCommunication;
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as string } });
    }
  };

  const deleteCommunication = async (id: string) => {
    if (!communicationsDetailsDelete) {
      return;
    }
    const communication = await fetchCommunication(id);
    if (!communication) {
      return;
    }
    try {
      await deleteEntityWithFetchBefore<Communication, DeleteCommunicationMutationVariables>(
        communication,
        getQuery,
        deleteMutation
      );
    } catch (error) {
      dispatch({ type: 'ERROR', payload: { error: error as string } });
    }
  };

  const batchDeleteCommunications = async (communicationsIdsVersions: BatchChangeIdVersion[]) => {
    return await batchChangeCommunications(communicationsIdsVersions, BatchChangeCommunicationsAction.DELETE);
  };

  const batchSendCommunications = async (communicationsIdsVersions: BatchChangeIdVersion[], resend = false) => {
    return await batchChangeCommunications(
      communicationsIdsVersions,
      resend ? BatchChangeCommunicationsAction.RESEND : BatchChangeCommunicationsAction.SEND
    );
  };

  const batchChangeCommunications = async (
    communicationsIdsVersions: BatchChangeIdVersion[],
    action: BatchChangeCommunicationsAction
  ) => {
    const result = await mutation<MutationStatus, BatchChangeCommunicationsMutationVariables>(
      batchChangeCommunicationsMutation,
      {
        input: { action, clientId: clientId!, userId: userId!, communications: communicationsIdsVersions },
      }
    );
    return result && result.status ? result.status : false;
  };

  const getCommunicationsForUnit = (unitId: string): Communication[] => {
    dispatch({ type: 'FETCH_UNIT', payload: { unitId } });
    if (state.communicationsLoading || state.communicationsError) {
      return [];
    }

    return communications.filter((communication) => communication.unit?.id === unitId);
  };

  const getCommunicationsForLease = (leaseId: string): Communication[] => {
    dispatch({ type: 'FETCH_LEASE', payload: { leaseId } });

    if (state.communicationsLoading || state.communicationsError) {
      return [];
    }

    return communications.filter((communication) => communication.leaseId === leaseId);
  };

  const getCommunicationsForBuilding = (buildingId: string): Communication[] => {
    dispatch({ type: 'FETCH_BUILDING', payload: { buildingId } });

    if (state.communicationsLoading || state.communicationsError) {
      return [];
    }

    return communications.filter((communication) => communication.buildingId === buildingId);
  };

  const getLightCommunicationsToSend = (): Communication[] => {
    dispatch({ type: 'SHOULD_FETCH', payload: { communicationCategory: CommunicationCategory.TO_SEND } });
    if (state.communicationsLoading || state.communicationsError) {
      return [];
    }
    return communicationsToSend;
  };

  const getLightCommunicationsOutbox = (): Communication[] => {
    dispatch({ type: 'SHOULD_FETCH', payload: { communicationCategory: CommunicationCategory.OUTBOX } });
    if (state.communicationsLoading || state.communicationsError) {
      return [];
    }
    return communicationsOutbox;
  };

  const getLightCommunicationsHistory = (): Communication[] => {
    dispatch({ type: 'SHOULD_FETCH', payload: { communicationCategory: CommunicationCategory.HISTORY } });
    if (state.communicationsLoading || state.communicationsError) {
      return [];
    }
    return communicationsHistory;
  };

  const getLightCommunicationsOutdated = (): Communication[] => {
    dispatch({ type: 'SHOULD_FETCH', payload: { communicationCategory: CommunicationCategory.OUTDATED } });
    if (state.communicationsLoading || state.communicationsError) {
      return [];
    }
    return communicationsOutdated;
  };

  const getLightCommunicationsForOwner = (ownerUnits: Unit[], ownerId: string): Communication[] => {
    const { ownerLeaseIds, ownerUnitIds } = ownerUnits.reduce(
      (acc, ownerUnit) => {
        const unitId = ownerUnit.id;
        if (unitId) {
          acc.ownerUnitIds.push(unitId);
        }
        ownerUnit.leases?.forEach((lease) => acc.ownerLeaseIds.push(lease.id));
        return acc;
      },
      { ownerLeaseIds: [] as string[], ownerUnitIds: [] as string[] }
    );

    dispatch({ type: 'FETCH_OWNER', payload: { ownerLeaseIds, ownerUnitIds, ownerId } });
    if (state.communicationsLoading || state.communicationsError) {
      return [];
    }

    const communicationsForOwner = communications.filter(
      (communication) =>
        ownerLeaseIds.includes(communication.leaseId ?? '') || ownerUnitIds.includes(communication.unitId ?? '')
    );

    return communicationsForOwner;
  };

  const getCommunicationsForSignatureDocument = (signatureDocumentId: string): Communication[] | undefined => {
    if (state.communicationsLoading || state.communicationsError) {
      return undefined;
    }
    return communications.filter((communication) => communication.signatureDocument?.id === signatureDocumentId);
  };

  const getCommunication = (id: string): Communication | undefined => {
    dispatch({ type: 'FETCH_CLASSIC', payload: { id } });
    if (state.communicationsLoading || state.communicationsError) {
      return undefined;
    }
    const communication = communications.find((currentCommunication) => currentCommunication.id === id);
    if (communication?.body) {
      // if full object
      return communication;
    }
    return undefined;
  };

  const values = useMemo(
    () => ({
      ...state,
      createCommunication,
      updateCommunication,
      deleteCommunication,
      batchDeleteCommunications,
      batchSendCommunications,
      getCommunicationsForBuilding,
      getCommunicationsForUnit,
      getCommunicationsForLease,
      getCommunicationsForSignatureDocument,
      getCommunication,
      getLightCommunicationsToSend,
      getLightCommunicationsOutbox,
      getLightCommunicationsHistory,
      getLightCommunicationsOutdated,
      getLightCommunicationsForOwner,
      setSyncCommunications,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <CommunicationContext.Provider value={values}>{children}</CommunicationContext.Provider>;
};

export const useCommunications = (): CommunicationContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<CommunicationContext | null>(CommunicationContext);

  if (isNil(context)) {
    throw new Error('`useCommunications` hook must be used within a `CommunicationContextProvider` component');
  }
  useEffect(() => {
    if (!firstRender.current && isEmpty(context.communicationsToSendOutboxRaw) && isNil(context.lastSync)) {
      context.setSyncCommunications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context as CommunicationContext;
};
