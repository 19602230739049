import { TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import {
  Building,
  Colors,
  LooseObject,
  Notification,
  NotificationStatus,
  Technic,
  TechnicType,
  Unit,
} from '@rentguru/commons-utils';
import { CustomIconButton, OverflowableTypography } from '@up2rent/ui';
import isNil from 'lodash/isNil';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';
import SnoozeMenu from 'src/components/Dashboard/DashboardTodos/DashboardDialogs/SnoozeMenu';
import { LEASES_DETAIL_TO } from 'src/components/Leases/Leases';
import { RouteDestination } from 'src/components/Routes/Routes';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import { useNotifications } from 'src/hooks/NotificationsContext';
import { useTechnics } from 'src/hooks/TechnicsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ReactComponent as CheckIcon } from 'src/icons/check.svg';
import { ReactComponent as HistoryIcon } from 'src/icons/history.svg';
import { ReactComponent as TicketIcon } from 'src/icons/ticket.svg';
import { ROW_HEIGHT } from '../DashboardCustomNotifications';

const createTicketLocationState = (
  entity: Technic | LeaseExtended | Building | Unit | null,
  type: 'technic' | 'lease' | 'unit' | 'building' | null,
  getUnit: (id: string) => Unit | undefined
) => {
  if (isNil(entity)) return {};
  if (type === 'building') {
    return { buildingId: entity.id };
  }
  if (type === 'unit') {
    return { unitId: entity.id };
  }
  if (type === 'lease') {
    const leaseUnit = getUnit((entity as LeaseExtended).units![0].unit!.id);
    return { ...(!isNil(leaseUnit) && { buildingId: leaseUnit.building!.id }) };
  }
  if (type === 'technic') {
    return {
      ...(!isNil((entity as Technic).building) && { buildingId: (entity as Technic).building!.id }),
      ...(!isNil((entity as Technic).unit) && { unitId: (entity as Technic).unit!.id }),
    };
  }
  return {};
};

const getEntityNameAdnExtraInfo = (
  entity: Technic | LeaseExtended | Building | Unit | null,
  type: 'technic' | 'lease' | 'unit' | 'building' | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string,
  getUnit: (id: string) => Unit | undefined
): { primaryText: string; secondaryText: string; type: string } => {
  if (isNil(entity)) return { primaryText: '', secondaryText: '', type: '' };
  if (type === 'building') {
    return {
      primaryText: '',
      secondaryText: (entity as Building).name,
      type: formatMessage({ id: 'rentalUnit.detail.general.building' }),
    };
  }
  if (type === 'unit') {
    return {
      primaryText: (entity as Unit).name,
      secondaryText: '',
      type: formatMessage({ id: 'rentalUnit.detail.menu.detailTitle' }),
    };
  }
  if (type === 'lease') {
    const leaseUnit = getUnit((entity as LeaseExtended).units![0].unit!.id);
    if (!isNil(leaseUnit)) {
      return {
        primaryText: (entity as LeaseExtended).units!.map((u) => u.unit!.name).join(','),
        secondaryText: leaseUnit.building!.name,
        type: formatMessage({ id: 'lease.title' }),
      };
    }
  } else if (type === 'technic') {
    const technicType =
      (entity as Technic).type === TechnicType.UTILITY_PROVIDER
        ? formatMessage({
            id: `enums.UtilityType.${(entity as Technic).utilityType!}`,
          })
        : formatMessage({ id: `enums.TechnicType.${(entity as Technic).type}` });
    if (!isNil((entity as Technic).building)) {
      return { primaryText: '', secondaryText: (entity as Technic).building!.name, type: technicType };
    }
    if (!isNil((entity as Technic).unit)) {
      return { primaryText: (entity as Technic).unit!.name, secondaryText: '', type: technicType };
    }
    return { primaryText: '', secondaryText: '', type: technicType };
  }
  return { primaryText: '', secondaryText: '', type: '' };
};

interface CustomNotificationRowProps {
  notification: Notification;
  setNotificationToSnooze: (notification: Notification | null) => void;
}

const CustomNotificationRow: React.FC<CustomNotificationRowProps> = ({ notification, setNotificationToSnooze }) => {
  const { ticketsDetailsWrite, notificationsDetailsWrite } = usePermissions();
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { updateNotification, notificationsLoading } = useNotifications();
  const { getUnit, unitsLoading } = useUnits();
  const { getLease, leasesLoading } = useLeases();
  const { getTechnic, technicsLoading } = useTechnics();
  const history = useHistory();

  let entity: Technic | LeaseExtended | Building | Unit | null = null;
  let entityType: 'technic' | 'lease' | 'unit' | 'building' | null = null;
  let destination: LooseObject | null = null;

  if (notificationsLoading || leasesLoading || unitsLoading || technicsLoading) {
    return (
      <TableRow style={{ minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
        <TableCell>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </TableCell>
      </TableRow>
    );
  }

  if (!isNil(notification.building)) {
    entity = notification.building;
    entityType = 'building';
    destination = {
      pathname: `${RouteDestination.PROPERTIES}/${notification.building.id}`,
      state: { goBackUrl: history.location },
    };
  } else if (!isNil(notification.unit)) {
    const unit = getUnit(notification.unit.id);
    if (!isNil(unit)) {
      entity = unit;
      entityType = 'unit';
      destination = {
        pathname: `${RouteDestination.PROPERTIES}/${unit?.building?.id}/unit-${unit.id}`,
        state: { goBackUrl: history.location },
      };
    }
  } else if (!isNil(notification.technic)) {
    const technic = getTechnic(notification.technic.id);
    if (!isNil(technic)) {
      entity = technic;
      entityType = 'technic';
      if (!isNil(technic.unit)) {
        const unit = getUnit(technic.unit.id);
        destination = {
          pathname: `${RouteDestination.PROPERTIES}/${unit?.building?.id}/unit-${technic.unit.id}/${
            technic.type === TechnicType.UTILITY_PROVIDER ? 'meters' : 'technics'
          }`,
          state: { goBackUrl: history.location, defaultTab: technic.type },
        };
      } else if (!isNil(technic.building)) {
        destination = {
          pathname: `${RouteDestination.PROPERTIES}/${technic.building.id}/${
            technic.type === TechnicType.UTILITY_PROVIDER ? 'meters' : 'technics'
          }`,
          state: { goBackUrl: history.location, defaultTab: technic.type },
        };
      }
    }
  } else if (!isNil(notification.lease)) {
    const lease = getLease(notification.lease.id);
    if (!isNil(lease)) {
      entity = lease;
      entityType = 'lease';
      destination = {
        pathname: `${RouteDestination.LEASES}/${LEASES_DETAIL_TO}/${entity.id}`,
        state: { goBackUrl: history.location },
      };
    }
  }
  const { type, primaryText, secondaryText } = getEntityNameAdnExtraInfo(entity, entityType, formatMessage, getUnit);
  return (
    <TableRow
      hover
      style={{
        cursor: !isNil(destination) ? 'pointer' : 'auto',
        textDecoration: 'none',
        minHeight: ROW_HEIGHT,
        height: ROW_HEIGHT,
      }}
      onClick={() => {
        if (!isNil(destination)) {
          history.push(destination);
        }
      }}
    >
      <TableCell align="left" padding="none" style={{ paddingLeft: 20 }}>
        <Typography style={{ color: Colors.CLASSICAL_BLACK, fontSize: 14, fontWeight: 700 }}>{type}</Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>{primaryText}</Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>{secondaryText}</Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <OverflowableTypography
          style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500, maxWidth: 700 }}
          text={!isNil(notification.message) ? notification.message : ''}
        />
      </TableCell>
      {ticketsDetailsWrite && (
        <TableCell align="left" padding="none">
          <Tooltip
            title={
              !isNil(notification.ticket)
                ? formatMessage({ id: 'tickets.detail.contact' })
                : formatMessage({ id: 'dashboard.ticket.createTicket' })
            }
            placement="top"
          >
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={(event) => {
                event.stopPropagation();
                history.push({
                  pathname: !isNil(notification.ticket)
                    ? `${RouteDestination.TICKETS_DETAILS}/${notification.ticket.id}`
                    : RouteDestination.ADD_TICKET,
                  state: {
                    goBackUrl: history.location,
                    ...createTicketLocationState(entity, entityType, getUnit),
                    notificationId: notification.id,
                  },
                });
              }}
              size="small"
              Icon={TicketIcon}
              iconStyle={{ fill: !isNil(notification.ticket) ? Colors.DODGER_BLUE : Colors.TOWER_GREY }}
            />
          </Tooltip>
        </TableCell>
      )}
      {notificationsDetailsWrite && (
        <TableCell align="left" padding="none">
          <Tooltip
            title={formatMessage({ id: 'dashboard.notifications.snooze' })}
            placement="top"
            onMouseDown={stopPropagation}
          >
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
              size="small"
              Icon={HistoryIcon}
              iconStyle={{ fill: Colors.BLUEY }}
            />
          </Tooltip>
        </TableCell>
      )}
      {notificationsDetailsWrite && (
        <TableCell align="left" padding="none">
          <Tooltip title={formatMessage({ id: 'dashboard.notifications.solve' })} placement="top">
            <CustomIconButton
              onMouseDown={stopPropagation}
              onClick={async (event) => {
                event.stopPropagation();
                await updateNotification(notification, { status: NotificationStatus.Done });
              }}
              size="small"
              Icon={CheckIcon}
              iconStyle={{ fill: Colors.DODGER_BLUE }}
            />
          </Tooltip>
        </TableCell>
      )}
      <SnoozeMenu
        anchorEl={anchorEl}
        snoozeAction={async (snoozedDate: Date) => {
          await updateNotification(notification!, {
            popupDate: snoozedDate.toISOString(),
            status: NotificationStatus.Snoozed,
          });
        }}
        customSnooze={() => setNotificationToSnooze(notification)}
        onClose={() => setAnchorEl(null)}
      />
    </TableRow>
  );
};

export default CustomNotificationRow;
