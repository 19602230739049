import { Grid, Typography } from '@material-ui/core';
import { Colors, Unit } from '@rentguru/commons-utils';
import { CustomIconButton } from '@up2rent/ui';
import { isBefore, isToday } from 'date-fns';
import { useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { isLeaseAlreadyStarted } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import FormAlertBox from 'src/components/ui/FormAlertBox';
import BulbRemark from 'src/components/ui/IconnedRemark';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as BulbIcon } from 'src/icons/bulb.svg';
import { ReactComponent as DeleteIcon } from 'src/icons/delete.svg';
import { getDetailedOverlappingError } from 'src/utils/unitsUtils';
import UnitSelectorWithLogoField from '../FormField/UnitSelectorWithLogoField';
import { AdditionalUnit, checkAndSetOverlappingEventsAndDatesValuesForSubUnit } from './AddAdditionalUnits';

interface AdditionalUnitSelectorFieldProps {
  id: string;
  index: number;
  units: Unit[];
  onDelete: (index: number) => void;
}

const AdditionalUnitSelectorField: React.FC<AdditionalUnitSelectorFieldProps> = ({ id, index, onDelete, units }) => {
  const { units: completeUnitsList, getUnit } = useUnits();
  const { getBuilding } = useBuildings();
  const { values, setFieldValue } = useFormikContext<AddLeaseFormValues>();
  const { formatMessage } = useIntl();
  const { language } = useLocale();

  const isRegionSameAsMainUnit = () => {
    if (isNil(values.subUnits[index].id)) return true;
    const subUnit = completeUnitsList.find((unit) => unit.id === values.subUnits[index].id);
    if (isNil(subUnit)) {
      return false;
    }
    const fullSubUnit = getUnit(values.subUnits[index].id);
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const subUnitBuilding = getBuilding(fullSubUnit?.building?.id!);
    const fullMainUnit = getUnit(values.unit.id ?? '');
    const mainUnitBuilding = getBuilding(fullMainUnit?.building?.id ?? '');
    return subUnitBuilding?.address?.region === mainUnitBuilding?.address?.region;
  };

  return (
    <Grid>
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <UnitSelectorWithLogoField
          index={index}
          units={units}
          fieldName={`subUnits[${index}]`}
          initialValue={id}
          inputStyle={{ width: 550, marginLeft: 30 }}
          disabled={isNil(values.unitOwnerId)}
          onChange={(selectedUnit: AdditionalUnit) => {
            const newSubUnits = values.subUnits.map((subUnit, mapIndex) =>
              mapIndex === index ? selectedUnit : subUnit
            );
            checkAndSetOverlappingEventsAndDatesValuesForSubUnit(
              newSubUnits,
              values.id,
              values.startDate,
              values.endDate,
              getUnit,
              setFieldValue,
              true
            );
          }}
        />
        <CustomIconButton
          style={{ width: 40, height: 40 }}
          onClick={() => onDelete(index)}
          Icon={DeleteIcon}
          iconStyle={{ fill: Colors.BLUE_GREY }}
        />
      </Grid>
      {!isNil(values.subUnits[index].overlappingNonEditedEvent) && (
        <FormAlertBox
          message1={getDetailedOverlappingError(
            values.subUnits[index].overlappingNonEditedEvent!,
            formatMessage,
            (dateLocaleMap as dateLocaleMapType)[language]
          )}
          message2={
            isBefore(new Date(values.subUnits[index].startDate), new Date()) &&
            !isToday(new Date(values.subUnits[index].startDate))
              ? formatMessage({
                  id: 'event.overlappingSubUnit',
                })
              : formatMessage({
                  id: 'event.overlappingSubUnitFuture',
                })
          }
          gridStyle={isLeaseAlreadyStarted(values.startDate) ? { backgroundColor: 'rgba(254, 209, 48,.15)' } : {}}
        />
      )}
      {!isRegionSameAsMainUnit() && !isEmpty(values.subUnits) && (
        <BulbRemark
          Icon={BulbIcon}
          message={
            <Typography
              style={{
                fontSize: 12,
                color: Colors.SLATE_GREY,
              }}
            >
              {formatMessage({ id: 'lease.addLease.regionsAreNotSame' })}
            </Typography>
          }
        />
      )}
    </Grid>
  );
};

export default AdditionalUnitSelectorField;
