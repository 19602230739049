import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';
import clsx from 'clsx';
import React from 'react';
import { BOX_SHADOW } from '../../styles';
import { stopPropagation } from '../../utils';

const useStyles = makeStyles({
  root: {
    color: Colors.CLASSICAL_WHITE,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: Colors.CARNATION_RED,
    borderRadius: '25px',
    textTransform: 'unset',
    lineHeight: '15px',
    paddingTop: 7,
    paddingBottom: 8,
    paddingRight: 15,
    paddingLeft: 15,
    '&:hover': {
      backgroundColor: Colors.CARNATION_RED,
    },
  },
  disabledRoot: {
    height: '50px',
    minWidth: '120px',
    borderRadius: '25px',
    boxShadow: BOX_SHADOW,
    backgroundColor: Colors.CARNATION_RED,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.25,
    paddingTop: 7,
    paddingBottom: 8,
    paddingRight: 15,
    paddingLeft: 15,
  },
});

interface ActionButtonProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export const ActionButton: React.FC<ActionButtonProps> = ({ children, style = {}, className = '', ...rest }) => {
  const classes = useStyles();
  const buttonStyle = className === '' ? { fontSize: '12px', ...style } : style;
  return (
    <div style={{ textAlign: 'center', display: 'inline-block' }}>
      <Button
        onMouseDown={stopPropagation}
        className={clsx(classes.root, className)}
        classes={{
          disabled: classes.disabledRoot,
        }}
        style={buttonStyle}
        {...rest}
      >
        {children}
      </Button>
    </div>
  );
};
