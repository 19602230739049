/* eslint-disable no-plusplus */
import { Typography } from '@material-ui/core';
import {
  TOP_OWNERS_BOX_KEY,
  Unit,
  UnitLease,
  UnitType,
  formatNumber,
  getContactNameFromObject,
  getCurrentLeases,
  getTopElementsBasedOnProperty,
} from '@rentguru/commons-utils';
import { isEmpty, isNil, toLower } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { BoxSwitchFiltersProps } from '../Filters/BoxSwitchFilters';
import RankingBox, { RankingBoxLayoutProps } from './RankingBox';

const DashboardRankingTopOwners: React.FC<RankingBoxLayoutProps> = ({ forceUpdateLayout }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const {
    switchFilters: { topOwners: topOwnersFilter },
  } = useDashboardFilters();
  const { owners, contactsLoading } = useContacts();
  const { getBuilding, buildingsLoading } = useBuildings();
  const { getUnit, unitsLoading } = useUnits();

  const loading = contactsLoading || buildingsLoading || unitsLoading;

  const switchs: BoxSwitchFiltersProps = {
    name: 'topOwners',
    filters: [
      {
        label: formatMessage({ id: 'dashboard.rankingFilters.all' }),
        value: 'all',
      },
      {
        label: formatMessage({ id: 'dashboard.rankingFilters.unitType' }),
        menuItems: Object.keys(UnitType).map((unitType) => ({
          label: formatMessage({ id: `enums.UnitType.${unitType}` }),
          value: unitType,
        })),
      },
    ],
  };

  const datas = useMemo(() => {
    const ownersWithIncomesAndUnits = owners.reduce((result, currentOwner) => {
      let numberOfUnits = 0;
      let unitIdsOfBuilding: string[] = [];
      let allUnitIds: string[] = [];
      // Get all units of his building
      if (!isNil(currentOwner.buildings) && !isEmpty(currentOwner.buildings)) {
        unitIdsOfBuilding = currentOwner.buildings
          .map((buildingOwner) => buildingOwner.building?.id)
          .map((buildingId) => {
            if (isNil(buildingId)) return [];

            const building = getBuilding(buildingId);
            if (isNil(building) || isNil(building.units) || isEmpty(building.units)) {
              return [];
            }
            numberOfUnits += building.units.length;

            return building.units.map((u) => u.id);
          })
          .reduce((currentList, currentArray) => {
            currentList.push(...currentArray);
            return currentList;
          }, []);
      }
      // Add all units
      if (!isNil(currentOwner.units) && !isEmpty(currentOwner.units)) {
        allUnitIds = currentOwner.units.map((unitOwner) => unitOwner.unit!.id);
        numberOfUnits += allUnitIds.length;
      }

      const allUnitIdsWithBuildings = [...allUnitIds, ...unitIdsOfBuilding];
      const unitsWithLease: Unit[] = allUnitIdsWithBuildings
        .map((unitId) => {
          const unit = getUnit(unitId);
          if (!isNil(unit) && !isNil(topOwnersFilter) && topOwnersFilter.value !== 'all') {
            if (topOwnersFilter.value !== unit.type) {
              numberOfUnits--;
              return null;
            }
          }

          return unit;
        })
        .filter((u) => !isNil(u)) as Unit[];

      // Total income
      let monthlyIncome = 0;
      unitsWithLease.forEach((unit) => {
        const leases: UnitLease[] | null = getCurrentLeases(unit.leases) as UnitLease[] | null;
        if (!isNil(leases)) {
          leases.forEach((unitlease) => {
            monthlyIncome += unitlease.rentalPrice;
          });
        }
      });

      result.push({
        owner: getContactNameFromObject(currentOwner),
        numberOfUnits,
        monthlyIncome,
        contactId: currentOwner.id,
      });
      return result;
    }, [] as { owner: string; numberOfUnits: number; monthlyIncome: number; contactId: string }[]);

    const top5Owner = getTopElementsBasedOnProperty<{
      owner: string;
      numberOfUnits: number;
      monthlyIncome: number;
      contactId: string;
    }>(ownersWithIncomesAndUnits, 'monthlyIncome', 5);
    return top5Owner.map((owner) => ({
      name: owner.owner,
      primaryText: formatNumber(owner.monthlyIncome, language, {
        style: 'currency',
        currency: 'EUR',
      }),
      secondaryText: `${owner.numberOfUnits} ${toLower(formatMessage({ id: 'rentalUnit.header.title' }))}`,
      lineDestination: `/contacts/${owner.contactId}`,
    }));
  }, [topOwnersFilter, owners, getBuilding, getUnit, language, formatMessage]);

  return (
    <RankingBox
      boxKey={TOP_OWNERS_BOX_KEY}
      title={formatMessage({ id: 'dashboard.topOwners.title' })}
      infoContent={
        <Typography style={{ fontSize: 12 }}>{formatMessage({ id: 'dashboard.topOwners.info' })}</Typography>
      }
      filtersBundle={switchs}
      datas={datas}
      loading={loading}
      forceUpdateLayout={forceUpdateLayout}
    />
  );
};

export default DashboardRankingTopOwners;
