import { Grid, Typography } from '@material-ui/core';
import {
  getVariousOperationLabel,
  LeaseVariousOperationType,
  VariousOperation,
  VariousOperationType,
} from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import AddEditVariousOperation from 'src/components/Settings/VariousOperations/AddEditVariousOperation';
import Dialog from 'src/components/ui/Dialog';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { getSafeValueInObject } from 'src/utils/object';
import CustomInputComboBox from '../ComboBox/FormikFullCustomComboBox';
import { CustomInputItemType } from '../ComboBox/FullCustomComboBox';
import { useCustomizedComboStyles } from '../ComboBox/TextComboBox';
import EditOrAddIconButton from './utils/EditOrAddIconButton';

interface SimpleVariousOperationSelectorProps {
  variousOperations: VariousOperation[];
  operationType: LeaseVariousOperationType;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  fieldName: string;
  inputWidth?: number;
  paperClass?: string;
  disabled?: boolean;
  onChangeObserver?: (
    valueToReturn: CustomInputItemType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => void;
}

const SimpleVariousOperationSelector: React.FC<SimpleVariousOperationSelectorProps> = ({
  variousOperations: allVariousOperations,
  style,
  inputStyle,
  fieldName,
  inputWidth = 560,
  disabled = false,
  onChangeObserver,
  operationType,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [dialogAddOpen, setDialogAddOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, errors, setFieldValue } = useFormikContext<any>();
  const { language } = useLocale();
  const comboBoxClasses = useCustomizedComboStyles();

  const handleAddOpen = () => {
    setDialogAddOpen(true);
  };

  const handleAddClose = () => {
    setDialogAddOpen(false);
  };

  const variousOperationToCustomComboItem = (variousOperation: VariousOperation) => {
    const label = getVariousOperationLabel(variousOperation, language);

    const type =
      variousOperation.type === VariousOperationType.RECURRING
        ? `${variousOperation.numberOfMonths!} ${formatMessage({ id: `lease.detail.variousOperations.occurrences` })}`
        : formatMessage({ id: `settings.variousOperations.${variousOperation.type}` });
    const frequency = formatMessage({ id: `enums.LeasePaymentFrequency.${variousOperation.paymentFrequency}` });
    return {
      primary: label,
      secondary: `${type} - ${frequency}`,
      value: variousOperation,
    };
  };
  const emptyObject = { primary: '', secondary: '', value: null };
  const selected = getSafeValueInObject(values, fieldName);

  const isService = operationType === LeaseVariousOperationType.SERVICE;

  const variousOperations = allVariousOperations.filter((operation) => operation.operationType === operationType);

  return (
    <Grid
      style={{
        ...style,
      }}
    >
      <CustomInputComboBox
        name={fieldName}
        value={!isNil(selected) && !isEmpty(selected) ? variousOperationToCustomComboItem(selected) : emptyObject}
        label={formatMessage({
          id: `settings.addVariousOperation.predefined${isService ? 'Service' : 'Charge'}Operations`,
        })}
        options={variousOperations.map((vo) => variousOperationToCustomComboItem(vo))}
        fieldToReturn="value"
        dataTest="PredefinedOperations"
        emptyValue={emptyObject}
        inputStyle={{ width: inputWidth, ...inputStyle }}
        renderOption={(value: CustomInputItemType) => {
          return (
            <Grid container alignContent="space-between" style={{ marginBottom: 5, marginTop: 5 }}>
              <Grid item xs={6}>
                <Typography className={comboBoxClasses.primaryText}>{value.primary}</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                {!isNil(value.secondary) && (
                  <Typography className={comboBoxClasses.secondaryText}>{value.secondary}</Typography>
                )}
              </Grid>
            </Grid>
          );
        }}
        renderInput={(value: CustomInputItemType) => {
          return (
            <>
              <Typography
                style={{
                  fontSize: 16,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: `calc(${inputWidth}px - 85px)`,
                }}
              >{`${value.primary} - ${value.secondary}`}</Typography>
            </>
          );
        }}
        inputAdornment={<EditOrAddIconButton isSelected={false} disabled={disabled} onClick={() => handleAddOpen()} />}
        stringToCompare={(value: CustomInputItemType) => `${value.primary} - ${value.secondary}`}
        error={Boolean(errors && getSafeValueInObject(errors, fieldName))}
        noOptionsText={formatMessage({ id: 'comboBox.noVariousOperations' })}
        disabled={disabled}
        onChangeObserver={onChangeObserver}
        {...rest}
      />
      <Dialog
        open={dialogAddOpen}
        onClose={handleAddClose}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { width: 640 } }}
      >
        <AddEditVariousOperation
          afterSubmit={(variousOperation?: VariousOperation) => {
            if (!isNil(variousOperation)) {
              setFieldValue(fieldName, variousOperation);
            }
            if (!isNil(onChangeObserver) && variousOperation) {
              onChangeObserver(variousOperation as unknown as CustomInputItemType, setFieldValue);
            }
            handleAddClose();
          }}
          operationType={operationType}
        />
      </Dialog>
    </Grid>
  );
};

export default SimpleVariousOperationSelector;
