import {
  InvoiceWithPostings,
  LinkType,
  Posting,
  REMITTANCE_TYPE_STRUCTURED,
  RemittanceType,
  TransactionLinkType,
  TransactionStatus,
  encodeStructuredRemittanceInformation,
  getPostingAmount,
  getPostingsWithAmountLeftToPay,
  linkTransactionToInvoice,
  linkTransactionToInvoicePartially,
  saveAccountAndTransactionsWithLink,
} from '@rentguru/commons-utils';
import { FormikHelpers } from 'formik';
import { sumBy } from 'lodash';
import isNil from 'lodash/isNil';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { useContacts } from '../../../hooks/ContactsContext';
import { useLeases } from '../../../hooks/LeasesContext';
import { useTransactions } from '../../../hooks/TransactionsContext';
import { useUnits } from '../../../hooks/UnitsContext';

export interface AddTransactionFormValues {
  linkId: string;
  linkType: LinkType;
  sender: string;
  amount?: number;
  operationDate: Date;
  bankAccountId: string;
  remittanceInformation: string;
  remittanceInformationType: 'structured' | 'unstructured';
  rememberRule: boolean;
  invoiceMatch?: InvoiceWithPostings | null;
}

const getTransactionStatus = (linkType: LinkType, transactionAmount: number, invoice?: InvoiceWithPostings | null) => {
  if (linkType === LinkType.Lease) {
    if (isNil(invoice)) {
      return TransactionStatus.TO_RECONCILE;
    }
    const unpaidPostings = getPostingsWithAmountLeftToPay(invoice.postings, true);
    const postingWithSameAmountList = unpaidPostings.filter((posting) => posting.totalAmount === transactionAmount);
    const hasOneSinglePostingWithSameAmount = postingWithSameAmountList.length === 1;
    const unpaidPostingsTotal = sumBy(unpaidPostings, 'totalAmount');
    if (!hasOneSinglePostingWithSameAmount && unpaidPostingsTotal !== transactionAmount) {
      return TransactionStatus.TO_RECONCILE;
    }
  }
  return TransactionStatus.RECONCILED;
};

export const useTransactionUtils = () => {
  const history = useHistory();
  const { leases, getLease } = useLeases();
  const { getContact } = useContacts();
  const { units } = useUnits();
  const {
    createTransaction,
    createPosting,
    transactionRefetch,
    getAccountLabelForBankAccountOrInsert,
    getAccountLabelForLease,
    getAccountLabelForContactOrInsert,
  } = useTransactions();

  const { updateInvoice } = useInvoices();

  const createNewTransaction = async (
    values: AddTransactionFormValues,
    shouldRedirectToReconciliationScreen: boolean,
    setStatus?: FormikHelpers<AddTransactionFormValues>['setStatus'],
    setSubmitting?: FormikHelpers<AddTransactionFormValues>['setSubmitting']
  ) => {
    const { amount: formAmount, invoiceMatch, linkId, linkType } = values;
    const amount = formAmount ?? 0;
    const amountToSecondDecimal = Math.round(amount * 100) / 100;
    const transactionDate = new Date(values.operationDate).toISOString();
    // Create transaction
    const transaction = {
      statementDate: transactionDate,
      operationDate: transactionDate,
      updatedAt: new Date().toISOString(),
      remittanceInformationType: values.remittanceInformationType as RemittanceType,
      remittanceInformation:
        values.remittanceInformationType === REMITTANCE_TYPE_STRUCTURED
          ? encodeStructuredRemittanceInformation(values.remittanceInformation)
          : values.remittanceInformation !== ''
          ? values.remittanceInformation
          : null,
      amount: amountToSecondDecimal,
      bankAccountId: values.bankAccountId,
      counterpartName: values.sender,
      status: getTransactionStatus(linkType, amountToSecondDecimal, invoiceMatch),
      links: [
        {
          amount,
          linkId,
          linkType: linkType === LinkType.Lease ? TransactionLinkType.LEASE : TransactionLinkType.CONTACT,
        },
      ],
      manualEntry: true,
    };
    const createdTransaction = await createTransaction(transaction);
    let hasBeenReconciled = false;
    if (linkType === LinkType.Contractor) {
      await saveAccountAndTransactionsWithLink(
        createdTransaction,
        [{ type: LinkType.Contractor, id: linkId, amount }],
        units,
        getLease,
        getContact,
        getAccountLabelForBankAccountOrInsert,
        getAccountLabelForLease,
        getAccountLabelForContactOrInsert,
        createPosting
      );
    } else {
      const lease = leases.find((currentLease) => currentLease.id === values.linkId)!;
      if (isNil(invoiceMatch)) {
        // Could not find an exact invoice on the lease => Send the transaction to To_check tab with proposedLease
        if (shouldRedirectToReconciliationScreen) {
          history.push({
            pathname: RouteDestination.RECONCILE_TRANSACTION,
            state: { leaseIds: [lease.id], goBackUrl: RouteDestination.ACCOUNTING },
          });
        }
        return { hasBeenReconciled: false };
      }
      const accountForLease = getAccountLabelForLease();
      const accountForBankAccount = await getAccountLabelForBankAccountOrInsert(createdTransaction.bankAccount!);
      const postingsWithAmountLeftToPay = getPostingsWithAmountLeftToPay(invoiceMatch.postings, false) as Posting[];
      const positiveTotalLeftToPay = postingsWithAmountLeftToPay.reduce(
        (total, posting) => total + getPostingAmount(posting.totalAmount, posting.type),
        0
      );
      const totalLeftToPay = invoiceMatch.creditNote ? -positiveTotalLeftToPay : positiveTotalLeftToPay;
      if (amountToSecondDecimal !== totalLeftToPay) {
        const isReconciled = await linkTransactionToInvoicePartially(
          createdTransaction,
          lease,
          invoiceMatch!,
          invoiceMatch!.postings,
          accountForLease,
          accountForBankAccount,
          createPosting,
          updateInvoice
        );
        hasBeenReconciled = isReconciled ?? false;
        if (!isReconciled && history.location.pathname !== RouteDestination.RECONCILE_TRANSACTION) {
          history.push({
            pathname: RouteDestination.RECONCILE_TRANSACTION,
            state: { leaseIds: [lease.id] },
          });
        }
      } else {
        await linkTransactionToInvoice(
          createdTransaction,
          lease,
          invoiceMatch!,
          invoiceMatch!.postings,
          accountForLease,
          accountForBankAccount,
          createPosting,
          updateInvoice
        );
        hasBeenReconciled = true;
      }
    }

    // Refresh list
    await transactionRefetch();
    if (setStatus) {
      setStatus(true);
    }
    if (setSubmitting) {
      setSubmitting(false);
    }
    return { createdTransaction, hasBeenReconciled };
  };

  return { createNewTransaction };
};
