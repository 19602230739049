import { Typography } from '@material-ui/core';
import {
  formatNumber,
  getTenantsName,
  getTopElementsBasedOnProperty,
  TOP_CREDITORS_BOX_KEY,
  UnitType,
} from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { BoxSwitchFiltersProps } from '../Filters/BoxSwitchFilters';
import RankingBox, { RankingBoxLayoutProps } from './RankingBox';
import RankingKeyNumber from './RankingKeyNumber';

const DashboardRankingReceivables: React.FC<RankingBoxLayoutProps> = ({ forceUpdateLayout }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const {
    switchFilters: { topCreditors: topCreditorsFilter },
  } = useDashboardFilters();
  const { leases, leasesLoading } = useLeases();

  const loading = leasesLoading;

  const switchs: BoxSwitchFiltersProps = {
    name: 'topCreditors',
    filters: [
      {
        label: formatMessage({ id: 'dashboard.rankingFilters.all' }),
        value: 'all',
      },
      {
        label: formatMessage({ id: 'dashboard.rankingFilters.unitType' }),
        menuItems: Object.keys(UnitType).map((unitType) => ({
          label: formatMessage({ id: `enums.UnitType.${unitType}` }),
          value: unitType,
        })),
      },
    ],
  };

  const datas = useMemo(() => {
    let totalReceivables = 0;
    const leasesWithBalance = leases.reduce((result, currentLease) => {
      if (!isNil(currentLease.balance) && currentLease.balance < 0) {
        const unitLease = currentLease.units ? currentLease.units.find((u) => u.unit) : null;
        let addToResult = true;
        if (!isNil(unitLease) && !isNil(topCreditorsFilter) && topCreditorsFilter.value !== 'all') {
          if (topCreditorsFilter.value !== unitLease.unit?.type) {
            addToResult = false;
          }
        }
        if (addToResult) {
          const amount = currentLease.balance * -1;
          totalReceivables += amount;
          result.push({ tenants: getTenantsName(currentLease.tenants), amount });
        }
      }

      return result;
    }, [] as { tenants: string; amount: number }[]);

    const top5 = getTopElementsBasedOnProperty<{ tenants: string; amount: number }>(leasesWithBalance, 'amount', 5);
    return {
      datas: top5.map((tenant) => ({
        name: tenant.tenants,
        primaryText: formatNumber(tenant.amount, language, {
          style: 'currency',
          currency: 'EUR',
        }),
      })),
      totalReceivables,
    };
  }, [topCreditorsFilter, leases, language]);

  return (
    <RankingBox
      title={formatMessage({ id: 'dashboard.topCreditors.title' })}
      infoContent={
        <Typography style={{ fontSize: 12 }}>{formatMessage({ id: 'dashboard.topCreditors.info' })}</Typography>
      }
      filtersBundle={switchs}
      datas={datas.datas}
      loading={loading}
      boxKey={TOP_CREDITORS_BOX_KEY}
      forceUpdateLayout={forceUpdateLayout}
    >
      <RankingKeyNumber
        title={formatMessage({ id: 'total' })}
        value={formatNumber(datas.totalReceivables, language, {
          style: 'currency',
          currency: 'EUR',
        })}
      />
    </RankingBox>
  );
};

export default DashboardRankingReceivables;
