import { Grid, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import {
  Colors,
  formatNumber,
  getStatusForStatusBox,
  LeasePriceHistory,
  LeasePriceHistoryType,
} from '@rentguru/commons-utils';
import { format, setYear } from 'date-fns';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import IndexationStatusBox from 'src/components/Leases/Details/IndexationStatusBox';
import { RouteDestination } from 'src/components/Routes/Routes';
import EllispsissedTypography from 'src/components/ui/EllispsissedTypography';
import { useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as ArrowRight } from 'src/icons/small-arrow-right.svg';
import { ROW_HEIGHT } from '../DashboardLeaseIndexations';

interface LeaseIndexationRowProps {
  leasePriceHistory: LeasePriceHistory;
}

const useLocalStyles = makeStyles({
  amountBox: {
    borderRadius: 4,
    fontWeight: 'bold',
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 4,
    paddingRight: 4,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    overflow: 'hidden',
  },
});

const LeaseIndexationRow: React.FC<LeaseIndexationRowProps> = ({ leasePriceHistory }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { getLease } = useLeases();
  const { getUnit } = useUnits();
  const { amountBox } = useLocalStyles();
  const { push: historyPush } = useHistory();
  const lease = getLease(leasePriceHistory.lease!.id);
  if (isNil(lease)) {
    return (
      <TableRow style={{ minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
        <TableCell>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </TableCell>
      </TableRow>
    );
  }
  const leaseUnit = getUnit(lease.units![0].unit!.id);
  if (isNil(leaseUnit)) {
    return (
      <TableRow style={{ minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
        <TableCell>
          <Typography>{formatMessage({ id: 'loading' })}</Typography>
        </TableCell>
      </TableRow>
    );
  }
  const leaseStartDate = new Date(lease.startDate);
  const leaseAnniversary = setYear(leaseStartDate, new Date().getFullYear());
  return (
    <TableRow
      hover
      onClick={() => historyPush(RouteDestination.INDEXATION, { id: leasePriceHistory.id })}
      style={{ cursor: 'pointer', minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}
    >
      <TableCell>
        <EllispsissedTypography width="150px" textStyle={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {lease.name}
        </EllispsissedTypography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {formatMessage({ id: `lease.detail.indexationTypes.${leasePriceHistory.type}` })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 14, fontWeight: 500 }}>
          {format(leaseAnniversary, 'MMMM yyyy', {
            locale: (dateLocaleMap as dateLocaleMapType)[language],
          })}
        </Typography>
      </TableCell>
      <TableCell align="left" padding="none">
        <IndexationStatusBox
          status={getStatusForStatusBox(leasePriceHistory)}
          type={leasePriceHistory.type as LeasePriceHistoryType}
        />
      </TableCell>
      <TableCell padding="none" align="center">
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid
            className={amountBox}
            style={{
              border: `1px solid ${Colors.BLUE_GREY}`,
              color: Colors.BLUE_GREY,
            }}
          >
            {formatNumber(lease.totalMonthlyCharges, language, { style: 'currency', currency: 'EUR' })}
          </Grid>
          <ArrowRight />
          <Grid
            className={amountBox}
            style={{
              border: `1px solid ${Colors.TURQUOISE_BLUE}`,
              color: Colors.TURQUOISE_BLUE,
            }}
          >
            {formatNumber(leasePriceHistory.totalMonthlyCharges, language, { style: 'currency', currency: 'EUR' })}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left" padding="none">
        {leasePriceHistory.type === LeasePriceHistoryType.INDEXATION && (
          <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid
              className={amountBox}
              style={{
                border: `1px solid ${Colors.BLUE_GREY}`,
                color: Colors.BLUE_GREY,
              }}
            >
              {formatNumber(lease.totalRentalPrice, language, { style: 'currency', currency: 'EUR' })}
            </Grid>
            <ArrowRight />
            <Grid
              className={amountBox}
              style={{
                border: `1px solid ${Colors.TURQUOISE_BLUE}`,
                color: Colors.TURQUOISE_BLUE,
              }}
            >
              {formatNumber(leasePriceHistory.totalRentalPrice, language, { style: 'currency', currency: 'EUR' })}
            </Grid>
          </Grid>
        )}
      </TableCell>
    </TableRow>
  );
};

export default LeaseIndexationRow;
