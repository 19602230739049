import { Grid, Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { Colors, Lease } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { CommunicationSettingsButton } from './CommunicationSettingsButton';

interface CommunicationSettingsInfoRemarkProps {
  iconnedRemarkStyle: CSSProperties;
  lease: Lease;
  showButton?: boolean;
}

export const CommunicationSettingsInfoRemark: React.FC<CommunicationSettingsInfoRemarkProps> = ({
  lease,
  iconnedRemarkStyle,
  showButton = true,
}) => {
  const { formatMessage } = useIntl();

  return (
    <IconnedRemark
      Icon={InfoSvg}
      noFixWidth
      style={iconnedRemarkStyle}
      message={
        <Grid style={showButton ? { display: 'flex', alignItems: 'center', gap: 15 } : {}}>
          <Typography
            style={{
              fontSize: 14,
              color: Colors.SLATE_GREY,
            }}
          >
            {formatMessage({ id: 'settings.addVariousOperation.confirmationCommunication' })}
          </Typography>
          {showButton && <CommunicationSettingsButton lease={lease} />}
        </Grid>
      }
    />
  );
};
