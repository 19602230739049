/* eslint-disable default-case */
import { Grid, ListItem, Typography } from '@material-ui/core';
import { Colors, CustomSubscription, getLocaleFromLanguage, SubscriptionError } from '@rentguru/commons-utils';
import { formatDistanceToNow } from 'date-fns/esm';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { SettingsRouteDestination } from 'src/components/Settings/Settings';
import { SUBSCRIPTION_PLAN_TAB_ROUTE } from 'src/components/Settings/SubscriptionSettings';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ReactComponent as SettingsIcon } from 'src/icons/lease-action-charge.svg';
import { useNotificationListStyle } from './NotificationsList';

export interface NotificationsSubscriptionItemProps {
  name: SubscriptionError;
  customSubscription: CustomSubscription;
}

const NotificationsSubscriptionItem: React.FC<NotificationsSubscriptionItemProps> = ({ name, customSubscription }) => {
  const { formatMessage } = useIntl();
  const localStyles = useNotificationListStyle();
  const { language } = useUser();
  const { deleteCustomSubscription } = useCustomSubscriptions();
  const { settingsManagementWrite } = usePermissions();

  const isAccountDisabling = name === SubscriptionError.ACCOUNT_DISABLING;

  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <SettingsIcon
              style={{
                transform: 'scale(0.6)',
              }}
            />

            <Typography
              variant="h6"
              style={{
                color: isAccountDisabling ? Colors.CARNATION_RED : Colors.CLASSICAL_BLACK,
                textJustify: 'inter-word',
              }}
            >
              {formatMessage({
                id: `settings.subscription.name`,
              })}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscription(customSubscription);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(language || 'en'),
            })}
          </Typography>
        )}
        <Grid style={{ color: Colors.SLATE_GREY }}>
          <Typography>
            {formatMessage({ id: `settings.subscription.notification.${name}` })}
            {settingsManagementWrite && (
              <Link
                // eslint-disable-next-line max-len
                to={`${RouteDestination.SETTINGS}/${SettingsRouteDestination.SUBSCRIPTION_ROUTE}/${SUBSCRIPTION_PLAN_TAB_ROUTE}`}
                style={{ color: Colors.DODGER_BLUE, textDecoration: 'underline', cursor: 'pointer' }}
              >
                {formatMessage({ id: `settings.subscription.notification.link` })}
              </Link>
            )}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default NotificationsSubscriptionItem;
