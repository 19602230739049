// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AccountLabelCustomUsage = {
  "INDEXATION_REGULARIZATION": "INDEXATION_REGULARIZATION"
};

const AccountType = {
  "OWNER": "OWNER",
  "AGENCY": "AGENCY",
  "FUND": "FUND"
};

const AddressStatus = {
  "NEW": "NEW",
  "DRAFT_OCR": "DRAFT_OCR",
  "ACTIVE_GEOCODED": "ACTIVE_GEOCODED",
  "ERROR": "ERROR"
};

const AdvertisementPublishingPlatformActions = {
  "PUBLISH": "PUBLISH",
  "DELETE": "DELETE",
  "UPDATE": "UPDATE"
};

const AdvertisementUnitType = {
  "APARTMENT": "APARTMENT",
  "HOUSE": "HOUSE",
  "LAND": "LAND",
  "COMMERCIAL": "COMMERCIAL",
  "GARAGEPARKING": "GARAGEPARKING",
  "SPECIAL": "SPECIAL"
};

const AdvertisementUnitSubType = {
  "STUDIO": "STUDIO",
  "DUPLEX": "DUPLEX",
  "TRIPLEX": "TRIPLEX",
  "PENTHOUSE": "PENTHOUSE",
  "GROUNDFLOORFLAT": "GROUNDFLOORFLAT",
  "LOFT": "LOFT",
  "STUDENTFLAT": "STUDENTFLAT",
  "BUILDING": "BUILDING",
  "FLAT": "FLAT",
  "APARTMENTSTANDARD": "APARTMENTSTANDARD",
  "VILLA": "VILLA",
  "TERRACED": "TERRACED",
  "FARM": "FARM",
  "MANSION": "MANSION",
  "COTTAGE": "COTTAGE",
  "BUNGALOW": "BUNGALOW",
  "CASTLE": "CASTLE",
  "DETACHED": "DETACHED",
  "SEMIDETACHED": "SEMIDETACHED",
  "KANGAROOHOUSE": "KANGAROOHOUSE",
  "PILEHOUSE": "PILEHOUSE",
  "HOUSEBOAT": "HOUSEBOAT",
  "MOBILEHOME": "MOBILEHOME",
  "MIXED": "MIXED",
  "BUILDINGLAND": "BUILDINGLAND",
  "MEADOW": "MEADOW",
  "WOOD": "WOOD",
  "INDUSTRIALLAND": "INDUSTRIALLAND",
  "FARMLAND": "FARMLAND",
  "OFFICE": "OFFICE",
  "OFFICEBUILDING": "OFFICEBUILDING",
  "WAREHOUSE": "WAREHOUSE",
  "SHOP": "SHOP",
  "RESTAURANT": "RESTAURANT",
  "BAR": "BAR",
  "HOTEL": "HOTEL",
  "RETAILSPACE": "RETAILSPACE",
  "INDUSTRIAL": "INDUSTRIAL",
  "BUSINESS": "BUSINESS",
  "OPENAIRSPACE": "OPENAIRSPACE",
  "OUTSIDEBOX": "OUTSIDEBOX",
  "INSIDEBOX": "INSIDEBOX",
  "COVEREDSPACE": "COVEREDSPACE",
  "CARPARK": "CARPARK",
  "INDOORSPACE": "INDOORSPACE"
};

const AgencyRateAmountDetailType = {
  "PRICE": "PRICE",
  "PERCENTAGE": "PERCENTAGE"
};

const AgencyRateFeesType = {
  "AMOUNT_COLLECTED": "AMOUNT_COLLECTED",
  "EXPECTED_RENT": "EXPECTED_RENT"
};

const AgencyRateIncludedAmount = {
  "RENT_WITHOUT_CHARGES": "RENT_WITHOUT_CHARGES",
  "PROVISIONED_CHARGES": "PROVISIONED_CHARGES",
  "FIXED_CHARGES": "FIXED_CHARGES",
  "RENTAL_DISCOUNT": "RENTAL_DISCOUNT",
  "INDEXED_PRICE": "INDEXED_PRICE",
  "VARIOUS_OPERATION_CHARGE": "VARIOUS_OPERATION_CHARGE",
  "VARIOUS_OPERATION_SERVICE": "VARIOUS_OPERATION_SERVICE"
};

const AgencyRateRepartitionType = {
  "BY_UNIT": "BY_UNIT",
  "ON_PROPERTY": "ON_PROPERTY"
};

const AgencyRateType = {
  "FIXED": "FIXED",
  "DECREASING": "DECREASING"
};

const AmenitiesType = {
  "ALARM": "ALARM",
  "ARMOURED_DOOR": "ARMOURED_DOOR",
  "DOOR_PHONE": "DOOR_PHONE",
  "FIRE_PLACE": "FIRE_PLACE",
  "JACUZZI": "JACUZZI",
  "LARGE_PETS_ALLOWED": "LARGE_PETS_ALLOWED",
  "SAUNA": "SAUNA",
  "SMALL_PETS_ALLOWED": "SMALL_PETS_ALLOWED",
  "TV_CONNECTION": "TV_CONNECTION"
};

const BatchChangeCommunicationsAction = {
  "SEND": "SEND",
  "RESEND": "RESEND",
  "DELETE": "DELETE"
};

const BounceType = {
  "MAILBOX_DOES_NOT_EXIST": "MAILBOX_DOES_NOT_EXIST",
  "MESSAGE_TOO_LARGE": "MESSAGE_TOO_LARGE",
  "MAILBOX_FULL": "MAILBOX_FULL",
  "CONTENT_NOT_ACCEPTED": "CONTENT_NOT_ACCEPTED",
  "UNKNOWN": "UNKNOWN",
  "TEMPORARY_FAILURE": "TEMPORARY_FAILURE"
};

const BuildingState = {
  "VERY_GOOD": "VERY_GOOD",
  "GOOD": "GOOD",
  "BAD": "BAD",
  "VERY_BAD": "VERY_BAD"
};

const CertificateOfConformityType = {
  "YES_CONFORM": "YES_CONFORM",
  "YES_NOT_CONFORM": "YES_NOT_CONFORM",
  "NO": "NO"
};

const ChargeSource = {
  "MAIL": "MAIL",
  "WEB": "WEB",
  "MANUAL": "MANUAL"
};

const ChargeStatus = {
  "TO_CHECK": "TO_CHECK",
  "TO_AFFECT": "TO_AFFECT",
  "AFFECTED": "AFFECTED"
};

const CivilityType = {
  "MAN": "MAN",
  "WOMAN": "WOMAN",
  "OTHER": "OTHER"
};

const CommunicationChannel = {
  "EMAIL": "EMAIL",
  "IN_APP": "IN_APP",
  "LETTER": "LETTER",
  "REGISTERED_LETTER": "REGISTERED_LETTER",
  "SMS": "SMS",
  "WHATSAPP": "WHATSAPP"
};

const CommunicationFrequencySettingPeriodDateFrom = {
  "ANNIVERSARY": "ANNIVERSARY",
  "MAINTENANCE_DAY": "MAINTENANCE_DAY"
};

const CommunicationFrequencySettingPeriodType = {
  "DAYS": "DAYS",
  "WEEKS": "WEEKS",
  "MONTHS": "MONTHS",
  "YEARS": "YEARS"
};

const CommunicationSendingRule = {
  "AUTOMATIC": "AUTOMATIC",
  "DISABLE": "DISABLE",
  "MANUAL": "MANUAL"
};

const CommunicationSettingsProfileReferent = {
  "LEASE": "LEASE",
  "OWNER": "OWNER",
  "TECHNIC": "TECHNIC"
};

const CommunicationSettingRecipient = {
  "GUARANTOR": "GUARANTOR",
  "OWNER": "OWNER",
  "TENANT": "TENANT"
};

const CommunicationStatus = {
  "TO_SEND": "TO_SEND",
  "SENT": "SENT",
  "SNOOZED": "SNOOZED",
  "TO_DELETE": "TO_DELETE",
  "BOUNCE_ERROR": "BOUNCE_ERROR",
  "CREDIT_ERROR": "CREDIT_ERROR",
  "OUTDATED": "OUTDATED"
};

const CommunicationTimeFrameSetting = {
  "MORNING": "MORNING",
  "AFTERNOON": "AFTERNOON",
  "EVENING": "EVENING",
  "NIGHT": "NIGHT"
};

const CommunicationType = {
  "CUSTOM": "CUSTOM",
  "DOCUMENT_SIGNATURE_REMINDER": "DOCUMENT_SIGNATURE_REMINDER",
  "DOCUMENT_SIGNED": "DOCUMENT_SIGNED",
  "LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON": "LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON",
  "LEASE_BANKING_INFO_UPDATE": "LEASE_BANKING_INFO_UPDATE",
  "LEASE_CHARGES_ADJUSTMENT": "LEASE_CHARGES_ADJUSTMENT",
  "LEASE_CONTRACT_EXTENDED": "LEASE_CONTRACT_EXTENDED",
  "LEASE_CONTRACT_REJECTED": "LEASE_CONTRACT_REJECTED",
  "LEASE_CONTRACT_RETRACTED": "LEASE_CONTRACT_RETRACTED",
  "LEASE_CONTRACT_REVOKED": "LEASE_CONTRACT_REVOKED",
  "LEASE_CONTRACT_SIGNATURE_REMINDER": "LEASE_CONTRACT_SIGNATURE_REMINDER",
  "LEASE_CONTRACT_SIGNED": "LEASE_CONTRACT_SIGNED",
  "LEASE_CREDIT_NOTE": "LEASE_CREDIT_NOTE",
  "LEASE_DISCOUNT_CREATED": "LEASE_DISCOUNT_CREATED",
  "LEASE_DISCOUNT_DELETED": "LEASE_DISCOUNT_DELETED",
  "LEASE_DISCOUNT_UPDATED": "LEASE_DISCOUNT_UPDATED",
  "LEASE_OPERATION_CREATED": "LEASE_OPERATION_CREATED",
  "LEASE_OPERATION_DELETED": "LEASE_OPERATION_DELETED",
  "LEASE_OPERATION_PAYMENT_REQUEST": "LEASE_OPERATION_PAYMENT_REQUEST",
  "LEASE_OPERATION_UPDATED": "LEASE_OPERATION_UPDATED",
  "LEASE_PAYMENT_RECEIPT": "LEASE_PAYMENT_RECEIPT",
  "LEASE_PAYMENT_REQUEST": "LEASE_PAYMENT_REQUEST",
  "LEASE_RENT_INDEXATION": "LEASE_RENT_INDEXATION",
  "LEASE_RENT_INDEXATION_SKIPPED": "LEASE_RENT_INDEXATION_SKIPPED",
  "LEASE_RENTAL_ADAPTATION": "LEASE_RENTAL_ADAPTATION",
  "LEASE_TENANT_STATEMENT": "LEASE_TENANT_STATEMENT",
  "LEASE_TERMINATION": "LEASE_TERMINATION",
  "LEASE_UNPAID_AMOUNT_FORMAL_NOTICE": "LEASE_UNPAID_AMOUNT_FORMAL_NOTICE",
  "LEASE_UNPAID_AMOUNT_REMINDER": "LEASE_UNPAID_AMOUNT_REMINDER",
  "LEASE_UNPAID_AMOUNT_SECOND_REMINDER": "LEASE_UNPAID_AMOUNT_SECOND_REMINDER",
  "OWNER_STATEMENT": "OWNER_STATEMENT",
  "SYSTEM": "SYSTEM"
};

const ConnectionPlugsType = {
  "TELEVISION": "TELEVISION",
  "INTERNET": "INTERNET",
  "TELEPHONE": "TELEPHONE"
};

const ContactStatus = {
  "DRAFT_OCR": "DRAFT_OCR",
  "ARCHIVED": "ARCHIVED",
  "ERROR": "ERROR"
};

const ContactType = {
  "OWNER": "OWNER",
  "TENANT": "TENANT",
  "GUARANTOR": "GUARANTOR",
  "MEMBER": "MEMBER",
  "CONTRACTOR": "CONTRACTOR",
  "JOINT_OWNERSHIP": "JOINT_OWNERSHIP",
  "CLIENT": "CLIENT"
};

const CustomFieldType = {
  "STRING": "STRING",
  "NUMBER": "NUMBER",
  "FLOAT": "FLOAT",
  "DATE": "DATE",
  "BOOLEAN": "BOOLEAN"
};

const CustomSubscriptionType = {
  "BLOCKED_COMMUNICATIONS": "BLOCKED_COMMUNICATIONS",
  "COMMUNICATION": "COMMUNICATION",
  "CREDITS": "CREDITS",
  "ENTITY_DELETION": "ENTITY_DELETION",
  "INTEGRATION": "INTEGRATION",
  "OCR_SUCCESS": "OCR_SUCCESS",
  "OCR_FAILURE": "OCR_FAILURE",
  "REFETCH": "REFETCH",
  "REPORT": "REPORT",
  "SENDING_SOURCE": "SENDING_SOURCE",
  "SIGNATURE_DOCUMENT": "SIGNATURE_DOCUMENT",
  "SUBSCRIPTION": "SUBSCRIPTION",
  "TACIT_RENEWAL": "TACIT_RENEWAL",
  "TACIT_WARNING": "TACIT_WARNING"
};

const DeepDeletableEntityType = {
  "BUILDING": "BUILDING",
  "CONTACT": "CONTACT",
  "LEASE": "LEASE",
  "UNIT": "UNIT"
};

const DelimitedZoneType = {
  "DELIMITED_FLOOD_ZONE": "DELIMITED_FLOOD_ZONE",
  "DELIMITED_REPARIAN_ZONE": "DELIMITED_REPARIAN_ZONE",
  "NOT_COMMUNICATED": "NOT_COMMUNICATED"
};

const DetectorType = {
  "CO2": "CO2",
  "SMOKE": "SMOKE"
};

const DocumentType = {
  "ENTRANCE_FURNITURE": "EntranceFurniture",
  "ENTRANCE_INVENTORY": "EntranceInventory",
  "EXIT_FURNITURE": "ExitFurniture",
  "EXIT_INVENTORY": "ExitInventory",
  "INVOICE": "Invoice",
  "LEASE": "Lease",
  "STATEMENT_DETAIL": "StatementDetail",
  "STATEMENT_RECEIPT": "StatementReceipt",
  "LEASE_PRICE_HISTORY_INDEXATION_ACCEPTED": "LeasePriceHistoryIndexationAccepted",
  "LEASE_TERMINATION_FROM_LESSOR": "LeaseTerminationFromLessor",
  "RECEIPT": "Receipt",
  "LETTER": "Letter"
};

const FileCategory = {
  "LEASE_CONTRACT": "LEASE_CONTRACT",
  "LEASE_CONTRACT_END": "LEASE_CONTRACT_END",
  "LEASE_INVENTORY_CONTRACT": "LEASE_INVENTORY_CONTRACT",
  "CHARGE_INVOICE": "CHARGE_INVOICE",
  "INVOICE": "INVOICE",
  "PEB": "PEB",
  "CO_OWNERSHIP_REGULATIONS": "CO_OWNERSHIP_REGULATIONS",
  "RULES_OF_PROCEDURE": "RULES_OF_PROCEDURE",
  "HYDROCARBONTANK_CERTIFICATE": "HYDROCARBONTANK_CERTIFICATE",
  "FURNITURE_INVENTORY": "FURNITURE_INVENTORY",
  "HEATING": "HEATING",
  "CHIMNEY": "CHIMNEY",
  "UTILITY": "UTILITY",
  "DETECTOR": "DETECTOR",
  "INVENTORY_OF_FIXTURES": "INVENTORY_OF_FIXTURES",
  "COMMUNICATION": "COMMUNICATION",
  "ADVERTISEMENT_PICTURE": "ADVERTISEMENT_PICTURE",
  "CLIENT_LOGO": "CLIENT_LOGO",
  "BASIC_DEED": "BASIC_DEED",
  "CONTRACT": "CONTRACT",
  "STATEMENT_DETAIL": "STATEMENT_DETAIL",
  "STATEMENT_RECEIPT": "STATEMENT_RECEIPT",
  "CONTRACT_AMENDMENT": "CONTRACT_AMENDMENT",
  "LEASE_PRICE_HISTORY_DETAIL": "LEASE_PRICE_HISTORY_DETAIL",
  "CUSTOM": "CUSTOM",
  "INVOICE_RECEIPT": "INVOICE_RECEIPT",
  "TICKET": "TICKET",
  "LEASE_INVENTORY_ENCODING": "LEASE_INVENTORY_ENCODING",
  "LETTER": "LETTER",
  "TEMPORARY": "TEMPORARY"
};

const FloodProneAreaType = {
  "RECOGNIZED_FLOOD_ZONE": "RECOGNIZED_FLOOD_ZONE",
  "POTENTIAL_FLOOD_ZONE": "POTENTIAL_FLOOD_ZONE",
  "NON_FLOODABLE_ZONE": "NON_FLOODABLE_ZONE",
  "NOT_COMMUNICATED": "NOT_COMMUNICATED"
};

const GenerateReportErrorCode = {
  "WRONG_PARAMS": "WRONG_PARAMS",
  "WRONG_FORMAT": "WRONG_FORMAT",
  "UNAUTHORIZED": "UNAUTHORIZED",
  "GENERATION_ERROR": "GENERATION_ERROR"
};

const GenerateReportOutputFormat = {
  "PDF": "PDF",
  "CSV": "CSV"
};

const GenerateReportOutputResult = {
  "CUSTOM_SUBSCRIPTION": "CUSTOM_SUBSCRIPTION",
  "EMAIL": "EMAIL"
};

const GenerateReportType = {
  "OPERATIONAL_PERFORMANCE": "OPERATIONAL_PERFORMANCE",
  "CHARGES": "CHARGES",
  "TURNOVER": "TURNOVER",
  "FEES": "FEES",
  "TENANTS_AGING_BALANCE": "TENANTS_AGING_BALANCE",
  "SUPPLIER_DEBTS": "SUPPLIER_DEBTS"
};

const HeatingAmenities = {
  "COLLECTIVE_HEATING": "COLLECTIVE_HEATING",
  "COLLECTIVE_WATER": "COLLECTIVE_WATER",
  "SIMPLE_GLAZING": "SIMPLE_GLAZING",
  "DOUBLE_GLAZING": "DOUBLE_GLAZING",
  "TRIPLE_GLAZING": "TRIPLE_GLAZING",
  "QUADRUPLE_GLAZING": "QUADRUPLE_GLAZING",
  "AIR_CONDITIONNING": "AIR_CONDITIONNING"
};

const HeatingType = {
  "GAS": "GAS",
  "FUEL_OIL": "FUEL_OIL",
  "ELECTRICITY": "ELECTRICITY",
  "HEATING_PUMP": "HEATING_PUMP",
  "WOOD": "WOOD",
  "GEOTHERMAL": "GEOTHERMAL",
  "SOLAR": "SOLAR",
  "WIND": "WIND"
};

const IntegrationName = {
  "SAGE_BOB50": "SAGE_BOB50",
  "PONTO": "PONTO",
  "MYBANX": "MYBANX",
  "OCR": "OCR"
};

const IntegrationStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "ERROR": "ERROR"
};

const InventoryItemType = {
  "COLOR": "COLOR",
  "MATERIAL": "MATERIAL",
  "DEFECT": "DEFECT"
};

const InventoryOfFixturesMethod = {
  "EXPERT": "EXPERT",
  "BETWEEN_PARTIES": "BETWEEN_PARTIES"
};

const InventoryOfFixturesType = {
  "FIRST_MONTH": "FIRST_MONTH",
  "BEFORE_OCCUPATION": "BEFORE_OCCUPATION"
};

const InviteContactToClientAccountType = {
  "SIGN_LEASE": "SIGN_LEASE",
  "SIGN_DOCUMENT": "SIGN_DOCUMENT",
  "INVITE_TENANT": "INVITE_TENANT",
  "INVITE_OWNER": "INVITE_OWNER"
};

const InvoiceType = {
  "RENT": "RENT",
  "STATEMENT": "STATEMENT",
  "LEASE_VARIOUS_OPERATION": "LEASE_VARIOUS_OPERATION"
};

const KitchenEquipment = {
  "FULLY_EQUIPPED": "FULLY_EQUIPPED",
  "EQUIPPED": "EQUIPPED",
  "PARTIALLY_EQUIPPED": "PARTIALLY_EQUIPPED",
  "UNEQUIPPED": "UNEQUIPPED",
  "AMERICAN_FULLY_EQUIPPED": "AMERICAN_FULLY_EQUIPPED",
  "AMERICAN_EQUIPPED": "AMERICAN_EQUIPPED",
  "AMERICAN_PARTIALLY_EQUIPPED": "AMERICAN_PARTIALLY_EQUIPPED",
  "AMERICAN_UNEQUIPPED": "AMERICAN_UNEQUIPPED"
};

const LandTerrainType = {
  "STREET_FRONT_LAND": "STREET_FRONT_LAND",
  "STREET_BACK_LAND": "STREET_BACK_LAND",
  "FLAT_LAND": "FLAT_LAND"
};

const Language = {
  "FR": "FR",
  "NL": "NL",
  "EN": "EN"
};

const LeaseAction = {
  "EXTEND_LEASE": "EXTEND_LEASE",
  "END_LEASE": "END_LEASE",
  "ADD_UNIT": "ADD_UNIT",
  "CANCELED_END_LEASE": "CANCELED_END_LEASE",
  "REMOVE_UNIT": "REMOVE_UNIT",
  "INITIAL_DATE": "INITIAL_DATE"
};

const LeaseActionEndInitiator = {
  "LESSOR": "LESSOR",
  "TENANT": "TENANT",
  "MUTUAL": "MUTUAL",
  "COURT_DECISION": "COURT_DECISION",
  "NOTARIAL_ACT": "NOTARIAL_ACT"
};

const LeaseActionEndReason = {
  "OWN_USE": "OWN_USE",
  "RENOVATION": "RENOVATION",
  "NONE": "NONE"
};

const LeaseActionHistoryStatus = {
  "TO_PROCESS": "TO_PROCESS",
  "PROCESSED": "PROCESSED"
};

const LeaseAmountUpdateStatus = {
  "DRAFT": "DRAFT",
  "TO_PROCESS_CLIENT_ACCEPTED": "TO_PROCESS_CLIENT_ACCEPTED",
  "TO_PROCESS_CLIENT_REFUSED": "TO_PROCESS_CLIENT_REFUSED",
  "ACCEPTED_PROCESSED_NEED_TO_APPLY": "ACCEPTED_PROCESSED_NEED_TO_APPLY",
  "APPLIED": "APPLIED",
  "REFUSED": "REFUSED",
  "OUTDATED": "OUTDATED",
  "ERROR": "ERROR"
};

const LeasePriceHistoryType = {
  "INDEXATION": "INDEXATION",
  "ADJUSTMENT": "ADJUSTMENT",
  "INITIAL_PRICE": "INITIAL_PRICE",
  "TERMS_CHANGE": "TERMS_CHANGE"
};

const LeasePriceHistoryStatus = {
  "INDEXED": "INDEXED",
  "NON_INDEXED": "NON_INDEXED",
  "INDEXED_WITH_CUSTOM_AMOUNT": "INDEXED_WITH_CUSTOM_AMOUNT",
  "NOT_APPLICABLE": "NOT_APPLICABLE"
};

const LeaseInventoryFurnitureExitIssueAction = {
  "TO_BE_REPARED": "TO_BE_REPARED",
  "TO_BE_REPLACED": "TO_BE_REPLACED",
  "NOTHING_TO_DO": "NOTHING_TO_DO"
};

const LeaseInventoryFurnitureExitIssueType = {
  "MISSING": "MISSING",
  "DAMAGED": "DAMAGED",
  "REPLACED": "REPLACED",
  "OUT_OF_ORDER": "OUT_OF_ORDER"
};

const LeaseInventoryUnitKeyType = {
  "APARTMENT_KEY": "APARTMENT_KEY",
  "BUILDING_KEY": "BUILDING_KEY",
  "HOUSE_KEY": "HOUSE_KEY",
  "BASEMENT_KEY": "BASEMENT_KEY",
  "ROOM_KEY": "ROOM_KEY",
  "APARTMENT_BADGE": "APARTMENT_BADGE",
  "BUILDING_BADGE": "BUILDING_BADGE",
  "HOUSE_BADGE": "HOUSE_BADGE",
  "BASEMENT_BADGE": "BASEMENT_BADGE",
  "ROOM_BADGE": "ROOM_BADGE"
};

const LeaseInventoryStructureStateType = {
  "VERY_GOOD": "VERY_GOOD",
  "GOOD": "GOOD",
  "BAD": "BAD",
  "VERY_BAD": "VERY_BAD"
};

const LeaseInventoryType = {
  "FIXTURES_ENTRANCE": "FIXTURES_ENTRANCE",
  "FIXTURES_EXIT": "FIXTURES_EXIT",
  "FURNITURES_ENTRANCE": "FURNITURES_ENTRANCE",
  "FURNITURES_EXIT": "FURNITURES_EXIT"
};

const LeaseMonthlyChargesType = {
  "FIXED_PRICE": "FixedPrice",
  "MONTHLY_PROVISIONED": "MonthlyProvisioned"
};

const LeasePaymentFrequency = {
  "MONTHLY": "MONTHLY",
  "BI_MONTHLY": "BI_MONTHLY",
  "QUARTERLY": "QUARTERLY",
  "BI_ANNUAL": "BI_ANNUAL",
  "YEARLY": "YEARLY"
};

const LeasePaymentInvoicePeriod = {
  "CONTRACT_PERIOD": "CONTRACT_PERIOD",
  "CALENDAR_PERIOD": "CALENDAR_PERIOD",
  "CUSTOM_PERIOD": "CUSTOM_PERIOD"
};

const LeaseRentalGuarantyType = {
  "CASH": "CASH",
  "BANK": "BANK",
  "CPAS": "CPAS",
  "BANK_BLOCKED": "BANK_BLOCKED"
};

const LeaseStatus = {
  "DRAFT": "Draft",
  "OUT_FOR_SIGNATURE": "OutForSignature",
  "ACTIVE": "Active",
  "REJECTED": "Rejected",
  "ENDED": "Ended",
  "CANCELLED": "Cancelled"
};

const LeaseType = {
  "MAIN_RESIDENCE_9_YEARS": "MAIN_RESIDENCE_9_YEARS",
  "MAIN_RESIDENCE_SHORT": "MAIN_RESIDENCE_SHORT",
  "MAIN_RESIDENCE_LONG": "MAIN_RESIDENCE_LONG",
  "MAIN_RESIDENCE_INFINITE": "MAIN_RESIDENCE_INFINITE",
  "COMMERCIAL": "COMMERCIAL",
  "STUDENT": "STUDENT",
  "FLATSHARING": "FLATSHARING",
  "PARKING": "PARKING"
};

const LeaseVariousOperationType = {
  "DISCOUNT": "DISCOUNT",
  "CHARGE": "CHARGE",
  "SERVICE": "SERVICE"
};

const MortgageRateType = {
  "FIXED": "Fixed",
  "VARIABLE": "Variable"
};

const MutationUserInputUpdatedBy = {
  "OWNER": "OWNER"
};

const NearbyCommoditiesSubTypes = {
  "SCHOOL": "SCHOOL",
  "PRIMARY_SCHOOL": "PRIMARY_SCHOOL",
  "SECONDARY_SCHOOL": "SECONDARY_SCHOOL",
  "UNIVERSITY": "UNIVERSITY",
  "BUS_STATION": "BUS_STATION",
  "SUBWAY_STATION": "SUBWAY_STATION",
  "TRAIN_STATION": "TRAIN_STATION",
  "LIGHT_RAIL_STATION": "LIGHT_RAIL_STATION",
  "SUPERMARKET": "SUPERMARKET",
  "RESTAURANT": "RESTAURANT"
};

const NearbyCommoditiesTypes = {
  "RESTAURANT": "RESTAURANT",
  "SUPERMARKET": "SUPERMARKET",
  "TRANSPORTS": "TRANSPORTS",
  "SCHOOL": "SCHOOL",
  "HEALTHCARE": "HEALTHCARE"
};

const NotificationCreation = {
  "AUTOMATIC": "Automatic",
  "MANUAL": "Manual"
};

const NotificationStatus = {
  "ACTIVE": "Active",
  "SNOOZED": "Snoozed",
  "IGNORED": "Ignored",
  "DONE": "Done"
};

const NotificationType = {
  "PEB_CERTIFICATE": "PebCertificate",
  "SMOKE_DETECTOR_BATTERY": "SmokeDetectorBattery",
  "FUEL_TANK_CERTIFICATE": "FuelTankCertificate",
  "HEATING_CERTIFICATE": "HeatingCertificate",
  "CHIMNEY_SWEEPING": "ChimneySweeping",
  "CUSTOM": "Custom"
};

const PostingChargeType = {
  "OWNER": "OWNER",
  "TENANT": "TENANT",
  "OWNER_VACANCY": "OWNER_VACANCY"
};

const PostingForeignType = {
  "TRANSACTION_RECONCILIATION": "TRANSACTION_RECONCILIATION"
};

const PostingStatus = {
  "PAID_BY_OWNER": "PAID_BY_OWNER",
  "TO_BE_PAID_BY_TENANT": "TO_BE_PAID_BY_TENANT",
  "TO_BE_PAID_BY_OWNER": "TO_BE_PAID_BY_OWNER"
};

const PostingType = {
  "CREDIT": "CREDIT",
  "DEBIT": "DEBIT"
};

const PublishingPlatform = {
  "LOGIC_IMMO": "LOGIC_IMMO"
};

const RemittanceType = {
  "STRUCTURED": "structured",
  "UNSTRUCTURED": "unstructured"
};

const RepartitionKeyType = {
  "CONSUMPTION_BASED_ON_SHARES": "CONSUMPTION_BASED_ON_SHARES",
  "CONSUMPTION_BASED_ON_USAGE": "CONSUMPTION_BASED_ON_USAGE",
  "SHARES": "SHARES",
  "SURFACE": "SURFACE",
  "CUSTOM": "CUSTOM"
};

const RepaymentStatementDetailType = {
  "EXPECTED": "EXPECTED",
  "RECEIVED": "RECEIVED",
  "UNPAID": "UNPAID"
};

const SendingSourceErrorType = {
  "ALREADY_EXISTS": "ALREADY_EXISTS",
  "CRASH": "CRASH",
  "TIME": "TIME",
  "SES": "SES",
  "OTHER": "OTHER"
};

const SendingSourceStatus = {
  "ERROR": "ERROR",
  "NONE": "NONE",
  "PENDING": "PENDING",
  "VERIFIED": "VERIFIED"
};

const SendingSourceVerifiedType = {
  "DEFAULT": "DEFAULT",
  "NONE": "NONE"
};

const SignatureDocumentStatus = {
  "DRAFT": "DRAFT",
  "PENDING": "PENDING",
  "REJECTED": "REJECTED",
  "SIGNED": "SIGNED",
  "ERROR": "ERROR"
};

const SignatureDocumentType = {
  "DOCUMENT_TO_SIGN": "DOCUMENT_TO_SIGN",
  "REGISTERED_LETTER": "REGISTERED_LETTER",
  "LETTER": "LETTER"
};

const SignatureType = {
  "EID": "EID",
  "ITSME": "ITSME",
  "SMSOTP": "SMSOTP",
  "EMAILOTP": "EMAILOTP",
  "MANUAL": "MANUAL"
};

const StatementSettingType = {
  "OWNER": "OWNER",
  "LEASE": "LEASE"
};

const StatementSource = {
  "AUTOMATIC": "AUTOMATIC",
  "MANUAL": "MANUAL"
};

const StatementStatus = {
  "TO_VALIDATE": "TO_VALIDATE",
  "DONE": "DONE"
};

const StatementType = {
  "CHARGES": "CHARGES",
  "FEES": "FEES",
  "RE_PAYMENT": "RE_PAYMENT"
};

const TankState = {
  "NEW": "NEW",
  "EXISTING": "EXISTING"
};

const TankType = {
  "AERIAL": "AERIAL",
  "UNDERGROUND": "UNDERGROUND"
};

const TankWallType = {
  "SIMPLE": "SIMPLE",
  "DOUBLE": "DOUBLE"
};

const TechnicType = {
  "UTILITY_PROVIDER": "UTILITY_PROVIDER",
  "PEB": "PEB",
  "DETECTOR": "DETECTOR",
  "HEATING": "HEATING",
  "CHIMNEY": "CHIMNEY",
  "FUELTANK": "FUELTANK",
  "LIFT": "LIFT"
};

const TemplateSubType = {
  "ACCOUNTING_EXPORT": "ACCOUNTING_EXPORT",
  "FIXTURES_ENTRANCE": "FIXTURES_ENTRANCE",
  "FIXTURES_EXIT": "FIXTURES_EXIT",
  "FOOTER": "FOOTER",
  "FURNITURES_ENTRANCE": "FURNITURES_ENTRANCE",
  "FURNITURES_EXIT": "FURNITURES_EXIT",
  "HEADER": "HEADER",
  "MAIN_RESIDENCE_9_YEARS": "MAIN_RESIDENCE_9_YEARS",
  "REPORT": "REPORT",
  "DOCUMENT_SIGNATURE_REMINDER": "DOCUMENT_SIGNATURE_REMINDER",
  "DOCUMENT_SIGNED": "DOCUMENT_SIGNED",
  "LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON": "LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON",
  "LEASE_BANKING_INFO_UPDATE": "LEASE_BANKING_INFO_UPDATE",
  "LEASE_CHARGES_ADJUSTMENT": "LEASE_CHARGES_ADJUSTMENT",
  "LEASE_CONTRACT_EXTENDED": "LEASE_CONTRACT_EXTENDED",
  "LEASE_CONTRACT_REJECTED": "LEASE_CONTRACT_REJECTED",
  "LEASE_CONTRACT_RETRACTED": "LEASE_CONTRACT_RETRACTED",
  "LEASE_CONTRACT_REVOKED": "LEASE_CONTRACT_REVOKED",
  "LEASE_CONTRACT_SIGNATURE_REMINDER": "LEASE_CONTRACT_SIGNATURE_REMINDER",
  "LEASE_CONTRACT_SIGNED": "LEASE_CONTRACT_SIGNED",
  "LEASE_CREDIT_NOTE": "LEASE_CREDIT_NOTE",
  "LEASE_DISCOUNT_CREATED": "LEASE_DISCOUNT_CREATED",
  "LEASE_DISCOUNT_DELETED": "LEASE_DISCOUNT_DELETED",
  "LEASE_DISCOUNT_UPDATED": "LEASE_DISCOUNT_UPDATED",
  "LEASE_OPERATION_CREATED": "LEASE_OPERATION_CREATED",
  "LEASE_OPERATION_DELETED": "LEASE_OPERATION_DELETED",
  "LEASE_OPERATION_PAYMENT_REQUEST": "LEASE_OPERATION_PAYMENT_REQUEST",
  "LEASE_OPERATION_UPDATED": "LEASE_OPERATION_UPDATED",
  "LEASE_PAYMENT_RECEIPT": "LEASE_PAYMENT_RECEIPT",
  "LEASE_PAYMENT_REQUEST": "LEASE_PAYMENT_REQUEST",
  "LEASE_RENT_INDEXATION": "LEASE_RENT_INDEXATION",
  "LEASE_RENT_INDEXATION_SKIPPED": "LEASE_RENT_INDEXATION_SKIPPED",
  "LEASE_RENTAL_ADAPTATION": "LEASE_RENTAL_ADAPTATION",
  "LEASE_TENANT_STATEMENT": "LEASE_TENANT_STATEMENT",
  "LEASE_TERMINATION": "LEASE_TERMINATION",
  "LEASE_UNPAID_AMOUNT_FORMAL_NOTICE": "LEASE_UNPAID_AMOUNT_FORMAL_NOTICE",
  "LEASE_UNPAID_AMOUNT_REMINDER": "LEASE_UNPAID_AMOUNT_REMINDER",
  "LEASE_UNPAID_AMOUNT_SECOND_REMINDER": "LEASE_UNPAID_AMOUNT_SECOND_REMINDER",
  "OWNER_STATEMENT": "OWNER_STATEMENT"
};

const TemplateType = {
  "MAIL": "MAIL",
  "CONTRACT": "CONTRACT",
  "CONTRACT_DOCUMENT": "CONTRACT_DOCUMENT",
  "SMS": "SMS",
  "WHATSAPP": "WHATSAPP"
};

const TicketSourceType = {
  "APP": "APP",
  "MOBILE": "MOBILE",
  "MAIL": "MAIL",
  "WEB": "WEB"
};

const TicketStatus = {
  "URGENT": "URGENT",
  "PENDING": "PENDING",
  "RESOLVED": "RESOLVED",
  "ARCHIVED": "ARCHIVED"
};

const TrackingProvider = {
  "BPOST": "BPOST"
};

const TransactionLinkType = {
  "CONTACT": "CONTACT",
  "LEASE": "LEASE"
};

const TransactionStatus = {
  "TO_RECONCILE": "TO_RECONCILE",
  "PARTIALLY_RECONCILED": "PARTIALLY_RECONCILED",
  "RECONCILED": "RECONCILED",
  "EXCLUDED": "EXCLUDED",
  "IGNORE": "IGNORE"
};

const UnitEventType = {
  "OWN_USE": "OWN_USE",
  "WORK": "WORK"
};

const UnitInventoryRoomType = {
  "ENTRANCE": "ENTRANCE",
  "LIVING": "LIVING",
  "DINING": "DINING",
  "KITCHEN": "KITCHEN",
  "LIVING_WITH_OPEN_KITCHEN": "LIVING_WITH_OPEN_KITCHEN",
  "OFFICE": "OFFICE",
  "BEDROOM": "BEDROOM",
  "BATHROOM": "BATHROOM",
  "BALCONY": "BALCONY",
  "SHOWER": "SHOWER",
  "TOILET": "TOILET",
  "CELLAR": "CELLAR",
  "ATTIC": "ATTIC",
  "GARDEN": "GARDEN",
  "TERRACE": "TERRACE",
  "PATIO": "PATIO",
  "GARAGE": "GARAGE",
  "PARKING": "PARKING",
  "OTHER": "OTHER",
  "REUNION": "REUNION",
  "TECHNICAL": "TECHNICAL",
  "INDIVIDUAL_OFFICE": "INDIVIDUAL_OFFICE",
  "OPENSPACE": "OPENSPACE",
  "WAREHOUSE": "WAREHOUSE"
};

const UnitInventoryStructureType = {
  "GENERAL": "GENERAL",
  "EQUIPMENT": "EQUIPMENT",
  "FURNITURE": "FURNITURE",
  "OTHER": "OTHER"
};

const UnitType = {
  "RESIDENTIAL": "RESIDENTIAL",
  "STUDENT": "STUDENT",
  "COMMERCIAL": "COMMERCIAL",
  "OFFICE": "OFFICE",
  "PARKING": "PARKING",
  "OTHER": "OTHER"
};

const UserRightAccessType = {
  "READ": "READ",
  "WRITE": "WRITE",
  "DELETE": "DELETE"
};

const UserRightSection = {
  "NOTIFICATIONS_DETAILS": "NOTIFICATIONS_DETAILS",
  "COMMUNICATIONS_DETAILS": "COMMUNICATIONS_DETAILS",
  "CONTACTS_DETAILS": "CONTACTS_DETAILS",
  "TICKETS_DETAILS": "TICKETS_DETAILS",
  "ADVERTISEMENTS_DETAILS": "ADVERTISEMENTS_DETAILS",
  "TEMPLATES_CONTRACTS": "TEMPLATES_CONTRACTS",
  "TEMPLATES_COMMUNICATIONS": "TEMPLATES_COMMUNICATIONS",
  "FINANCIAL_TRANSACTIONS": "FINANCIAL_TRANSACTIONS",
  "FINANCIAL_OCR": "FINANCIAL_OCR",
  "FINANCIAL_MORTGAGES": "FINANCIAL_MORTGAGES",
  "FINANCIAL_VALUATIONS_AND_COSTS": "FINANCIAL_VALUATIONS_AND_COSTS",
  "LEASES_CREATION": "LEASES_CREATION",
  "LEASES_SIGNATURE": "LEASES_SIGNATURE",
  "LEASES_DETAILS": "LEASES_DETAILS",
  "INVENTORY_OF_FIXTURES_CREATION": "INVENTORY_OF_FIXTURES_CREATION",
  "FURNITURES_INVENTORY_CREATION": "FURNITURES_INVENTORY_CREATION",
  "BUILDINGS_UNITS_DETAILS": "BUILDINGS_UNITS_DETAILS",
  "BUILDINGS_UNITS_UTILITY_PROVIDERS": "BUILDINGS_UNITS_UTILITY_PROVIDERS",
  "BUILDINGS_UNITS_TECHNICS": "BUILDINGS_UNITS_TECHNICS",
  "AGENCY_RATE": "AGENCY_RATE",
  "SETTINGS_AUTOMATION": "SETTINGS_AUTOMATION",
  "SETTINGS_MANAGEMENT": "SETTINGS_MANAGEMENT"
};

const UserRightChangeEvent = {
  "DELETE_TENANT_LEASE_ACCESS": "DELETE_TENANT_LEASE_ACCESS",
  "DELETE_OWNER_UNIT_ACCESS": "DELETE_OWNER_UNIT_ACCESS",
  "DELETE_OWNER_BUILDING_ACCESS": "DELETE_OWNER_BUILDING_ACCESS"
};

const UserStatus = {
  "DISABLED": "DISABLED",
  "JOINED": "JOINED",
  "NOT_INVITED": "NOT_INVITED",
  "PENDING": "PENDING"
};

const UtilityReadingReason = {
  "LEASE_START": "LEASE_START",
  "LEASE_END": "LEASE_END",
  "NEW_SUPPLIER": "NEW_SUPPLIER",
  "CONSUMPTION_TRACKING": "CONSUMPTION_TRACKING",
  "MONTHLY_READING": "MONTHLY_READING",
  "QUARTERLY_READING": "QUARTERLY_READING",
  "YEARLY_READING": "YEARLY_READING",
  "RENOVATION": "RENOVATION",
  "OWN_USE_START": "OWN_USE_START",
  "OWN_USE_END": "OWN_USE_END",
  "OTHER": "OTHER"
};

const UtilityType = {
  "ELECTRICITY": "ELECTRICITY",
  "ELECTRICITY_DAY_NIGHT": "ELECTRICITY_DAY_NIGHT",
  "GAS": "GAS",
  "WATER": "WATER"
};

const ValuationSourceType = {
  "MANUAL": "MANUAL",
  "PROFESSIONAL": "PROFESSIONAL"
};

const VariousOperationType = {
  "ONE_TIME": "ONE_TIME",
  "END_WITH_LEASE": "END_WITH_LEASE",
  "RECURRING": "RECURRING"
};

const { AccountLabel, Address, Advertisement, AgencyRate, AgencyRateOwner, AgencyRateStatement, BankAccount, Building, BuildingEvent, BuildingOwner, Charge, ChargeStatement, Client, Communication, CommunicationSettingsProfile, Contact, CustomField, CustomSubscription, DocumentCategory, File, Integration, InventoryCustomItem, Invoice, InvoiceSetting, Lease, LeaseActionHistory, LeaseAdditionalClause, LeaseContact, LeasePriceHistory, LeaseInventory, LeaseInventoryCheckedItem, LeaseInventoryEncoding, LeaseVariousOperation, Mortgage, Notification, Posting, RepartitionKey, RepaymentStatement, SendingSource, Setting, SignatureDocument, Statement, StatementSetting, Team, Technic, Template, Ticket, Transaction, TransactionRule, Unit, UnitEvent, UnitInventory, UnitInventoryStructure, UnitLease, UnitOwner, User, UserRole, UserTeam, Valuation, VariousOperation, VatRate, AdvertisementDescription, AdvertisementPostingMutationStatus, AdvertisementPostingStatus, AgencyRateAmountDetail, AgencyRateStatementAmount, BlockedEmail, CommunicationFrequencySetting, CommunicationSettings, CustomLabel, CustomValue, DNSRecord, GenerateCommunicationPdfResult, GenerateReportResult, IntegrationConfiguration, JointOwner, LeaseActionHistoryDetail, LeasePriceHistoryDetail, LeaseInventoryFurnitureExitIssue, LeaseInventoryUnitKey, LeaseVariousOperationHistory, MutationStatus, NearbyComodity, OwnerFinancialInformation, PhoneNumber, ProposedValue, PublishedPlatform, RepartitionValues, RepaymentStatementDetail, SignatureDocumentResult, SignaturePosition, StatusResult, TechnicMaintenanceHistory, TransactionLink, UserRight, UtilityHistory } = initSchema(schema);

export {
  AccountLabel,
  Address,
  Advertisement,
  AgencyRate,
  AgencyRateOwner,
  AgencyRateStatement,
  BankAccount,
  Building,
  BuildingEvent,
  BuildingOwner,
  Charge,
  ChargeStatement,
  Client,
  Communication,
  CommunicationSettingsProfile,
  Contact,
  CustomField,
  CustomSubscription,
  DocumentCategory,
  File,
  Integration,
  InventoryCustomItem,
  Invoice,
  InvoiceSetting,
  Lease,
  LeaseActionHistory,
  LeaseAdditionalClause,
  LeaseContact,
  LeasePriceHistory,
  LeaseInventory,
  LeaseInventoryCheckedItem,
  LeaseInventoryEncoding,
  LeaseVariousOperation,
  Mortgage,
  Notification,
  Posting,
  RepartitionKey,
  RepaymentStatement,
  SendingSource,
  Setting,
  SignatureDocument,
  Statement,
  StatementSetting,
  Team,
  Technic,
  Template,
  Ticket,
  Transaction,
  TransactionRule,
  Unit,
  UnitEvent,
  UnitInventory,
  UnitInventoryStructure,
  UnitLease,
  UnitOwner,
  User,
  UserRole,
  UserTeam,
  Valuation,
  VariousOperation,
  VatRate,
  AccountLabelCustomUsage,
  AccountType,
  AddressStatus,
  AdvertisementPublishingPlatformActions,
  AdvertisementUnitType,
  AdvertisementUnitSubType,
  AgencyRateAmountDetailType,
  AgencyRateFeesType,
  AgencyRateIncludedAmount,
  AgencyRateRepartitionType,
  AgencyRateType,
  AmenitiesType,
  BatchChangeCommunicationsAction,
  BounceType,
  BuildingState,
  CertificateOfConformityType,
  ChargeSource,
  ChargeStatus,
  CivilityType,
  CommunicationChannel,
  CommunicationFrequencySettingPeriodDateFrom,
  CommunicationFrequencySettingPeriodType,
  CommunicationSendingRule,
  CommunicationSettingsProfileReferent,
  CommunicationSettingRecipient,
  CommunicationStatus,
  CommunicationTimeFrameSetting,
  CommunicationType,
  ConnectionPlugsType,
  ContactStatus,
  ContactType,
  CustomFieldType,
  CustomSubscriptionType,
  DeepDeletableEntityType,
  DelimitedZoneType,
  DetectorType,
  DocumentType,
  FileCategory,
  FloodProneAreaType,
  GenerateReportErrorCode,
  GenerateReportOutputFormat,
  GenerateReportOutputResult,
  GenerateReportType,
  HeatingAmenities,
  HeatingType,
  IntegrationName,
  IntegrationStatus,
  InventoryItemType,
  InventoryOfFixturesMethod,
  InventoryOfFixturesType,
  InviteContactToClientAccountType,
  InvoiceType,
  KitchenEquipment,
  LandTerrainType,
  Language,
  LeaseAction,
  LeaseActionEndInitiator,
  LeaseActionEndReason,
  LeaseActionHistoryStatus,
  LeaseAmountUpdateStatus,
  LeasePriceHistoryType,
  LeasePriceHistoryStatus,
  LeaseInventoryFurnitureExitIssueAction,
  LeaseInventoryFurnitureExitIssueType,
  LeaseInventoryUnitKeyType,
  LeaseInventoryStructureStateType,
  LeaseInventoryType,
  LeaseMonthlyChargesType,
  LeasePaymentFrequency,
  LeasePaymentInvoicePeriod,
  LeaseRentalGuarantyType,
  LeaseStatus,
  LeaseType,
  LeaseVariousOperationType,
  MortgageRateType,
  MutationUserInputUpdatedBy,
  NearbyCommoditiesSubTypes,
  NearbyCommoditiesTypes,
  NotificationCreation,
  NotificationStatus,
  NotificationType,
  PostingChargeType,
  PostingForeignType,
  PostingStatus,
  PostingType,
  PublishingPlatform,
  RemittanceType,
  RepartitionKeyType,
  RepaymentStatementDetailType,
  SendingSourceErrorType,
  SendingSourceStatus,
  SendingSourceVerifiedType,
  SignatureDocumentStatus,
  SignatureDocumentType,
  SignatureType,
  StatementSettingType,
  StatementSource,
  StatementStatus,
  StatementType,
  TankState,
  TankType,
  TankWallType,
  TechnicType,
  TemplateSubType,
  TemplateType,
  TicketSourceType,
  TicketStatus,
  TrackingProvider,
  TransactionLinkType,
  TransactionStatus,
  UnitEventType,
  UnitInventoryRoomType,
  UnitInventoryStructureType,
  UnitType,
  UserRightAccessType,
  UserRightSection,
  UserRightChangeEvent,
  UserStatus,
  UtilityReadingReason,
  UtilityType,
  ValuationSourceType,
  VariousOperationType,
  AdvertisementDescription,
  AdvertisementPostingMutationStatus,
  AdvertisementPostingStatus,
  AgencyRateAmountDetail,
  AgencyRateStatementAmount,
  BlockedEmail,
  CommunicationFrequencySetting,
  CommunicationSettings,
  CustomLabel,
  CustomValue,
  DNSRecord,
  GenerateCommunicationPdfResult,
  GenerateReportResult,
  IntegrationConfiguration,
  JointOwner,
  LeaseActionHistoryDetail,
  LeasePriceHistoryDetail,
  LeaseInventoryFurnitureExitIssue,
  LeaseInventoryUnitKey,
  LeaseVariousOperationHistory,
  MutationStatus,
  NearbyComodity,
  OwnerFinancialInformation,
  PhoneNumber,
  ProposedValue,
  PublishedPlatform,
  RepartitionValues,
  RepaymentStatementDetail,
  SignatureDocumentResult,
  SignaturePosition,
  StatusResult,
  TechnicMaintenanceHistory,
  TransactionLink,
  UserRight,
  UtilityHistory
};