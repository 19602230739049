import { Typography } from '@material-ui/core';
import {
  Colors,
  getEventTimeWindowLong,
  getFirstCurrentEvent,
  getFirstCurrentLease,
  Lease,
  LeaseExtended,
  UnitEvent,
} from '@rentguru/commons-utils';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import TextButton from 'src/components/ui/TextButton';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as ArrowRight } from 'src/icons/arrow-right.svg';

interface UnitStatusProps {
  events: UnitEvent[] | undefined;
  leases: Lease[] | LeaseExtended[] | undefined;
  statusStyle?: React.CSSProperties;
}
const UnitStatus: React.FC<UnitStatusProps> = ({ leases, events, statusStyle = {} }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const history = useHistory();
  const { pathname } = useLocation();
  const lease = !isNil(leases) && !isEmpty(leases) ? (getFirstCurrentLease(leases) as LeaseExtended) : null;
  const event = !isNil(events) && !isEmpty(events) ? (getFirstCurrentEvent(events) as UnitEvent) : null;

  let labelId = '';
  let timeEnd = null;
  if (isNil(lease) && isNil(event)) {
    // Vacant
    labelId = 'rentalUnit.detail.general.vacant';
  } else if (isNil(event)) {
    // Leased
    labelId = 'event.rented';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    timeEnd = getEventTimeWindowLong(lease!, (dateLocaleMap as any)[language]);
  } else if (event.type === 'WORK') {
    // Renovation
    labelId = 'event.work';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    timeEnd = getEventTimeWindowLong(event!, (dateLocaleMap as any)[language]);
  } else if (event.type === 'OWN_USE') {
    // Own use
    labelId = 'event.ownUse';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    timeEnd = getEventTimeWindowLong(event!, (dateLocaleMap as any)[language]);
  }
  const isDisabled = !timeEnd;

  return (
    <TextButton
      style={{
        ...(!isDisabled && { backgroundColor: 'white' }),
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={(e) => {
        if (lease) {
          history.replace({
            pathname: `${RouteDestination.LEASES}/detail/${lease.id}`,
            state: {
              goBackUrl: pathname,
            },
          });
        }
        e.stopPropagation();
      }}
      disabled={isDisabled}
    >
      <div style={statusStyle}>
        <Typography
          style={{
            fontSize: 14,
            color: Colors.SLATE_GREY,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'capitalize',
          }}
        >
          {formatMessage({ id: labelId })}
        </Typography>
        {timeEnd && (
          <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <ArrowRight
              style={{
                fill: Colors.BLUE_GREY,
                padding: 4,
                width: 11,
                height: 11,
              }}
            />
            <Typography style={{ color: Colors.BLUE_GREY, fontSize: 11 }}>{timeEnd}</Typography>
          </div>
        )}
      </div>
    </TextButton>
  );
};

export default UnitStatus;
