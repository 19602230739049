import { Grid, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { SendingSource } from '@rentguru/commons-utils';
import { formatDistance, parseISO } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as Info } from 'src/icons/info.svg';
import DnsRecordElement from './DnsRecord';

interface DnsRecordsListProps {
  sendingSource: SendingSource;
  hideLastVerificationTime?: boolean;
}

const DnsRecordsList: React.FC<DnsRecordsListProps> = ({ sendingSource, hideLastVerificationTime = false }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();

  return (
    <>
      <Grid style={{ marginLeft: 30, marginRight: 30 }}>
        <Typography>
          {formatMessage({
            id: 'settings.sendingSources.verifyDomainInfo1',
          })}
          <b> {sendingSource.name} </b> {formatMessage({ id: 'settings.sendingSources.verifyDomainInfo2' })}
        </Typography>
        <Typography>
          {formatMessage({ id: 'settings.sendingSources.verifyDomainInfo3' })}
          <b> {formatMessage({ id: 'settings.sendingSources.verifyDomainInfo4' })}</b>
        </Typography>
        <Typography>
          {formatMessage({ id: 'settings.sendingSources.verifyDomainInfo5' })}
          <ol>
            <li>{formatMessage({ id: 'settings.sendingSources.verifyDomainInfoEnum1' })}</li>
            <li>{formatMessage({ id: 'settings.sendingSources.verifyDomainInfoEnum2' })}</li>
            <li>{formatMessage({ id: 'settings.sendingSources.verifyDomainInfoEnum3' })}</li>
          </ol>
        </Typography>
        {!hideLastVerificationTime && (
          <Typography style={{ marginTop: 10, fontWeight: 'bold' }}>
            {formatMessage(
              {
                id: 'settings.sendingSources.pendingInfo4',
              },
              {
                name: sendingSource.name,
                duration: formatDistance(parseISO(sendingSource.statusLastVerification!), new Date(), {
                  locale: (dateLocaleMap as dateLocaleMapType)[language],
                }),
              }
            )}
          </Typography>
        )}
      </Grid>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      {sendingSource.dnsRecords?.map((dnsRecord) => (
        <DnsRecordElement domainName={sendingSource.name} dnsRecord={dnsRecord} key={dnsRecord.value} />
      ))}

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      <IconnedRemark
        Icon={Info}
        noFixWidth
        style={{ marginRight: 30 }}
        message={
          <Grid style={{ textAlign: 'left' }}>
            <Typography>{formatMessage({ id: 'settings.sendingSources.verifyDomainFooterInfo' })}</Typography>
          </Grid>
        }
      />
    </>
  );
};

export default DnsRecordsList;
