/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import {
  CreateSignatureDocumentInput as CreateInput,
  CreateSignatureDocumentMutationVariables as CreateMutationVariables,
  CreateSignatureDocumentInput,
  DeleteSignatureDocumentMutationVariables as DeleteMutationVariables,
  Model,
  OnCreateSignatureDocumentSubscription,
  OnDeleteSignatureDocumentSubscription,
  OnUpdateSignatureDocumentSubscription,
  SignatureDocument,
  SignatureDocumentStatus,
  UpdateSignatureDocumentInput,
  cleanInputCreate,
  cleanInputUpdate,
  createSignatureDocument as createMutation,
  deleteSignatureDocument as deleteMutation,
  getSignatureDocument as getQuery,
  getReadId,
  getTableClientId,
  onCreateSignatureDocument,
  onDeleteSignatureDocument,
  onUpdateSignatureDocument,
  syncSignatureDocuments as syncQuery,
  updateSignatureDocument as updateMutation,
} from '@rentguru/commons-utils';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { isEmpty, isNil } from 'lodash';
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useUser } from './UserContext';
import { usePermissions } from './utils/PermissionsContext';

const ENTITY_MODEL_NAME: Model = 'SignatureDocument';

export interface SignatureDocumentsContext extends SignatureDocumentState {
  error: string | null;
  getSignatureDocument: (id: string) => SignatureDocument | null | undefined;
  getSignatureDocumentsForEntity: (
    entityName: Model,
    entityId: string,
    status?: SignatureDocumentStatus
  ) => SignatureDocument[] | null | undefined;
  createSignatureDocument: (
    input: Omit<CreateSignatureDocumentInput, 'clientId' | 'readId'>
  ) => Promise<SignatureDocument>;
  setSignatureResultToZero: (signatureDocument: SignatureDocument) => Promise<SignatureDocument>;
  updateSignatureDocument: (updates: UpdateSignatureDocumentInput) => Promise<SignatureDocument>;
  deleteSignatureDocument: (input: SignatureDocument) => Promise<SignatureDocument | null>;
  setFetchSignatureDocument: () => void;
}

interface SignatureDocumentState {
  signatureDocuments: SignatureDocument[] | null;
  loading: boolean;
  lastSync?: Date;
  shouldFetch: boolean;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'ADD_SIGNATURE_DOCUMENT';
      payload: { signatureDocument: SignatureDocument };
    }
  | {
      type: 'FETCHED';
      payload: { signatureDocuments: SignatureDocument[]; error?: string };
    }
  | {
      type: 'ERROR';
      payload: { error: string };
    }
  | {
      type: 'UPDATE_SIGNATURE_DOCUMENT';
      payload: { signatureDocument: SignatureDocument };
    }
  | {
      type: 'DELETE_SIGNATURE_DOCUMENT';
      payload: { id: string };
    };

const initialState: SignatureDocumentState = {
  signatureDocuments: [],
  loading: false,
  shouldFetch: false,
};

const signatureDocumentReducer = (state: SignatureDocumentState, action: Action): SignatureDocumentState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      if (state.loading) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };

    case 'FETCHED':
      if (action.payload.error) {
        return {
          ...state,
          signatureDocuments: [],
          loading: false,
          shouldFetch: false,
        };
      }
      const signatureDocuments = action.payload.signatureDocuments;
      return {
        ...state,
        loading: false,
        shouldFetch: false,
        lastSync: new Date(),
        signatureDocuments,
      };
    case 'ADD_SIGNATURE_DOCUMENT':
      return {
        ...state,
        signatureDocuments: [...(state.signatureDocuments ?? []), action.payload.signatureDocument],
      };
    case 'UPDATE_SIGNATURE_DOCUMENT':
      if (!state.signatureDocuments) {
        return state;
      }

      const updatedSignatureDocument = action.payload.signatureDocument;
      const oldRecord = state.signatureDocuments.find(
        (signatureDocument) => signatureDocument.id === updatedSignatureDocument.id
      );
      if (isNil(oldRecord) || !recordWasUpdated(oldRecord, updatedSignatureDocument)) {
        return state;
      }

      return {
        ...state,
        signatureDocuments: state.signatureDocuments.map((signatureDocument) => {
          if (signatureDocument.id === updatedSignatureDocument.id) {
            return updatedSignatureDocument;
          }
          return signatureDocument;
        }),
      };
    case 'DELETE_SIGNATURE_DOCUMENT':
      if (!state.signatureDocuments) {
        return state;
      }

      return {
        ...state,
        signatureDocuments: state.signatureDocuments.filter(
          (signatureDocument) => signatureDocument.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

const fetchSignatureDocuments = async (
  by: 'byClientId' | 'byForeignKey',
  byValue: string,
  additionalFilter?: object
): Promise<SignatureDocument[]> => {
  return await list<SignatureDocument>(syncQuery, getFilterFieldNameForIndex(by), byValue, additionalFilter);
};

export const SignatureDocumentsContext = React.createContext<SignatureDocumentsContext | null>(null);

export const SignatureDocumentsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<SignatureDocumentState, Action>>(signatureDocumentReducer, initialState);
  const { clientId } = useUser();
  const { signatureDetailsWrite } = usePermissions();

  const { loading, signatureDocuments } = state;

  const setFetchSignatureDocument = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    const fetchAndSet = async () => {
      dispatch({ type: 'IS_FETCHING' });
      const result = await fetchSignatureDocuments('byClientId', getTableClientId(clientId!, ENTITY_MODEL_NAME));
      dispatch({ type: 'FETCHED', payload: { signatureDocuments: result } });
    };
    if (state.shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch]);

  useSubscriptions<
    OnCreateSignatureDocumentSubscription,
    OnUpdateSignatureDocumentSubscription,
    OnDeleteSignatureDocumentSubscription
  >(
    onCreateSignatureDocument,
    onUpdateSignatureDocument,
    onDeleteSignatureDocument,
    (data) => {
      dispatch({
        type: 'ADD_SIGNATURE_DOCUMENT',
        payload: { signatureDocument: data.onCreateSignatureDocument as SignatureDocument },
      });
    },
    (data) => {
      dispatch({
        type: 'UPDATE_SIGNATURE_DOCUMENT',
        payload: { signatureDocument: data.onUpdateSignatureDocument as SignatureDocument },
      });
    },
    (data) => {
      const { id } = data.onDeleteSignatureDocument as SignatureDocument;
      dispatch({
        type: 'DELETE_SIGNATURE_DOCUMENT',
        payload: { id },
      });
    }
  );

  const getSignatureDocument = (id: string) => {
    if (loading || !signatureDocuments) {
      return null;
    }
    return signatureDocuments.find((item) => item.id === id);
  };

  const getSignatureDocumentsForEntity = (entityName: Model, entityId: string, status?: SignatureDocumentStatus) => {
    if (loading || !signatureDocuments) {
      return null;
    }
    return signatureDocuments.filter(
      (item) =>
        item.foreignKey === entityId &&
        item.foreignTableName === entityName &&
        ((status && item.status === status) || !status)
    );
  };

  const createSignatureDocument = async (
    input: Omit<CreateSignatureDocumentInput, 'clientId' | 'readId'>
  ): Promise<SignatureDocument> => {
    const signatureDocument = await mutation<SignatureDocument, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    dispatch({ type: 'ADD_SIGNATURE_DOCUMENT', payload: { signatureDocument } });
    return signatureDocument;
  };

  const updateSignatureDocument = async (updates: UpdateSignatureDocumentInput): Promise<SignatureDocument> => {
    const original = getSignatureDocument(updates.id);
    const result = await mutation<SignatureDocument>(updateMutation, {
      input: { _version: original?._version, ...cleanInputUpdate(updates, false) },
    });
    dispatch({ type: 'UPDATE_SIGNATURE_DOCUMENT', payload: { signatureDocument: result } });
    return result;
  };

  const setSignatureResultToZero = async (signatureDocument: SignatureDocument): Promise<SignatureDocument> => {
    return updateSignatureDocument({
      ...signatureDocument,
      status: SignatureDocumentStatus.DRAFT,
      signatureResults: signatureDocument.signatureResults.map(({ __typename, ...result }) => ({
        ...result,
        signed: false,
        signedDate: undefined,
        lastSignInviteSentDate: undefined,
        openedDate: undefined,
        signatureLink: undefined,
      })),
    } as UpdateSignatureDocumentInput);
  };

  const deleteSignatureDocument = async (signatureDocument: SignatureDocument): Promise<SignatureDocument | null> => {
    if (!signatureDetailsWrite) {
      return null;
    }

    await deleteEntityWithFetchBefore<Pick<SignatureDocument, 'id'>, DeleteMutationVariables>(
      { id: signatureDocument.id },
      getQuery,
      deleteMutation
    );

    dispatch({ type: 'DELETE_SIGNATURE_DOCUMENT', payload: { id: signatureDocument.id } });
    return signatureDocument;
  };

  const values = useMemo(
    () => ({
      ...state,
      error: null,
      getSignatureDocument,
      getSignatureDocumentsForEntity,
      createSignatureDocument,
      updateSignatureDocument,
      setSignatureResultToZero,
      deleteSignatureDocument,
      setFetchSignatureDocument,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <SignatureDocumentsContext.Provider value={values}>{children}</SignatureDocumentsContext.Provider>;
};

export const useSignatureDocuments = (): SignatureDocumentsContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<SignatureDocumentsContext | null>(SignatureDocumentsContext);

  if (isNil(context)) {
    throw new Error('`useSignatureDocuments` hook must be used within a `SignatureDocumentsContext` component');
  }
  useEffect(() => {
    if (!firstRender.current && isEmpty(context.signatureDocuments) && isNil(context.lastSync)) {
      context.setFetchSignatureDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
