import { Chip, Grid, Typography } from '@material-ui/core';
import { Colors, displayAddressInLanguage } from '@rentguru/commons-utils';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import EditAddressDialog from 'src/components/ui/Dialogs/EditAddressDialog';
import TextDetail from 'src/components/ui/TextDetail';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as EditIcon } from 'src/icons/edit.svg';

interface CommunicationDetailContentAddressProps {
  communicationId: string;
  editable: boolean;
}

const CommunicationDetailsContentAddress: React.FC<CommunicationDetailContentAddressProps> = ({
  communicationId,
  editable,
}) => {
  const { language } = useLocale();
  const { formatMessage } = useIntl();
  const [isEditAddressDialogOpen, setIsEditAddressDialogOpen] = useState<boolean>(false);
  const { getCommunication } = useCommunications();

  const communication = getCommunication(communicationId);
  const address = displayAddressInLanguage(communication?.address, language);
  const isAddressMissing = isEmpty(address);
  const addressLabel = isAddressMissing ? formatMessage({ id: 'communications.detail.recipient.noAddress' }) : address;

  return (
    <Grid>
      <TextDetail
        title={formatMessage({ id: `communications.detail.recipient.recipientAddress` })}
        style={{ paddingBottom: 12 }}
      />
      {editable ? (
        <Chip
          key={'addressChip'}
          variant="outlined"
          label={
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: 14,
                  marginRight: 5,
                  color: isAddressMissing ? Colors.ROLLING_STONE_GREY : Colors.CLASSICAL_BLACK,
                }}
              >
                {addressLabel}
              </Typography>
              <EditIcon />
            </Grid>
          }
          clickable
          onClick={() => setIsEditAddressDialogOpen(true)}
        />
      ) : (
        <Typography
          style={{ fontSize: 14, color: isAddressMissing ? Colors.ROLLING_STONE_GREY : Colors.CLASSICAL_BLACK }}
        >
          {addressLabel}
        </Typography>
      )}
      <EditAddressDialog
        open={isEditAddressDialogOpen}
        communication={communication!}
        onClose={() => setIsEditAddressDialogOpen(false)}
      />
    </Grid>
  );
};

export default CommunicationDetailsContentAddress;
