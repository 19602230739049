/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import {
  Advertisement,
  AdvertisementPostingMutationStatus,
  AdvertisementPublishingInput,
  CreateAdvertisementInput,
  CreateAdvertisementMutationVariables,
  DeleteAdvertisementMutationVariables,
  UpdateAdvertisementInput,
  UpdateAdvertisementMutationVariables,
  cleanInputCreate,
  cleanInputUpdate,
  createAdvertisement as createMutation,
  deleteAdvertisement as deleteMutation,
  getAdvertisement,
  getReadId,
  getTableClientId,
  handleAdvertisementPublishing,
  updateAdvertisement as updateAdMutation,
} from '@rentguru/commons-utils';
import { deleteAndHideEntity, get, mutation } from '@up2rent/fetch-utils';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useUser } from './UserContext';

export interface AdvertisementContext {
  refetchAdvertisement: () => void;
  updateAdvertisement: (updates: UpdateAdvertisementInput) => Promise<Advertisement>;
  deleteAdvertisement: (advertisement: Advertisement) => Promise<Advertisement>;
  createAdvertisement: (input: Omit<CreateAdvertisementInput, 'clientId' | 'readId'>) => Promise<Advertisement>;
  publishUnit: (input: AdvertisementPublishingInput) => Promise<AdvertisementPostingMutationStatus>;
  advertisement: Advertisement | null;
  loading: boolean;
  error: string | null;
}

const fetchAdvertisements = async (advertisementId: string): Promise<Advertisement | Error> => {
  try {
    const result = await get<Advertisement>(getAdvertisement, advertisementId);
    return result;
  } catch (err) {
    return err as Error;
  }
};

export const useAdvertisement = (
  shouldFetchAdvertisement: boolean = false,
  advertisementId: string
): AdvertisementContext => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | Error | null>(null);
  const [advertisement, setAdvertisements] = useState<Advertisement | null>(null);
  const [firstRender, setFirstRender] = useState<boolean>(true); // useRef
  const { clientId, isOwner, userId } = useUser();
  if (shouldFetchAdvertisement && firstRender && !isEmpty(advertisementId)) {
    setLoading(true);
    setFirstRender(false);
    fetchAdvertisements(advertisementId).then((result) => {
      if (result instanceof Error) {
        setError(result);
      } else {
        setAdvertisements(result);
      }
      setLoading(false);
    });
  }

  const createAdvertisement = async (
    input: Omit<CreateAdvertisementInput, 'clientId' | 'readId'>
  ): Promise<Advertisement> => {
    const advertisement = await mutation<Advertisement, CreateAdvertisementMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateAdvertisementInput),
        readId: getReadId(clientId!, 'Advertisement'),
        clientId: getTableClientId(clientId!, 'Advertisement'),
        ...(isOwner && { writers: [userId!] }),
      },
    });
    fetchAdvertisements(advertisementId).then((result) => {
      if (result instanceof Error) {
        setError(result as Error);
      } else {
        setAdvertisements(result as Advertisement);
      }
    });
    return advertisement;
  };

  const refetchAdvertisement = () => {
    fetchAdvertisements(advertisementId).then((result) => {
      if (result instanceof Error) {
        setError(result);
      } else {
        setAdvertisements(result);
      }
      setLoading(false);
    });
  };

  const updateAdvertisement = async (updates: UpdateAdvertisementInput): Promise<Advertisement> => {

    const result = await mutation<Advertisement>(updateAdMutation, {
      input: cleanInputUpdate(updates, false, true),
    });
    return result;
  };

  const deleteAdvertisement = async (advertisement: Advertisement): Promise<Advertisement> => {
    const result = await deleteAndHideEntity<
      Advertisement,
      DeleteAdvertisementMutationVariables,
      UpdateAdvertisementMutationVariables
    >(advertisement, deleteMutation, updateAdMutation);
    fetchAdvertisements(advertisementId).then((result) => {
      if (result instanceof Error) {
        setError(result as Error);
      } else {
        setAdvertisements(result as Advertisement);
      }
    });
    return result;
  };

  const publishUnit = async (input: AdvertisementPublishingInput): Promise<AdvertisementPostingMutationStatus> => {
    const result = await mutation<AdvertisementPostingMutationStatus, { input: AdvertisementPublishingInput }>(
      handleAdvertisementPublishing,
      {
        input,
      }
    );
    return result;
  };

  return {
    refetchAdvertisement,
    updateAdvertisement,
    deleteAdvertisement,
    createAdvertisement,
    publishUnit,
    loading,
    error: error as string | null,
    advertisement,
  };
};
