import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Colors, Lease } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { CommunicationSettingsInfoRemark } from 'src/components/Communications/CommunicationSettingsInfoRemark';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import EndLeaseCommentToTenantField from './EndLeaseCommentToTenantField';
import { iconnedRemarkMargins, useEndLeaseStyles } from './EndLeaseForm';
import EndLeaseIndemnityField, { IndemnityFieldProps } from './EndLeaseIndemnityField';

interface EndLeaseMutualFieldsProps extends IndemnityFieldProps {
  lease: Lease;
}

const EndLeaseMutualFields: React.FC<EndLeaseMutualFieldsProps> = (props: EndLeaseMutualFieldsProps) => {
  const { formatMessage } = useIntl();
  const classes = useEndLeaseStyles();

  return (
    <>
      <EndLeaseCommentToTenantField />
      <Grid style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <Typography className={classes.informationFillInTitle}>
          {formatMessage({ id: `lease.endExtendLease.compensation` })}
        </Typography>
        <Tooltip title={formatMessage({ id: 'lease.endExtendLease.compensationInfo' })} placement="right">
          <Grid>
            <InfoSvg style={{ marginLeft: 10, fill: Colors.SILVER }} />
          </Grid>
        </Tooltip>
      </Grid>

      <EndLeaseIndemnityField {...props} />

      <CommunicationSettingsInfoRemark lease={props.lease} iconnedRemarkStyle={iconnedRemarkMargins} />
    </>
  );
};

export default EndLeaseMutualFields;
